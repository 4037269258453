import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import focusElement from '../../../../helpers/focusElement';
import { useDispatch } from 'react-redux';
import { paymentFailed } from './checkoutSlice';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { changeColorAlpha } from '../../../../helpers/colorUtils';
import Button from '../../components/Button/Button';

const LIBRARY_URL = 'https://js.authorize.net/v3/AcceptUI.js';
const TEST_LIBRARY_URL = 'https://jstest.authorize.net/v3/AcceptUI.js';

const getLibrarySource = testMode =>
    testMode ? TEST_LIBRARY_URL : LIBRARY_URL;

const useStyles = makeStyles(({ palette, spacing }) => ({
    buttonContainer: {
        width: '100%',
    },
    button: {
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: spacing(2),
        borderRadius: 5,
        display: 'block',
        width: '100%',
        fontSize: '1.438rem',
        fontWeight: 500,
        '&:disabled': {
            backgroundColor: changeColorAlpha(palette.secondary.main, 0.5),
        },
        '@media (max-width: 600px)': {
            width: '100%',
        },
    },
}));

function AuthorizeButton({
    apiLoginId,
    publicClientKey,
    isTestMode,
    onDonateFailed,
    onDonateSuccess,
    makeDonation,
}) {
    const classes = useStyles();
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [libStatus, setLibStatus] = useState('loading');
    const { handleSubmit, getValues } = useFormContext();
    const paymentStatusRef = useRef('idle');
    const acceptUIResponseHandlerRef = useRef();
    const [paymentStatus, setPaymentStatus] = useState('idle');

    acceptUIResponseHandlerRef.current = async response => {
        if (paymentStatusRef.current === 'processing') {
            return;
        }
        paymentStatusRef.current = 'processing';
        setPaymentStatus('processing');
        setTimeout(function () {
            document
                .getElementById('authorize-button-container')
                ?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
        }, 0);
        let errorText =
            'Error fetching paymentToken, please reload the page and try again';
        if (!response || response?.messages?.resultCode === 'Error') {
            if (Array.isArray(response?.messages?.message)) {
                errorText = response.messages.message
                    ?.map(error => error.text ?? error.code)
                    .join(', ');
            }
            if (typeof onDonateFailed === 'function') {
                onDonateFailed(new Error(errorText));
            }
            dispatch(paymentFailed(errorText));
            paymentStatusRef.current = 'idle';
            setPaymentStatus('idle');
            return;
        }

        if (
            response.messages?.resultCode === 'Ok' &&
            response.opaqueData?.dataValue &&
            response.opaqueData?.dataDescriptor
        ) {
            const { layerItems, ...donationData } = getValues();

            if (typeof makeDonation === 'function') {
                const donations = await makeDonation(
                    donationData,
                    layerItems,
                    response.opaqueData,
                );

                if (typeof onDonateSuccess === 'function' && donations) {
                    onDonateSuccess(donations);
                }
            }
        }
        paymentStatusRef.current = 'idle';
        setPaymentStatus('idle');
    };

    const checkIsValid = async () => {
        return new Promise((resolve, reject) =>
            handleSubmit(() => resolve(true), reject)(),
        ).catch(errors => {
            if (errors?.amount) {
                setTimeout(() => focusElement('[name="amount"]'), 25);
            }
            return false;
        });
    };

    useEffect(() => {
        window.acceptUIResponseHandler = acceptUIResponseHandlerRef.current;
        let authorizeHiddenButton = document.getElementById(
            'authorize-hidden-button',
        );
        if (!authorizeHiddenButton) {
            authorizeHiddenButton = document.createElement('button');
            authorizeHiddenButton.style.display = 'none';
            authorizeHiddenButton.type = 'button';
            authorizeHiddenButton.id = 'authorize-hidden-button';
            authorizeHiddenButton.className = 'AcceptUI'; //script uses class 'AcceptUI' to interface
            authorizeHiddenButton.dataset.billingaddressoptions =
                '{ "show": false, "required": false }';
            authorizeHiddenButton.dataset.apiloginid = apiLoginId;
            authorizeHiddenButton.dataset.clientkey = publicClientKey;
            authorizeHiddenButton.dataset.acceptuiformbtntxt = 'Submit';
            authorizeHiddenButton.dataset.acceptuiformheadertxt =
                'Card Information';
            authorizeHiddenButton.dataset.paymentoptions =
                '{"showCreditCard": true,"showBankAccount": false}';
            authorizeHiddenButton.dataset.responsehandler =
                'acceptUIResponseHandler';
            document.body.appendChild(authorizeHiddenButton);
        }

        const handleScriptEvent = event => {
            const status = event.type === 'load' ? 'ready' : 'error';
            setLibStatus(status);
            document.getElementById('pg-authorize-script').dataset.status =
                status;
        };
        let script = document.getElementById('pg-authorize-script');
        if (!script) {
            script = document.createElement('script');
            script.id = 'pg-authorize-script';
            script.src = getLibrarySource(isTestMode);
            script.async = true;
            script.dataset.status = 'loading';
            script.addEventListener('load', handleScriptEvent);
            script.addEventListener('error', handleScriptEvent);
            document.body.appendChild(script);
        } else {
            setLibStatus(script.getAttribute('data-status'));
        }

        const hideAuthorizeUI = () => {
            document
                .getElementById('AcceptUIBackground')
                ?.classList?.remove('show');
            document
                .getElementById('AcceptUIContainer')
                ?.classList?.remove('show');
        };
        const handleDocumentBodyClick = event => {
            if (event.target.id === 'AcceptUIBackground') {
                hideAuthorizeUI();
            }
        };
        document.body.addEventListener('click', handleDocumentBodyClick);

        return () => {
            if (script) {
                script.removeEventListener('load', handleScriptEvent);
                script.removeEventListener('error', handleScriptEvent);
            }
            hideAuthorizeUI();
            document.body.removeEventListener('click', handleDocumentBodyClick);
            delete window.acceptUIResponseHandler;
        };
    }, []);

    const getButtonContent = () => {
        if (paymentStatus === 'processing') {
            return (
                <>
                    {formatMessage({ id: 'Checkout.pending' })}{' '}
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </>
            );
        }
        return formatMessage({ id: 'DonateButton.text' });
    };

    const handleClick = async () => {
        const isFormValid = await checkIsValid();
        if (isFormValid) {
            document.getElementById('authorize-hidden-button')?.click();
        }
    };

    return (
        <div
            id="authorize-button-container"
            className={classes.buttonContainer}
        >
            <Button
                color={palette.secondary.main}
                hoverColor={palette.secondary.main}
                disabled={
                    paymentStatus === 'processing' || libStatus !== 'ready'
                }
                type="button"
                id="authorize-button"
                className={classes.button}
                onClick={handleClick}
            >
                {getButtonContent()}
            </Button>
        </div>
    );
}

AuthorizeButton.propTypes = {
    apiLoginId: PropTypes.string.isRequired,
    publicClientKey: PropTypes.string.isRequired,
    isTestMode: PropTypes.bool,
    onDonateFailed: PropTypes.func,
    onDonateSuccess: PropTypes.func,
    makeDonation: PropTypes.func,
};

export default AuthorizeButton;
