import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuButton from '../MenuButton/MenuButton';
import useClickOutside from '../../hooks/clickOutside';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette, zIndex }) => ({
        root: {
            padding: 0,
            margin: 0,
            position: 'relative',
        },

        list: {
            padding: '5px 0',
            margin: 0,
            background: palette.background.default,
            position: 'absolute',
            right: 0,
            minWidth: 150,
            border: `1px solid ${palette.grey[400]}`,
            borderRadius: 3,
            zIndex: zIndex.modal,

            'html[dir=rtl] &': {
                right: 'auto',
                left: 0,
            },
        },
    }),
    { name: 'Menu' },
);

function Menu(props) {
    const { className, children } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);

    useClickOutside(menuRef, () => {
        setOpen(false);
    });

    return (
        <div className={classNames(classes.root, className)} ref={menuRef}>
            <MenuButton onClick={() => setOpen(!open)} />
            {open && <ul className={classNames(classes.list)}>{children}</ul>}
        </div>
    );
}

Menu.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Menu;
