import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import has from 'lodash/has';
import { isLightColor } from 'client/helpers/colorUtils';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            padding: `${spacing(1)}px ${spacing(2)}px`,
            borderRadius: 8,
            border: '1px transparent',
            outline: 'none',
            fontSize: '1rem',
            fontWeight: 600,
            textDecoration: 'none',
            display: 'inline-block',
            textAlign: 'center',
            cursor: 'pointer',
        },

        square: {
            borderRadius: 0,
        },

        primary: {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,

            '&.outlined': {
                background: 'none',
                fontWeight: 400,
                color: isLightColor(palette.primary.main, 0.8)
                    ? palette.text.primary
                    : palette.primary.main,
                border: `1px solid ${
                    isLightColor(palette.primary.main, 0.8)
                        ? palette.text.primary
                        : palette.primary.main
                }`,
            },
        },

        secondary: {
            backgroundColor: palette.secondary.main,
            color: palette.secondary.contrastText,

            '&.outlined': {
                background: 'none',
                fontWeight: 400,
                color: isLightColor(palette.secondary.main, 0.8)
                    ? palette.text.primary
                    : palette.secondary.main,
                border: `1px solid ${
                    isLightColor(palette.secondary.main, 0.8)
                        ? palette.text.primary
                        : palette.secondary.main
                }`,
            },
        },

        accent: {
            backgroundColor: palette.accent.main,
            color: palette.accent.contrastText,

            '&.outlined': {
                background: 'none',
                fontWeight: 400,
                color: isLightColor(palette.accent.main, 0.8)
                    ? palette.text.primary
                    : palette.accent.main,
                border: `1px solid ${
                    isLightColor(palette.accent.main, 0.8)
                        ? palette.text.primary
                        : palette.accent.main
                }`,
            },
        },

        disabled: {
            pointerEvents: 'none',
            filter: 'grayscale(60%)',
        },
    }),
    { name: 'button' },
);

function Button({
    children,
    className,
    color = 'secondary',
    link,
    square,
    variant = 'contained',
    disabled,
    ...rest
}) {
    const classes = useStyles();
    const Component = link ? 'a' : 'button';

    const isSupportedColor = has(classes, color);

    return (
        <Component
            className={classNames(
                classes.root,
                {
                    [classes.square]: square,
                    [classes[color]]: isSupportedColor,
                    [classes.disabled]: disabled,
                },
                variant === 'outlined' ? 'outlined' : null,
                className,
            )}
            {...rest}
        >
            {children}
        </Component>
    );
}

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'accent', 'outlined']),
    disabled: PropTypes.bool,
    href: PropTypes.string,
    link: PropTypes.bool,
    square: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'outlined']),
};

export default Button;
