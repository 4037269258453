import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageSection from '../../components/PageSection/PageSection';
import MakeDonationWidget from '../../components/MakeDonationWidget/MakeDonationWidget';
import DonateButton from '../../components/DonateButton/DonateButton';
import CustomLogicDonationInput from './CustomLogicWidget/CustomLogicDonationInput';
import {
    updateDonationAmount,
    updateDonationCurrency,
    selectDonationAmount,
    selectDonationCurrency,
    selectBitEnabled,
    updateDonationTier,
    selectDonationTier,
    selectCustomLogicState,
    updateCustomLogicState,
} from './checkoutSlice';
import {
    selectPageSpecificCampaignData,
    selectEnableDonations,
    selectHideDonateArea,
    selectDonationTiersSettings,
    selectCustomLogicSettings,
    selectPageDesign,
} from '../campaign/campaignSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { CAMPAIGN_PAGE_DESIGNS, PAYMENT_METHODS } from 'common/constants';
import { currencyLabel } from 'common/helpers';
import Card from 'client/componentLibrary/Card';
import Button from 'client/componentLibrary/Button';
import useIsMobile from 'client/hooks/isMobile';
import MinimalDonationInput from '../../components/MinimalDonationInput';
import Text from '../../components/Text/Text';
import { getSelectedDonationTier } from 'common/helpers/donationTier';
import { CAMPAIGN_CUSTOM_LOGIC_TYPES } from 'common/constants/campaign';

const MOBILE_THRESHOLD = 600;

const useStyles = makeStyles(({ spacing, zIndex }) => ({
    classic: {
        display: 'grid',
        gridTemplateColumns: '1fr 181px',
        gridGap: '20px 20px',
        gap: '20px 20px',

        '@media (max-width: 1023px)': {
            gridGap: '15px',
            gap: '15px',
            marginBottom: 30,
        },
        '@media (max-width: 600px)': {
            marginBottom: 12,
            padding: 0,
            gridTemplateColumns: '1fr',
            gridGap: 0,
            gap: 0,
            width: '100%',
            fontSize: 22,
            '& button': {
                position: 'fixed',
                left: 0,
                bottom: 0,
                zIndex: 10,
            },
        },
    },
    modern: {
        padding: 0,
    },
    widget: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1),
    },
    textWidget: {
        padding: spacing(2),
    },
    donateButton: {
        display: 'inline-block',
        padding: `${spacing(1)}px ${spacing(2.5)}px`,
        fontSize: '1.25rem',
        width: '100%',
    },
    floatingDonateButton: {
        width: 'auto',
        padding: `${spacing(2)}px ${spacing(2.5)}px`,
        position: 'fixed',
        left: spacing(1),
        bottom: spacing(1),
        right: spacing(1),
        zIndex: zIndex.modal + ' !important',
    },
    donationText: {
        wordBreak: 'break-word',

        '& *': {
            lineHeight: 1.25,
        },
        '& p': {
            paddingBottom: spacing(1),
        },
    },
}));

function DonationWidget({
    className,
    currencies,
    disabled = false,
    handleDonateNowClick,
    multiplier,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { formatMessage } = useIntl();
    const { width } = useIsMobile();
    const enableDonations = useSelector(selectEnableDonations);
    const hideDonateArea = useSelector(selectHideDonateArea);
    const { donationText, showDonationText } = useSelector(
        selectPageSpecificCampaignData,
    );
    const pageDesign = useSelector(selectPageDesign);
    const amount = useSelector(selectDonationAmount);
    const currency = useSelector(selectDonationCurrency);
    const isBitEnabled = useSelector(selectBitEnabled);
    const showDonationTextWidget = !!donationText && showDonationText;

    const onDonateNowClick = paymentMethod => {
        handleDonateNowClick(paymentMethod ?? PAYMENT_METHODS.CARD, history);
    };

    const customLogicSettings = useSelector(selectCustomLogicSettings);
    const customLogicState = useSelector(selectCustomLogicState);
    const donationTiersSettings = useSelector(selectDonationTiersSettings);
    const donationTier = useSelector(selectDonationTier);
    const isPurimCustomLogic =
        customLogicSettings?.customLogicType ===
        CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM;

    const handleAmountChange = useCallback(event => {
        dispatch(updateDonationAmount(event.target.value));
    }, []);
    const handleCurrencyChange = useCallback(event => {
        dispatch(updateDonationCurrency(event.target.value));
    }, []);

    useEffect(() => {
        // initialize custom logic state per enabled custom logic
        if (
            customLogicSettings?.customLogicType ===
                CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM &&
            !customLogicState?.timestamp
        ) {
            dispatch(updateCustomLogicState({ numDonors: 1 }));
        }
    }, [customLogicSettings?.customLogicType, customLogicState?.timestamp]);

    useEffect(() => {
        dispatch(
            updateDonationTier(
                getSelectedDonationTier(
                    donationTiersSettings,
                    currency,
                    amount,
                    { ...customLogicSettings, ...customLogicState },
                ),
            ),
        );
    }, [
        currency,
        amount,
        donationTiersSettings,
        customLogicSettings?.customLogicType,
        customLogicState?.timestamp,
    ]);

    if (hideDonateArea) {
        return null;
    }

    return (
        <PageSection
            className={classNames(
                {
                    [classes.classic]:
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.CLASSIC,
                    [classes.modern]:
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN,
                },
                className,
                'donation-widget',
            )}
        >
            {pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN ? (
                <>
                    <Box mt={1} />
                    <Grid container spacing={2}>
                        {showDonationTextWidget && (
                            <Grid item xs={12}>
                                <Card className={classes.textWidget}>
                                    <Text html className={classes.donationText}>
                                        {donationText}
                                    </Text>
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Card
                                backgroundColor="background.default"
                                className={classes.widget}
                                elevation={1}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={7}>
                                        <CustomLogicDonationInput
                                            currency={currency}
                                            amount={amount}
                                        >
                                            <MinimalDonationInput
                                                options={currencies.map(
                                                    currency => ({
                                                        name: currencyLabel(
                                                            currency.name,
                                                        ),
                                                        value: currency.value,
                                                    }),
                                                )}
                                                placeholder={formatMessage({
                                                    id: 'EnterAmountInput.placeholder',
                                                    defaultMessage:
                                                        'Enter Amount...',
                                                })}
                                                onChange={handleAmountChange}
                                                value={amount || ''}
                                                selectProps={{
                                                    onChange:
                                                        handleCurrencyChange,
                                                    value: currency || '',
                                                }}
                                                donationTier={donationTier}
                                                pattern="[0-9]+$"
                                                disabled={disabled}
                                                withPrimaryBorder={
                                                    isPurimCustomLogic
                                                }
                                            />
                                        </CustomLogicDonationInput>
                                    </Grid>
                                    {width > MOBILE_THRESHOLD && (
                                        <Grid item xs={12} sm={5}>
                                            <Button
                                                disabled={
                                                    !enableDonations || disabled
                                                }
                                                onClick={onDonateNowClick}
                                                color="secondary"
                                                className={classes.donateButton}
                                            >
                                                <FormattedMessage
                                                    id="DonateButton.text"
                                                    defaultMessage="Donate Now!"
                                                />
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                        {width <= MOBILE_THRESHOLD && (
                            <Button
                                disabled={!enableDonations || disabled}
                                onClick={onDonateNowClick}
                                color="secondary"
                                className={classNames(
                                    classes.donateButton,
                                    classes.floatingDonateButton,
                                )}
                            >
                                <FormattedMessage
                                    id="DonateButton.text"
                                    defaultMessage="Donate Now!"
                                />
                            </Button>
                        )}
                    </Grid>
                </>
            ) : (
                <>
                    <MakeDonationWidget
                        amount={amount}
                        currency={currency}
                        multiplier={multiplier}
                        currencies={currencies}
                        donationText={donationText}
                        showDonationText={showDonationText}
                        CustomLogicInputComponent={CustomLogicDonationInput}
                        donationTier={donationTier}
                        onAmountChange={handleAmountChange}
                        onCurrencyChange={handleCurrencyChange}
                        disabled={disabled}
                    />
                    <DonateButton
                        onClick={onDonateNowClick}
                        disabled={!enableDonations || disabled}
                        multiplier={multiplier}
                        isBitEnabled={isBitEnabled}
                    >
                        <FormattedMessage
                            id="DonateButton.text"
                            defaultMessage="Donate Now!"
                        />
                    </DonateButton>
                </>
            )}
        </PageSection>
    );
}

DonationWidget.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    currencies: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        }),
    ),
    disabled: PropTypes.bool,
    handleDonateNowClick: PropTypes.func,
    multiplier: PropTypes.number,
};

export default DonationWidget;
