import * as React from 'react';
import PropTypes from 'prop-types';

function GiftRoundedIcon({ stroke = '#000', ...restProps }) {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={stroke}
            {...restProps}
        >
            <defs>
                <clipPath id="clip0_237_3640">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5 0.682373)"
                    />
                </clipPath>
                <clipPath id="clip1_237_3640">
                    <rect x="0.5" y="3.68237" width="16" height="13" rx="2" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip0_237_3640)">
                <g clipPath="url(#clip1_237_3640)">
                    <path d="M8.5 4.18237V16.1824" strokeLinecap="round" />
                    <path d="M15.5 12.6824H1.5" strokeLinecap="round" />
                </g>
                <rect
                    fill="none"
                    x="1"
                    y="4.18237"
                    width="15"
                    height="12"
                    rx="1.5"
                />
                <path d="M7 4.5484C7.66667 4.1635 7.66667 3.20125 7 2.81635L5.5 1.95032C4.83333 1.56542 4 2.04655 4 2.81635L4 4.5484C4 5.3182 4.83333 5.79932 5.5 5.41442L7 4.5484Z" />
                <path d="M10 2.81635C9.33333 3.20125 9.33333 4.1635 10 4.5484L11.5 5.41442C12.1667 5.79932 13 5.3182 13 4.5484L13 2.81635C13 2.04655 12.1667 1.56542 11.5 1.95032L10 2.81635Z" />
                <rect
                    x="7.5"
                    y="2.68237"
                    width="2"
                    height="2"
                    rx="0.5"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

GiftRoundedIcon.propTypes = {
    stroke: PropTypes.string,
};

export default GiftRoundedIcon;
