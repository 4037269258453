const _ = require('lodash');
const { isValidColor } = require('./color');

const getPaletteColor = (palette, color, fallback = 'transparent') => {
    if (isValidColor(_.get(palette, color))) {
        return _.get(palette, color);
    }
    if (isValidColor(_.get(palette, [color, 'main']))) {
        return _.get(palette, [color, 'main']);
    }
    if (isValidColor(color) || color === 'transparent') {
        return color;
    }
    return fallback;
};

module.exports = {
    getPaletteColor,
};
