import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCustomLogicSettings } from '../../campaign/campaignSlice';
import { CAMPAIGN_CUSTOM_LOGIC_TYPES } from 'common/constants/campaign';
import PurimDonationInput from './PurimDonationInput';

const CustomLogicComponents = {
    [CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM]: PurimDonationInput,
};

function CustomLogicDonationInput({ currency, amount, children }) {
    const customLogicSettings = useSelector(selectCustomLogicSettings);

    const CustomLogicComponent =
        CustomLogicComponents[customLogicSettings?.customLogicType];
    if (!CustomLogicComponent) {
        return <>{children}</>;
    }

    return (
        <CustomLogicComponent currency={currency} amount={amount}>
            {children}
        </CustomLogicComponent>
    );
}

CustomLogicDonationInput.propTypes = {
    currency: PropTypes.string,
    amount: PropTypes.number,
    children: PropTypes.node,
};

export default CustomLogicDonationInput;
