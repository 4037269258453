import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CheckIcon } from './CheckIcon.svg';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        position: 'relative',
        fontFamily: typography.fontFamily,
        paddingInlineStart: '30px',
    },
    input: {
        position: 'absolute',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        opacity: 0,
    },
    label: {
        display: 'inline-block',
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 15,
            height: 15,
            border: `1px solid ${palette.text.primary}`,
            borderRadius: 2,
            background: palette.background.default,
            left: 0,
            top: 3,
        },
        '& small': {
            fontSize: 13,
            fontWeight: 300,
        },
        '& a': {
            fontWeight: 500,
            color: palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        'html[dir=rtl] &': {
            '&::before': {
                left: 'auto',
                right: 0,
            },
        },
    },
    icon: {
        position: 'absolute',
        left: 5,
        top: 7,
        width: 7,
        height: 8.75,
        zIndex: 2,
        stroke: palette.text.primary,

        'html[dir=rtl] &': {
            left: 'auto',
            right: 5,
        },
    },
}));

function Checkbox(props) {
    const {
        className,
        children,
        onChange,
        defaultChecked = false,
        inputRef,
        ...rest
    } = props;
    const classes = useStyles();
    const [checked, setChecked] = useState(defaultChecked);

    const handleChange = event => {
        if (typeof onChange === 'function') {
            onChange(event);
        }
        setChecked(event.target.checked);
    };

    return (
        <label
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <input
                className={classes.input}
                type="checkbox"
                defaultChecked={defaultChecked}
                onChange={handleChange}
                ref={inputRef}
                {...rest}
            />
            {(rest.checked || (rest.checked == undefined && checked)) && (
                <CheckIcon className={classes.icon} />
            )}
            <span className={classes.label}>{children}</span>
        </label>
    );
}

Checkbox.propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.node,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    inputRef: PropTypes.func,
};

export default Checkbox;
