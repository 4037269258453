import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PageSection from '../../components/PageSection/PageSection';
import SuggestedAmounts from './SuggestedAmounts';
import { selectDonationCurrency, updateDonationAmount } from './checkoutSlice';
import {
    selectHideDonateArea,
    selectPageDesign,
    selectSuggestedAmounts,
} from '../campaign/campaignSlice';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';

const useStyles = makeStyles(({ palette, spacing }) => ({
    pageSection: {
        padding: spacing(2),
        margin: `${spacing(2)}px auto`,
    },
    classic: {},
    modern: {
        backgroundColor: palette.background.paper,
        border: `0.5px solid ${palette.background.paperBorder}`,
        borderRadius: spacing(2),
    },
    root: {
        marginBottom: 0,
    },
    heading: {
        textAlign: 'center',
        fontSize: '1.2rem',
        fontWeight: 600,

        '@media (max-width: 600px)': {
            fontSize: '1.1rem',
        },
    },
    suggestedAmount: {
        marginLeft: spacing(1.5),
        marginRight: spacing(1.5),
        paddingLeft: spacing(5),
        paddingRight: spacing(5),
        fontSize: '1.1rem',
        backgroundColor: palette.background.default,

        '@media (max-width: 960px)': {
            marginLeft: spacing(1),
            marginRight: spacing(1),
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
        },

        '@media (max-width: 600px)': {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
            paddingLeft: spacing(2.5),
            paddingRight: spacing(2.5),
            fontSize: '1rem',
        },
    },
}));

function SuggestedAmountsWidget() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pageDesign = useSelector(selectPageDesign);
    const hideDonateArea = useSelector(selectHideDonateArea);
    const currency = useSelector(selectDonationCurrency);
    const suggestedAmounts = useSelector(selectSuggestedAmounts);

    const handleAmountChange = value => {
        dispatch(updateDonationAmount(value));
    };

    if (hideDonateArea || !currency || !suggestedAmounts?.length) {
        return null;
    }

    return (
        <PageSection
            className={classNames(
                'suggested-amounts-widget',
                classes.pageSection,
                {
                    [classes.classic]:
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.CLASSIC,
                    [classes.modern]:
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN,
                },
            )}
        >
            <SuggestedAmounts
                currency={currency}
                onAmountChange={handleAmountChange}
                classes={classes}
            />
        </PageSection>
    );
}

export default SuggestedAmountsWidget;
