import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography, zIndex }) => ({
    previewMode: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        fontSize: typography.pxToRem(16),
        fontWeight: 600,
        zIndex: zIndex.modal,
        borderBottom: `1px solid ${palette.grey[400]}`,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
}));

function PreviewBanner({ campaignId }) {
    const classes = useStyles();

    return (
        <div className={classes.previewMode}>
            <span>
                <FormattedMessage
                    id="preview.previewMode"
                    defaultMessage="PREVIEW MODE"
                />
            </span>
            <Box m={0.5} />
            <a
                target="_blank"
                rel="noreferrer"
                href={`/dashboard/campaign/${campaignId}`}
            >
                <FormattedMessage
                    id="preview.openInDashboard"
                    defaultMessage="Open in Dashboard"
                />
            </a>
        </div>
    );
}

PreviewBanner.propTypes = {
    campaignId: PropTypes.number.isRequired,
};

export default PreviewBanner;
