import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'client/componentLibrary/Card';
import Progress from 'client/componentLibrary/Progress';
import { getPaletteColor } from 'common/helpers/palette';
import { useSelector } from 'react-redux';
import { selectActiveBonusRoundIndex } from '../../features/campaign/campaignSlice';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    content: {
        padding: spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%',
    },
    alignCenter: {
        alignItems: 'center',
    },
    mainLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.85rem',
        fontFamily: typography.fontFamily,

        '@media (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    raisedAmount: {
        fontSize: '4.5rem',
        fontWeight: 400,
        lineHeight: 1.2,
        fontFamily: typography.fontFamily,
        color: palette.text.primary,
        marginBottom: spacing(1),
        '@media (max-width: 600px)': {
            fontSize: '3rem',
        },
    },
    goals: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        color: palette.text.primary,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBottom: spacing(1),
        '@media (max-width: 600px)': {
            fontSize: '1.2rem',
        },
    },
    goalsLarge: {
        fontSize: '2rem',
        flexDirection: 'column',
        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(24),
        },
    },
    goalLabel: {
        display: 'flex',
        whiteSpace: 'break-spaces',
        marginInlineEnd: spacing(1),
    },
    progressBar: {
        width: '98%',
        margin: `0 ${spacing(0.5)}px`,
    },
    margin0: {
        margin: 0,
    },
}));

function ProgressWidget(props) {
    const { formatMessage } = useIntl();

    const {
        amount = 0,
        className,
        currencySign = '$',
        goal = 0,
        goal1Color = 'primary',
        goal1Percent = 0,
        goal2Color = 'accent',
        goal2Percent = 0,
        goalColor = 'secondary',
        goalPercent = 0,
        hideGoalAmount,
        hideProgressBar,
        hideRaisedAmount,
        totalGoalAmount = 0,
        totalPercent = 0,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const activeBonusGoalIndex = useSelector(selectActiveBonusRoundIndex);
    const hasEnteredBonusGoal = !isNaN(activeBonusGoalIndex);
    const onlyShowProgressBar =
        hideGoalAmount && hideRaisedAmount && !hideProgressBar;
    const onlyShowGoals =
        !hideGoalAmount && hideRaisedAmount && hideProgressBar;

    const bonusGoalColor = getPaletteColor(
        palette,
        activeBonusGoalIndex === 1 ? goal2Color : goal1Color,
        palette.text.primary,
    );

    const goalTitle = hasEnteredBonusGoal
        ? formatMessage({
              id: 'common.originalGoal',
              defaultMessage: 'Original Goal',
          })
        : formatMessage({
              id: 'DonationWidget.goalTitle',
              defaultMessage: 'Campaign Goal',
          });

    return (
        <Card
            className={classNames(
                classes.root,
                { [classes.alignCenter]: !hideRaisedAmount && hideGoalAmount },
                className,
                'stats-widget-progress',
            )}
        >
            <div
                className={classNames(classes.content, {
                    [classes.alignCenter]: !hideRaisedAmount && hideGoalAmount,
                })}
            >
                {!hideRaisedAmount && (
                    <div className={classes.raisedAmount}>
                        {currencySign}
                        {amount?.toLocaleString('en-EN')}
                    </div>
                )}
                {!hideRaisedAmount && hideGoalAmount && (
                    <div className={classes.mainLabel}>
                        <FormattedMessage
                            id="Amount.raised"
                            defaultMessage="Raised"
                        />
                    </div>
                )}
                {!hideGoalAmount && (
                    <div
                        className={classNames(classes.goals, {
                            [classes.goalsLarge]: hideRaisedAmount,
                            [classes.margin0]: onlyShowGoals,
                        })}
                    >
                        <span className={classes.goalLabel}>
                            <span>
                                {currencySign}
                                {goal.toLocaleString('en-EN')}{' '}
                            </span>
                            <span>{goalTitle}</span>
                        </span>

                        {hasEnteredBonusGoal && (
                            <span className={classes.goalLabel}>
                                <span style={{ color: bonusGoalColor }}>
                                    {currencySign}
                                    {totalGoalAmount.toLocaleString(
                                        'en-EN',
                                    )}{' '}
                                </span>
                                <span>
                                    <FormattedMessage
                                        id={'DonationWidget.bonusTitle'}
                                        defaultMessage={'Bonus Goal'}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                )}
                {!hideProgressBar && (
                    <>
                        <Box mt={4.5} />
                        <Progress
                            className={classNames(classes.progressBar)}
                            label={`${totalPercent}%`}
                            showLabel
                            size={6}
                            goals={[
                                {
                                    percent: goalPercent,
                                    color: goalColor,
                                },
                                {
                                    percent: goal1Percent,
                                    color: goal1Color,
                                },
                                {
                                    percent: goal2Percent,
                                    color: goal2Color,
                                },
                            ]}
                        />
                        {onlyShowProgressBar && <Box mb={4} />}
                    </>
                )}
            </div>
        </Card>
    );
}

ProgressWidget.propTypes = {
    amount: PropTypes.number,
    bonusGoal: PropTypes.number,
    className: PropTypes.string,
    currency: PropTypes.string,
    currencySign: PropTypes.string,
    goal: PropTypes.number,
    goal1Color: PropTypes.string,
    goal1Percent: PropTypes.number,
    goal2Color: PropTypes.string,
    goal2Percent: PropTypes.number,
    goalColor: PropTypes.string,
    goalPercent: PropTypes.number,
    hideGoalAmount: PropTypes.bool,
    hideProgressBar: PropTypes.bool,
    hideRaisedAmount: PropTypes.bool,
    nextBonusRoundGoal: PropTypes.number,
    totalGoalAmount: PropTypes.number,
    totalPercent: PropTypes.number,
};

export default ProgressWidget;
