const CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES = Object.freeze({
    ASK_EN: 'AskEN',
});

const CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES.ASK_EN]:
        "{{NAME}}, Last year, you were a generous supporter of {{ORGANIZATION}}'s campaign. You donated {{AMOUNT}} and made a tremendous impact through your generosity. We've just launched a brand new campaign, and I'm turning to you again this year. Can I count on your support again? {{NEWER_CAMPAIGN_LINK}}",
});

const CONNECT_BALANCE_WARNING = Object.freeze({
    SUBJECT: 'CauseMatch: Low Connect Balance Alert',
    BODY: `<p>Hi {{CLIENT_NAME}},</p>
<p>We're so glad to see you making great use of our Connect services! We're reaching out to let you know that the balance for your account, {{ACCOUNT_NAME}}, is running low. As of now, your account balance is \${{BALANCE}}.</p>
<p>To ensure uninterrupted access to email, text, and WhatsApp your contacts, please top up your balance. You can do this by contacting your Customer Success Manager, reaching out to us at support@causematch.com, or using the Live Chat feature on your dashboard for assistance.</p>
<p>
Best regards,
<br>
The CauseMatch Team
</p>`,
});

const CONNECT_BALANCE_INSUFFICIENT = Object.freeze({
    SUBJECT: 'CauseMatch: Connect Services Paused - Action Required',
    BODY: `<p>Hi {{CLIENT_NAME}},</p>
<p>We're reaching out to inform you that the Connect balance for your account, {{ACCOUNT_NAME}}, has reached $0, resulting in a temporary pause of your Connect services (email, text, and WhatsApp).</p>
<p>We know how valuable these tools are for engaging with your donors and ambassadors. To get your Connect services back up and running, you will need to top up your balance. You can do this by contacting your Customer Success Manager, reaching out to us at support@causematch.com, or using the Live Chat feature on your dashboard for assistance.</p>
<p>
Best regards,
<br>
The CauseMatch Team
</p>`,
});

const DONOR_WHATSAPP_TEMPLATES = Object.freeze({
    BEFORE_NEXT_CAMPAIGN_EN: 'BeforeNextCampaignEN',
    THANKYOU_EN: 'ThankYouEN',
});

const DONOR_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [DONOR_WHATSAPP_TEMPLATES.BEFORE_NEXT_CAMPAIGN_EN]:
        '{{NAME}}, you are part of the {{ORGANIZATION}} family. Your past generosity does not go unnoticed. In a short while, we are going to embark on a fundraising campaign, our most important one of the year. When we launch, can we count on your support again? Thank you. Truly and sincerely. Your commitment propels us forward!',
    [DONOR_WHATSAPP_TEMPLATES.THANKYOU_EN]:
        '{{NAME}}, thank you again for donating to our crowdfunding campaign. I am still so overwhelmed by your generosity and commitment to our cause. \n\nAs a donor to {{ORGANIZATION}}, you are the engine that makes our organization run. You own a share of every milestone. Your gift has had a tremendous impact. \n\nThank you for being a part of the {{ORGANIZATION}} family.',
});

const P2P_WHATSAPP_TEMPLATES = Object.freeze({
    GOAL_PROGRESS_25_EN: 'GoalProgress25EN',
    GOAL_PROGRESS_50_EN: 'GoalProgress50EN',
    GOAL_PROGRESS_75_EN: 'GoalProgress75EN',
    GOAL_PROGRESS_100_EN: 'GoalProgress100EN',
    PERSONAL_LINK_EN: 'PersonalFundraisingLinkEN',
    PERSONAL_LINK_HE: 'PersonalFundraisingLinkHE',
});

const P2P_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_25_EN]:
        "A standing ovation for {{NAME}}! 👏🎉 You've raised 25% of your personal goal through {{DONOR_COUNT}} donors. Your hard work is an inspiration to everyone working on this campaign. Keep shining and leading the way!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_50_EN]:
        "Sending you a round of applause for hitting 50% of your personal fundraising goal! 🌊💪 Go {{NAME}}, Go! Keep the pedal to the medal and let's finish strong!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_75_EN]:
        "You are almost home! 💙👏 You just hit 75% of your fundraising goal. We are so incredibly lucky to have you on our team. Don't stop now though. You've got momentum. Here we go!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_100_EN]:
        "Wow 🙌 wow 🙌 wow 🙌. You did it. 100% of your fundraising goal! There is still time left so would you consider setting a Bonus Goal for yourself? You have shown what you can do when you put your mind to it. Let's keep it going until the final whistle blows!",
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_EN]:
        'Dear {{NAME}}, \nThis is the personal link for your fundraiser {{PERSONAL_LINK}} \nPlease help us reach the goal by *sharing the campaign with friends and family*.',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_HE]:
        'שלום {{NAME}}, \nתודה על הרשמתך כשגריר/ה בקמפיין של {{ORGANIZATION}}! \nהנה הקישור לדף עם היעד האישי שלך: {{PERSONAL_LINK}}. \nלאחר תחילת הקמפיין, עזור/עזרי לנו להגיע למטרה על ידי שיתוף הקישור עם חברים, מכרים ובני משפחה! \nאנחנו כאן כדי לעזור, אל תהסס/י לפנות אלינו לכל שאלה ולתמיכה. \nתודה שוב על ההתגייסות! \n{{ORGANIZATION}}',
});

const WHATSAPP_TEMPLATES_INFO = Object.freeze({
    ...CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO,
    ...DONOR_WHATSAPP_TEMPLATES_INFO,
    ...P2P_WHATSAPP_TEMPLATES_INFO,
});

const WHATSAPP_TEMPLATES_TWILIO_SID_MAP = Object.freeze({
    [CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES.ASK_EN]:
        'HX29fc20aa3aa61390e7871088dce5e797',
    [DONOR_WHATSAPP_TEMPLATES.BEFORE_NEXT_CAMPAIGN_EN]:
        'HX3db9b19b84fa8f1840b7a234147b058f',
    [DONOR_WHATSAPP_TEMPLATES.THANKYOU_EN]:
        'HXc97d3a35dcaa5a29310d1bc36fea95be',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_25_EN]:
        'HX07d2f08af939ea0a88f6633bc461b989',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_50_EN]:
        'HX853161a4af1c74eb1845ba0df98d09dd',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_75_EN]:
        'HX1e9614e9fac6579c6da541ed5d3efb43',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_100_EN]:
        'HX9fd3f6878f72e808ff19c3adc66c05dd',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_EN]:
        'HX06d24ddca946a6651b8dfc5c823e978a',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_HE]:
        'HX2dafb615a56e5005c884e53ac4a9784b',
});

const PLEDGE_TEMPLATES = Object.freeze({
    CLIENT_REMINDER: 'ClientReminder',
});

const PLEDGE_TEMPLATES_INFO = Object.freeze({
    [PLEDGE_TEMPLATES.CLIENT_REMINDER]: `<p>Hello,</p>
<p>This is an automated reminder that a pledge made by a donor on your campaign is now due. Below are the details of the donor:</p>

<p>Name: {{NAME}}</p>
<p>Pledge Amount: {{AMOUNT}}</p>
<p>Pledge Due Date: {{PLEDGE_DATE}}</p>
<p>Email: {{EMAIL}}</p>
<p>Phone: {{PHONE}}</p>
<p>We recommend reaching out to {{NAME}} with a friendly reminder about completing their donation. Remember to thank them for their generosity and share how their contribution will make a difference. (Below the signature of this email, we've included a template you can use.)</p>
<p>-The CauseMatch Campaign Success Team</p>

<p>____</p>
<p>Here is the Pledge Reminder Template</p>
<p>Subj: A Gentle Reminder About Your Generous Pledge</p>
<p>Dear [Donor's First Name],</p>
<p>Thank you for your incredible generosity and support of [Organization Name]. Your pledge of [amount] means so much to us.</p>
<p>This is a friendly reminder that your pledge date has arrived. If you’ve already taken care of it, thank you! If not, you can fulfill your pledge quickly and securely by clicking [here/link].</p>
<p>Your gift makes a real difference, and we’re deeply grateful to have you as part of the [Organization Name] family. Thank you for helping us create lasting change!</p>
<p>Thank you,</p>
<p>[Signature]</p>`,
});

const P2P_DONATION_NOTIFICATION_SMS_TEMPLATE = `You have a new donation!\n{{NAME}} donated {{LAYER_ITEM_AMOUNT}} to {{ORGANIZATION}} via {{LAYER_ITEM_NAME}} on {{DATE}}`;
const P2P_DONATION_NOTIFICATION_SMS_TEMPLATE_HE = `יש לך תרומה חדשה! {{NAME}} תרם {{LAYER_ITEM_AMOUNT}} ל{{ORGANIZATION}} דרך {{LAYER_ITEM_NAME}} ב{{DATE_DMY}}.`;

module.exports = {
    CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES,
    CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO,
    CONNECT_BALANCE_WARNING,
    CONNECT_BALANCE_INSUFFICIENT,
    DONOR_WHATSAPP_TEMPLATES,
    DONOR_WHATSAPP_TEMPLATES_INFO,
    P2P_WHATSAPP_TEMPLATES,
    P2P_WHATSAPP_TEMPLATES_INFO,
    PLEDGE_TEMPLATES,
    PLEDGE_TEMPLATES_INFO,
    WHATSAPP_TEMPLATES_INFO,
    WHATSAPP_TEMPLATES_TWILIO_SID_MAP,
    P2P_DONATION_NOTIFICATION_SMS_TEMPLATE,
    P2P_DONATION_NOTIFICATION_SMS_TEMPLATE_HE,
};
