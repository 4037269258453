import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BitIcon from './BitIcon';
import BitIconChecked from './BitIconChecked';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 40,
        height: 21,
        '@media (max-width: 600px)': {
            width: 37,
            height: 20,
        },
    },
}));

function BitLogo(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <SvgIcon
                {...props}
                component={props.checked ? BitIconChecked : BitIcon}
                viewBox={props.viewbox || '0 0 113.462 123.042'}
                className={props.className ? props.className : classes.svg}
            ></SvgIcon>
        </div>
    );
}

BitLogo.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    viewbox: PropTypes.string,
};

export default BitLogo;
