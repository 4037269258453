import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import MinusIcon from 'client/components/Icons/Minus';
import PlusIcon from 'client/components/Icons/Plus';
import Button from 'client/componentLibrary/Button';
import useIsMobile from 'client/hooks/isMobile';
import { FormattedMessage } from 'react-intl';

const DonateButton = ({ children, ...rest }) => (
    <Button color="secondary" variant="contained" {...rest}>
        {children}
    </Button>
);
DonateButton.propTypes = {
    children: PropTypes.node,
};

const PerkActions = ({
    canDecreaseCounter,
    canIncreaseCounter,
    classes,
    count,
    decreaseCounter,
    disabled,
    handleDonateClick,
    increaseCounter,
    isSelectEnabled,
    shouldShowCounter,
}) => {
    const { isMobile } = useIsMobile();

    const donateBtnProps = {
        className: classNames(classes.donateButton, 'perk-donate-btn'),
        onClick: handleDonateClick,
        square: shouldShowCounter || !isMobile,
        disabled,
    };

    return (
        <div
            className={classNames(classes.actions, {
                [classes.disabled]: disabled,
                [classes.centerActions]: !shouldShowCounter,
                [classes.counterBuffer]: shouldShowCounter,
            })}
        >
            <div className={classes.actionRow}>
                {shouldShowCounter ? (
                    <>
                        <div
                            className={classNames(
                                classes.counterBtn,
                                classes.roundCounterBtnStart,
                                canDecreaseCounter ? '' : 'disabled',
                            )}
                            disabled={!canDecreaseCounter}
                            onClick={decreaseCounter}
                        >
                            <SvgIcon component={MinusIcon} />
                        </div>
                        <DonateButton {...donateBtnProps}>
                            {isSelectEnabled && count > 1 && (
                                <div className={classes.perkCount}>{count}</div>
                            )}
                            <FormattedMessage id="DonateButton.text" />
                        </DonateButton>
                        <div
                            className={classNames(
                                classes.counterBtn,
                                classes.roundCounterBtnEnd,
                                canIncreaseCounter ? '' : 'disabled',
                            )}
                            disabled={!canIncreaseCounter}
                            onClick={increaseCounter}
                        >
                            <SvgIcon component={PlusIcon} />
                        </div>
                    </>
                ) : (
                    <DonateButton {...donateBtnProps}>
                        <FormattedMessage id="DonateButton.text" />
                    </DonateButton>
                )}
            </div>
        </div>
    );
};

PerkActions.propTypes = {
    canDecreaseCounter: PropTypes.bool,
    canIncreaseCounter: PropTypes.bool,
    classes: PropTypes.object,
    count: PropTypes.number,
    decreaseCounter: PropTypes.func,
    disabled: PropTypes.bool,
    handleDonateClick: PropTypes.func,
    increaseCounter: PropTypes.func,
    isSelectEnabled: PropTypes.bool,
    shouldShowCounter: PropTypes.bool,
};

export default PerkActions;
