import get from 'lodash/get';
export const getPerkLogo = (perk, campaign, pageName) => {
    if (perk?.logo) {
        return perk.logo;
    }
    const campaignPageOrgLogo = get(campaign, `locales.${pageName}.logo`, '');
    if (get(campaign, `landing.useOrgLogoAsPerkLogo`, '')) {
        return campaignPageOrgLogo;
    }
};
