import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import CountrySelector from 'client/components/Phone/CountrySelector';
import addDashedBorderAfterOption from 'client/components/Phone/addDashedBorderAfterOption';
import PhoneNumber from 'client/components/Phone/PhoneNumber';
import { COUNTRIES_INFO, TOP_COUNTRIES_COUNT } from 'common/constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import { KeyboardArrowDown } from '@material-ui/icons';
import { getDefaultFieldLabel } from 'client/helpers/checkoutFields';
import { useIntl } from 'react-intl';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => {
    const lightErrorColor = changeColorAlpha(palette.error.light, 0.1);
    const lightSuccessColor = changeColorAlpha(palette.success.light, 0.1);
    return {
        phoneWithPrefix: {
            display: 'flex',
            width: '100%',
            ...addDashedBorderAfterOption(TOP_COUNTRIES_COUNT),
        },
        phonePrefixSelect: {
            width: '15rem',
            marginRight: '1rem',

            '& ::placeholder': {
                color: changeColorAlpha(palette.text.primary, 0.85),
                opacity: 1,
            },
            backgroundColor: palette.background.default,
            '&.hasError': {
                backgroundColor: lightErrorColor,
                color: lightErrorColor,
            },
            '& fieldset': {
                border: `1px solid ${palette.text.primary}`,
            },
            '& input': {
                fontFamily: typography.fontFamily,
                [breakpoints.down('600')]: {
                    height: '23px',
                },
            },
        },
        phonePrefixInput: {
            borderRadius: '5px',
        },
        phoneField: {
            borderRadius: '5px',
            [breakpoints.down('600')]: {
                border: `1px solid ${palette.text.primary}`,

                '&.noMuiBordersOnFocus fieldset': {
                    border: `none !important`,
                },
            },
            '& ::placeholder': {
                color: changeColorAlpha(palette.text.primary, 0.85),
                opacity: 1,
            },
            '& .Mui-error': {
                '& input': {
                    '&:autofill': {
                        boxShadow: `0 0 0 50px ${lightErrorColor} inset`,
                    },
                },
            },
            '& fieldset': {
                border: `1px solid ${palette.text.primary}`,
            },
            '& input': {
                fontFamily: typography.fontFamily,
                [breakpoints.down('600')]: {
                    paddingTop: '4.5px',
                    paddingBottom: '4.5px',
                    height: '34px',
                },
                '&:autofill': {
                    boxShadow: `0 0 0 50px ${lightSuccessColor} inset`,
                },
            },
        },
    };
});

function DonationFormPhoneField({
    control,
    name,
    config,
    errors,
    countryCodeClassName,
    phoneNumberClassName,
    endIcon,
    defaultCountry,
    phonePrefixEditable,
}) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('600'));
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const defaultCountryOption =
        COUNTRIES_INFO.find(country => country.flagCode === defaultCountry) ||
        {};

    const [selectedCountry, setSelectedCountry] =
        useState(defaultCountryOption);

    let errorToDisplay = errors.phone;
    if (!errorToDisplay) {
        errorToDisplay = errors.phonePrefix;
    }

    const containerClasses = [classes.phoneWithPrefix];

    if (errors[name]) {
        containerClasses.push(classes.error);
    }

    useEffect(() => {
        const defaultCountryOption = COUNTRIES_INFO.find(
            country => country.flagCode === defaultCountry,
        );
        if (defaultCountryOption) {
            setSelectedCountry(defaultCountryOption);
        }
    }, [defaultCountry]);

    return (
        <FormGroup key={name}>
            <div className={classes.phoneWithPrefix} dir="ltr">
                <Controller
                    render={({ name: prefName, onBlur, onChange, ref }) => {
                        return (
                            <CountrySelector
                                countries={COUNTRIES_INFO}
                                clientCountryCode={defaultCountry}
                                input={{
                                    name: prefName,
                                    value: selectedCountry.flagCode || null,
                                    onBlur: onBlur,
                                    onChange: newlySelectedCountry => {
                                        setSelectedCountry(
                                            newlySelectedCountry,
                                        );
                                        onChange(
                                            newlySelectedCountry.phonePrefix,
                                        );
                                    },
                                    ref,
                                }}
                                inputRootClassname={classNames(
                                    countryCodeClassName,
                                    'noMuiBordersOnFocus',
                                )}
                                textFieldProps={{
                                    label: '',
                                    placeholder: formatMessage({
                                        id: 'DonationForm.defaultFields.country',
                                        defaultMessage: 'Country',
                                    }),
                                    size: isSmallScreen ? 'small' : 'medium',
                                }}
                                popupIcon={<KeyboardArrowDown />}
                                className={classNames(
                                    classes.phonePrefixSelect,
                                )}
                                disabled={!phonePrefixEditable}
                            />
                        );
                    }}
                    control={control}
                    name={'phonePrefix'}
                    defaultValue={
                        defaultCountryOption.phonePrefix
                            ? defaultCountryOption.phonePrefix
                            : null
                    }
                />
                <Controller
                    render={({
                        name: prefName,
                        ref,
                        value,
                        onBlur,
                        onChange,
                    }) => {
                        return (
                            <PhoneNumber
                                input={{
                                    name: prefName,
                                    value: value,
                                    ref: ref,
                                    onBlur: event => {
                                        onBlur(event);
                                    },
                                    onChange: onChange,
                                }}
                                phoneNumberFormat={
                                    selectedCountry.phoneNumberFormat || ''
                                }
                                variant="outlined"
                                fullWidth
                                placeholder={
                                    `${config.isRequired ? '* ' : ''}` +
                                    getDefaultFieldLabel(formatMessage, {
                                        key: name,
                                        field: config,
                                    })
                                }
                                error={errors[name]}
                                className={classNames(
                                    classes.phoneField,
                                    phoneNumberClassName,
                                    'noMuiBordersOnFocus',
                                )}
                                size={isSmallScreen ? 'small' : 'medium'}
                                endIcon={endIcon}
                            />
                        );
                    }}
                    control={control}
                    rules={{ required: true }}
                    name={'phone'}
                    defaultValue={''}
                />
            </div>
            <FormInputHelperText error={errorToDisplay} />
        </FormGroup>
    );
}

DonationFormPhoneField.propTypes = {
    name: PropTypes.string,
    config: PropTypes.shape({
        isRequired: PropTypes.bool,
    }),
    endIcon: PropTypes.node,
    control: PropTypes.object,
    errors: PropTypes.object,
    phoneNumberClassName: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    countryCodeClassName: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    defaultCountry: PropTypes.string,
    phonePrefixEditable: PropTypes.bool,
};

export default DonationFormPhoneField;
