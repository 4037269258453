import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormatText from 'client/screens/Dashboard/Form/Text/NumberFormatText';

const useStyles = makeStyles(() => ({
    emptyInput: {
        '&::placeholder': {
            color: `currentColor !important`,
        },
    },
}));

const PhoneNumberInput = React.forwardRef(function PurePhoneNumberInput(
    { className, placeholder, rawValue, value, ...props },
    ref,
) {
    const classes = useStyles();

    const isEmpty = rawValue === '';

    return (
        <input
            ref={ref}
            className={classNames(
                className,
                isEmpty ? classes.emptyInput : null,
            )}
            placeholder={isEmpty ? value : placeholder}
            value={isEmpty ? '' : value}
            {...props}
        />
    );
});
PhoneNumberInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    rawValue: PropTypes.string,
    value: PropTypes.string,
};

function PhoneNumber(props) {
    const { input, phoneNumberFormat = '', endIcon, error, ...rest } = props;
    const { value, onBlur, ...restInput } = input;
    const userInputContainedLeadingZero = useRef(false);

    const formatProps =
        phoneNumberFormat.length > 0
            ? {
                  format: phoneNumberFormat,
                  mask: '_',
                  allowEmptyFormatting: true,
                  useFloatValue: false,
              }
            : {
                  useFloatValue: false,
              };

    const removeLeadingZeroIfNeeded = ({ key }) => {
        // if user entered zero and then wants to delete it,
        // it wouldn't work since leading zero is rendered as a div in startAdornment,
        // so we need to separately handle the keydown event to handle this case
        if (key === 'Backspace' && value === '') {
            userInputContainedLeadingZero.current = false;
            // trigger re-render
            input.onChange('');
        }
    };

    const omitLeadingZero = inputValue => inputValue.replace(/^0+/, '');

    if (value !== omitLeadingZero(value)) {
        userInputContainedLeadingZero.current = true;
    }

    if (value.length > 2) {
        userInputContainedLeadingZero.current = false;
    }

    const showLeadingZero =
        phoneNumberFormat.length && userInputContainedLeadingZero.current;

    return (
        <TextField
            {...rest}
            error={!!error}
            InputProps={{
                startAdornment: showLeadingZero ? (
                    <span className="styledStartAdornment">0</span>
                ) : (
                    ''
                ),
                endAdornment: endIcon,
                inputComponent: NumberFormatText,
                inputProps: {
                    ...formatProps,
                    ...restInput,
                    customInput: PhoneNumberInput,
                    autoComplete: 'tel-national',
                    onKeyDown: removeLeadingZeroIfNeeded,
                    disableOnBlur: false,
                    allowNegative: false,
                    onBlur: () => {
                        userInputContainedLeadingZero.current = false;
                        onBlur(value);
                    },
                    value: phoneNumberFormat.length
                        ? omitLeadingZero(value)
                        : value,
                    rawValue: value,
                },
            }}
        />
    );
}

PhoneNumber.propTypes = {
    input: PropTypes.object,
    endIcon: PropTypes.node,
    phoneNumberFormat: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default PhoneNumber;
