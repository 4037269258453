import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageHeader from '../PageHeader/PageHeader';
import PageFooter from '../PageFooter/PageFooter';
import { makeStyles } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import messages from '../../locales.json';
import { languageDirection } from 'common/helpers/languages';
import caseInsensitiveEqual from 'common/helpers/caseInsensitiveEqual';
import classNames from 'classnames';
import {
    CAMPAIGN_PAGE_DESIGNS,
    SUPPORTED_PAGE_DESIGNS,
} from 'common/constants';

const useStyles = makeStyles(
    ({ palette, spacing, typography }) => ({
        '@global': {
            body: {
                lineHeight: 'inherit',
                margin: 0,
            },
        },

        root: {
            backgroundColor: palette.background.default,
            fontFamily: typography.fontFamily,
        },

        content: {
            flex: '1 0 auto',
            paddingTop: 93,
            '@media (max-width: 600px)': {
                paddingTop: 50,
            },
        },
        smallHeader: {
            paddingTop: 70,
            '@media (max-width: 600px)': {
                paddingTop: 40,
            },
        },
        hideHeader: {
            paddingTop: 0,
        },
        addSidePadding: {
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            '@media (max-width: 600px)': {
                paddingLeft: spacing(2),
                paddingRight: spacing(2),
            },
        },
    }),
    { name: 'PageWrapper' },
);

function PageLayout({
    children,
    slugs,
    locales,
    pageLanguage,
    hideHeader,
    hideFooter,
    addSidePadding,
    hideLanguageIcon,
    pageDesign,
}) {
    const languages = Object.entries(locales).map(
        ([slug, { language, pageName, displayPageName }]) => ({
            value: language,
            slug,
            pageName,
            displayPageName,
        }),
    );
    const showHeader = !hideHeader || languages.length > 1;
    const onlyShowLanguageSelector = hideHeader && languages.length > 1;

    const [lang, setLang] = useState(pageLanguage || 'he');
    const classes = useStyles();
    const [currentPage] = Object.entries(slugs).find(([, slug]) => {
        const match = document.location.pathname.match(/^\/([^/]+)\/?/i);

        if (match) {
            return caseInsensitiveEqual(slug, match[1]);
        }

        return false;
    });

    const initLanguage = language => {
        document.documentElement.setAttribute('lang', language);
        document.documentElement.setAttribute(
            'dir',
            languageDirection(language),
        );
        setLang(language);
    };

    useEffect(() => {
        initLanguage(pageLanguage || locales[currentPage].language);
    }, [pageLanguage]);

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <PageWrapper className={classes.root}>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&display=swap"
                />
                {showHeader && (
                    <PageHeader
                        lang={lang}
                        slugs={slugs}
                        locales={locales}
                        currentPage={currentPage}
                        languages={languages}
                        onlyShowLanguageSelector={onlyShowLanguageSelector}
                        hideLanguageIcon={hideLanguageIcon}
                        pageDesign={pageDesign}
                    />
                )}
                <section
                    className={classNames(classes.content, {
                        [classes.smallHeader]: onlyShowLanguageSelector,
                        [classes.hideHeader]: !showHeader,
                        [classes.addSidePadding]: addSidePadding,
                    })}
                >
                    {children}
                </section>
                {!hideFooter && (
                    <PageFooter
                        lang={lang}
                        showWave={pageDesign === CAMPAIGN_PAGE_DESIGNS.CLASSIC}
                    />
                )}
            </PageWrapper>
        </IntlProvider>
    );
}

PageLayout.propTypes = {
    slugs: PropTypes.object.isRequired,
    locales: PropTypes.object.isRequired,
    pageLanguage: PropTypes.string,
    children: PropTypes.node,
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    addSidePadding: PropTypes.bool,
    hideLanguageIcon: PropTypes.bool,
    pageDesign: PropTypes.oneOf(SUPPORTED_PAGE_DESIGNS),
};

export default PageLayout;
