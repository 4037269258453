import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import FlagIcon from 'client/components/Icons/Flag';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { Box } from '@material-ui/core';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';
import Avatar from '../Avatar/Avatar';
import Progress from '../Progress/Progress';
import Text from '../Text/Text';
import { currencySign } from 'common/helpers';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        background: palette.background.paper,
        border: `1.5px solid ${palette.grey[200]}`,
        position: 'relative',

        '&::after': {
            content: '""',
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: '-1px 2px 8px rgb(0, 0, 0, 0.1)',
            opacity: 0,
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '1.1s',
        },
        '&:hover::after': {
            transitionDuration: ' 0.4s',
            opacity: 1,
        },
    },
    tiled: {
        background: palette.grey[100],
        cursor: 'pointer',
    },
    bonusRound: {
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderImage: `linear-gradient(45deg, ${palette.secondary.light}, ${palette.secondary.light}) 1`,
        '&::after': {
            boxShadow: `-1px 2px 8px ${changeColorAlpha(
                palette.secondary.main,
                0.2,
            )}`,
        },
        '& .amountCustom': {
            color: `${palette.secondary.main} !important`,
        },
    },
    content: {
        padding: '25px 40px',

        '@media (max-width: 600px)': {
            padding: '25px 25px 10px !important',
        },
    },
    tiledContent: {
        padding: '11px 30px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(3.5),
        marginTop: spacing(1),
        overflow: 'hidden',
    },
    avatar: {
        marginInlineEnd: '13px',
        flexShrink: 0,
        boxShadow: '0px 0px 15px -10px #000',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    name: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '1.25rem',
        lineHeight: 1.1,
        fontWeight: 600,

        '@media (max-width: 600px)': {
            fontSize: '1.063rem',
        },
    },
    description: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '0.875rem',
        lineHeight: 1,
        paddingTop: 4,
        marginBottom: 4,
        wordBreak: 'break-word',

        '@media (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    progressBar: {
        marginTop: spacing(3),
    },
    progressBarLabel: {
        bottom: spacing(2),
        fontSize: typography.pxToRem(12),
    },
    info: {
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    counts: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '0.125em',
        marginInlineStart: 'auto',
    },
    count: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        color: palette.primary.main,
        fontFamily: typography.fontFamily,
        fontSize: '0.938rem',
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    amounts: {
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        fontSize: '0.75rem',
        color: palette.text.primary,
        paddingTop: '0.125em',
        '& strong': {
            marginBottom: -2,
            marginInlineEnd: '6px',
            color: palette.primary.main,
            fontSize: '1.063rem',
            fontWeight: 'bold',
        },

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',

            '& strong': {
                fontSize: '0.938rem',
            },
        },
    },
    amountCustom: {
        marginBottom: 0,
        color: palette.primary.main,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        fontFamily: typography.fontFamily,
    },
    goalAmount: {
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        marginInlineStart: '3px',
        '@media (max-width: 600px)': {
            fontSize: 'inherit',
            marginTop: -2,
        },
    },
    headInner: {},
    banner: {
        position: 'relative',
        backgroundColor: palette.grey[100],
        width: '100%',

        '& img': {
            width: '100%',
            maxHeight: 144,
            objectFit: 'cover',
            '@media (max-width: 1023px)': {
                maxHeight: 110,
            },
            '@media (max-width: 820px)': {
                maxHeight: 92,
            },
        },
    },
    blurredTileBanner: {
        '& img': {
            objectFit: 'contain',
        },
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        filter: 'blur(9px)',
        backgroundSize: '200%',
        backgroundPosition: '-50% -50%',
    },
    itemProgress: {
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '0.938rem',
        margin: '0 0.4em',
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
            marginBottom: -1,
        },
    },
    actions: {
        textAlign: 'center',
    },
    actionBtn: {
        margin: '5px 7px',
        padding: '5px 15px',
        fontSize: '1.2rem',
        '@media (max-width: 600px)': {
            fontSize: '0.938rem',
            padding: '2px 13px',
        },
    },
    donateBtn: {
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        '&:hover': {
            textShadow: `0 0 1px ${palette.common.white}`,
        },
    },
    viewBtn: {
        '&::after': {
            display: 'inline-block',
            content: '"\\00A0➔"',

            'html[dir=rtl] &': {
                content: '"➔\\00A0"',
                transformOrigin: 'center center',
                transform: 'rotate(180deg)',
            },
        },
    },
    bonusGoalBanner: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
        marginInlineEnd: 40,
        alignItems: 'center',
        padding: '0 0.325em',
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        borderRadius: '0 0 5px 5px',
        color: palette.secondary.contrastText,
        fontSize: '1.125rem',
        fontWeight: 500,
        border: isLightColor(palette.secondary.main, 0.7)
            ? `1px solid ${palette.secondary.contrastText}`
            : 'none',
        '[dir=rtl] &': {
            right: 'auto',
            left: 0,
        },
        '& svg': {
            stroke: palette.secondary.contrastText,
        },
        '@media (max-width: 600px)': {
            fontSize: '1rem',
            marginInlineEnd: 25,
        },
    },
}));

function ClassicTeamCard({
    allowDonations,
    amount,
    avatarSrc,
    baseGoalPercent,
    blurredTile = false,
    bonusGoalAmount = 0,
    campaignSlug,
    className,
    currency = 'USD',
    description,
    donorsCount,
    goalAmount = 0,
    hasEnteredBonusGoal = false,
    isCustomTileEnabled = false,
    name,
    onDonate,
    onView,
    queryString,
    teamsCount,
    totalGoalAmount,
    tile = null,
}) {
    const classes = useStyles();
    const { palette } = useTheme();

    return (
        <div
            className={classNames(
                classes.root,
                {
                    [classes.bonusRound]: hasEnteredBonusGoal,
                    [classes.tiled]: isCustomTileEnabled,
                },
                className,
            )}
            onClick={onView}
        >
            {isCustomTileEnabled && Boolean(tile) && (
                <div
                    className={classNames(classes.banner, {
                        [classes.blurredTileBanner]: blurredTile,
                    })}
                >
                    {blurredTile && (
                        <div
                            className={classes.bg}
                            style={{ backgroundImage: `url(${tile})` }}
                        />
                    )}
                    <img src={tile} alt={name} />
                </div>
            )}
            <div className={classes.content}>
                <div className={classes.header}>
                    <Avatar size={84} className={classes.avatar}>
                        <img
                            src={
                                avatarSrc
                                    ? avatarSrc
                                    : 'https://via.placeholder.com/31x31'
                            }
                            alt=""
                        />
                    </Avatar>
                    <div className={classes.headInner}>
                        <div className={classes.name}>{name}</div>
                        {description && (
                            <div className={classes.description}>
                                {description}
                            </div>
                        )}
                    </div>
                </div>
                {goalAmount > 0 && !isNaN(amount) && (
                    <>
                        {hasEnteredBonusGoal ? (
                            <Progress
                                className={classes.progressBar}
                                labelClassName={classes.progressBarLabel}
                                percent={(goalAmount * 100) / totalGoalAmount}
                                label={`${baseGoalPercent}%`}
                                bonusPercent={Math.round(
                                    (amount / totalGoalAmount) * 100,
                                )}
                                size={12}
                            />
                        ) : (
                            <Progress
                                className={classes.progressBar}
                                labelClassName={classes.progressBarLabel}
                                percent={baseGoalPercent}
                                size={12}
                            />
                        )}
                    </>
                )}
                <div className={classes.info}>
                    <div className={classes.amounts}>
                        {!isNaN(amount) ? (
                            <>
                                <Text className={classes.amountCustom}>
                                    {currencySign(currency)}
                                    {amount.toLocaleString('en-EN')}
                                </Text>
                                <div className={classes.itemProgress}>
                                    {goalAmount > 0 ? (
                                        <>
                                            <FormattedMessage id="Amount.outOf" />{' '}
                                            <span
                                                className={classes.goalAmount}
                                            >
                                                {currencySign(currency)}
                                                {hasEnteredBonusGoal ? (
                                                    <>
                                                        {(
                                                            goalAmount +
                                                            bonusGoalAmount
                                                        ).toLocaleString(
                                                            'en-EN',
                                                        )}{' '}
                                                        <FormattedMessage
                                                            id="DonationWidget.bonusTitle"
                                                            defaultMessage="Bonus Goal"
                                                        />
                                                    </>
                                                ) : (
                                                    goalAmount.toLocaleString(
                                                        'en-EN',
                                                    )
                                                )}
                                            </span>
                                        </>
                                    ) : (
                                        <span>
                                            <FormattedMessage
                                                id="Amount.raised"
                                                defaultMessage="Raised"
                                            />
                                        </span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <span>Analytics in progress...</span>
                        )}
                    </div>
                    <div className={classes.counts}>
                        {!isNaN(donorsCount) && amount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Donor"
                                    values={{ counter: donorsCount }}
                                />
                            </div>
                        )}
                        {!isCustomTileEnabled && teamsCount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Team"
                                    values={{ counter: teamsCount }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isCustomTileEnabled && (
                    <>
                        <div className={classes.actions}>
                            <Button
                                outline
                                color={palette.primary.main}
                                hoverColor={palette.primary.main}
                                className={classNames(
                                    classes.actionBtn,
                                    classes.viewBtn,
                                )}
                                onClick={onView}
                            >
                                <FormattedMessage id="Button.view" />
                            </Button>
                            {allowDonations && (
                                <Link
                                    to={`/${campaignSlug}/donate/${queryString}`}
                                >
                                    <Button
                                        onClick={onDonate}
                                        className={classNames(
                                            classes.actionBtn,
                                            classes.donateBtn,
                                        )}
                                    >
                                        <FormattedMessage id="Button.donateNow" />
                                    </Button>
                                </Link>
                            )}
                        </div>
                        {hasEnteredBonusGoal && (
                            <div className={classes.bonusGoalBanner}>
                                <FlagIcon
                                    color={palette.secondary.contrastText}
                                />
                                <Box m={0.5} />
                                <FormattedMessage
                                    id="common.bonusGoal"
                                    defaultMessage="Bonus Goal!"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

ClassicTeamCard.propTypes = {
    allowDonations: PropTypes.bool,
    amount: PropTypes.number,
    avatarSrc: PropTypes.string,
    baseGoalPercent: PropTypes.number,
    blurredTile: PropTypes.bool,
    bonusGoalAmount: PropTypes.number,
    campaignSlug: PropTypes.string,
    className: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    donorsCount: PropTypes.number,
    goalAmount: PropTypes.number,
    hasEnteredBonusGoal: PropTypes.bool,
    isCustomTileEnabled: PropTypes.bool,
    name: PropTypes.string,
    onDonate: PropTypes.func,
    onView: PropTypes.func,
    queryString: PropTypes.string,
    teamsCount: PropTypes.number,
    tile: PropTypes.string,
    totalGoalAmount: PropTypes.number,
};

export default ClassicTeamCard;
