import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'client/componentLibrary/Button';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        padding: `${spacing(1.5)}px ${spacing(5)}px`,
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: 1.2,
        color: palette.primary.contrastText,

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(13),
            padding: `${spacing(1)}px ${spacing(4)}px`,
        },
    },
}));

function MoreButton(props) {
    const { children, className, color = 'primary', ...rest } = props;
    const classes = useStyles();

    return (
        <Button
            color={color}
            className={classNames(classes.root, className)}
            {...rest}
        >
            {children}
        </Button>
    );
}

MoreButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
};

export default MoreButton;
