import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PerkGifts = ({
    classes,
    disabled,
    isStockEmpty,
    shouldShowGiftsSection,
    stockLabel,
}) => {
    if (!shouldShowGiftsSection) {
        return <></>;
    }

    return (
        <div
            className={classNames({
                [classes.gifts]: shouldShowGiftsSection,
                [classes.disabled]: disabled || isStockEmpty,
            })}
        >
            <div className={classes.giftsData}>
                <div>{stockLabel}</div>
            </div>
        </div>
    );
};

PerkGifts.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    isStockEmpty: PropTypes.bool,
    shouldShowGiftsSection: PropTypes.bool,
    stockLabel: PropTypes.string,
};

export default PerkGifts;
