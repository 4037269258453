import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from 'client/components/CampaignPage/components/Button/Button';
import { GATEWAYS, PAYMENT_GATEWAYS, PAYMENT_METHODS } from 'common/constants';
import { useIntl } from 'react-intl';
import { changeColorAlpha } from '../../../../helpers/colorUtils';
import PaypalButton from './PaypalButton';
import StripeCashAppButton from './StripeCashAppButton';
import ChariotButton from './ChariotButton';
import CardknoxGooglePayButton from './CardknoxGooglePayButton';
import StripeWalletInfo from './StripeWalletInfo';
import {
    PAYMENT_PROCESS_PENDING,
    PAYMENT_PROCESS_REDIRECT,
} from './checkoutSlice';
import AuthorizeButton from './AuthorizeButton';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        padding: spacing(2),
        borderRadius: 5,
        display: 'block',
        width: '100%',
        fontSize: '1.438rem',
        fontWeight: 500,
        '&:disabled': {
            backgroundColor: changeColorAlpha(palette.secondary.main, 0.5),
        },
        '@media (max-width: 600px)': {
            width: '100%',
        },
    },
}));

function DonateButton({
    status,
    paymentGateway,
    paymentMethod,
    paymentMode,
    awayProcessing,
    isTokenPending,
    paypalClientId,
    chariotCid,
    currency,
    onDonateFailed,
    onDonateSuccess,
    makeDonation,
    onReady,
    isTestMode,
    payment,
}) {
    const { formatMessage } = useIntl();
    const { palette } = useTheme();
    const classes = useStyles();

    const gatewayHasRedirect = (gateway, method) => {
        if (method === 'aac' || method === 'ideal') {
            return true;
        }
        const redirectScenarioValue = 'awayPre';
        const gatewaySettings = GATEWAYS[gateway];
        if (typeof gatewaySettings.flow === 'string') {
            return gatewaySettings.flow === redirectScenarioValue;
        } else if (gatewaySettings?.flow && gatewaySettings.flow[method]) {
            return gatewaySettings.flow[method] === redirectScenarioValue;
        }
        return false;
    };

    useEffect(() => {
        if (paymentMethod !== PAYMENT_METHODS.PAYPAL) {
            onReady();
        }
    }, [paymentMethod]);

    const getButtonContent = () => {
        if (status === PAYMENT_PROCESS_REDIRECT) {
            return formatMessage({ id: 'Checkout.redirecting' });
        }
        if (status === PAYMENT_PROCESS_PENDING || awayProcessing) {
            return (
                <>
                    {formatMessage({ id: 'Checkout.pending' })}{' '}
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </>
            );
        }
        if (isTokenPending) {
            return formatMessage({
                id: 'Checkout.pleaseWait',
                defaultMessage: 'Please wait',
            });
        }
        const hasRedirect = gatewayHasRedirect(paymentGateway, paymentMethod);
        const buttonMessageId = hasRedirect
            ? 'Button.continueDonation'
            : 'DonateButton.text';
        return formatMessage({ id: buttonMessageId });
    };

    const renderDefaultButton = (onClick = null) => (
        <Button
            color={palette.secondary.main}
            hoverColor={palette.secondary.main}
            disabled={
                status === PAYMENT_PROCESS_PENDING ||
                status === PAYMENT_PROCESS_REDIRECT ||
                isTokenPending
            }
            type={onClick ? 'button' : 'submit'}
            onClick={onClick}
            className={classes.root}
        >
            {getButtonContent()}
        </Button>
    );

    const renderPaymentButton = () => {
        switch (paymentMethod) {
            case 'paypal':
                return (
                    <PaypalButton
                        key={paymentMode}
                        clientId={paypalClientId}
                        currency={currency}
                        onDonateSuccess={onDonateSuccess}
                        makeDonation={makeDonation}
                        paymentMode={paymentMode}
                        onReady={onReady}
                    />
                );
            case 'cashapp':
                return (
                    <StripeCashAppButton
                        makeDonation={makeDonation}
                        renderButton={renderDefaultButton}
                        onDonateFailed={onDonateFailed}
                        onDonateSuccess={onDonateSuccess}
                    />
                );
            case 'chariot':
                return (
                    <ChariotButton
                        cid={chariotCid}
                        makeDonation={makeDonation}
                        onReady={onReady}
                        onDonateSuccess={onDonateSuccess}
                        onDonateFailed={onDonateFailed}
                    />
                );
            case 'applepay':
                if (paymentGateway === PAYMENT_GATEWAYS.STRIPE) {
                    return (
                        <StripeWalletInfo
                            makeDonation={makeDonation}
                            onDonateSuccess={onDonateSuccess}
                            paymentMode={paymentMode}
                        />
                    );
                }

                return renderDefaultButton();
            case 'googlepay':
                if (paymentGateway === PAYMENT_GATEWAYS.STRIPE) {
                    return (
                        <StripeWalletInfo
                            makeDonation={makeDonation}
                            onDonateSuccess={onDonateSuccess}
                            paymentMode={paymentMode}
                        />
                    );
                }
                if (paymentGateway === PAYMENT_GATEWAYS.CARDKNOX) {
                    return (
                        <CardknoxGooglePayButton
                            makeDonation={makeDonation}
                            onDonateSuccess={onDonateSuccess}
                            paymentMode={paymentMode}
                            isTestMode={isTestMode}
                        />
                    );
                }

                return renderDefaultButton();

            case 'card':
                if (paymentGateway === PAYMENT_GATEWAYS.AUTHORIZE) {
                    return (
                        <AuthorizeButton
                            apiLoginId={payment.apiLoginId}
                            publicClientKey={payment.publicClientKey}
                            isTestMode={isTestMode}
                            onDonateSuccess={onDonateSuccess}
                            onDonateFailed={onDonateFailed}
                            makeDonation={makeDonation}
                        />
                    );
                }
                return renderDefaultButton();

            default:
                return renderDefaultButton();
        }
    };

    return renderPaymentButton();
}

DonateButton.propTypes = {
    status: PropTypes.string.isRequired,
    paymentGateway: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    paymentMode: PropTypes.string.isRequired,
    awayProcessing: PropTypes.bool.isRequired,
    isTokenPending: PropTypes.bool.isRequired,
    paypalClientId: PropTypes.string.isRequired,
    chariotCid: PropTypes.string,
    currency: PropTypes.string.isRequired,
    onDonateFailed: PropTypes.func,
    onDonateSuccess: PropTypes.func.isRequired,
    makeDonation: PropTypes.func.isRequired,
    onReady: PropTypes.func,
    isTestMode: PropTypes.bool,
    payment: PropTypes.object,
};

export default DonateButton;
