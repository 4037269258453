import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    selectBonusGoal,
    selectCurrency,
    selectEnableTimer,
    selectEndCampaignTimestamp,
    selectGoal,
    selectNextBonusRoundGoal,
    selectRatedAmount,
    selectStartCampaignTimestamp,
    selectProgressGradation,
    selectHideProgressBar,
    selectHideGoalAmount,
    selectHideRaisedAmount,
    selectHideTime,
    selectHideClock,
    selectTotalGoalAmount,
} from '../campaign/campaignSlice';
import PageSection from 'client/components/CampaignPage/components/PageSection/PageSection';
import CountdownWidget from 'client/components/CampaignPage/components/CountdownWidget/CountdownWidget';
import DonationWidget from 'client/components/CampaignPage/components/DonationWidget/DonationWidget';
import { currencySign } from 'common/helpers';
import classNames from 'classnames';

const useStyles = makeStyles(
    () => ({
        root: {
            display: 'grid',
            gridTemplateColumns: ({
                enableTimer,
                hideClock,
                hideTime,
                hideStats,
            }) =>
                enableTimer && !(hideClock && hideTime)
                    ? (hideClock || hideTime) && !hideStats
                        ? '1fr 2fr'
                        : hideStats
                        ? '1fr'
                        : '1fr 1fr'
                    : '1fr',
            gridGap: '20px 20px',
            gap: '20px 20px',
            marginTop: 40,
            marginBottom: 15,

            '@media (max-width: 1023px)': {
                gridTemplateColumns: '1fr !important',
                gridGap: '15px',
                gap: '15px',
                marginTop: 15,
            },
        },

        countdown: {
            'html[dir=rtl] &': {
                order: 0,
            },
        },
    }),
    { name: 'DonationWidgets' },
);

function StatisticWidgets({
    handleTimerStop,
    variant = 'standard',
    showClockGraphic = true,
    widgetClassName,
}) {
    const enableTimer = useSelector(selectEnableTimer);
    const startCampaignTimestamp = useSelector(selectStartCampaignTimestamp);
    const endCampaignTimestamp = useSelector(selectEndCampaignTimestamp);
    const goal = useSelector(selectGoal);
    const bonusGoal = useSelector(selectBonusGoal);
    const nextBonusRoundGoal = useSelector(selectNextBonusRoundGoal);
    const totalGoalAmount = useSelector(selectTotalGoalAmount);
    const ratedAmount = useSelector(selectRatedAmount);
    const gradation = useSelector(selectProgressGradation);
    const currency = useSelector(selectCurrency);
    const hideTime = useSelector(selectHideTime);
    const hideClock = useSelector(selectHideClock) || !showClockGraphic;
    const hideProgressBar = useSelector(selectHideProgressBar);
    const hideGoalAmount = useSelector(selectHideGoalAmount);
    const hideRaisedAmount = useSelector(selectHideRaisedAmount);
    const hideStats = hideProgressBar && hideGoalAmount && hideRaisedAmount;
    const classes = useStyles({
        enableTimer,
        hideClock,
        hideTime,
        hideStats,
    });

    return (
        <PageSection className={classNames(classes.root, 'stats-widget')}>
            {enableTimer && (
                <CountdownWidget
                    startAt={startCampaignTimestamp}
                    date={endCampaignTimestamp}
                    className={widgetClassName}
                    onStop={handleTimerStop}
                    hideTime={hideTime}
                    hideClock={hideClock}
                    variant={variant}
                />
            )}
            <DonationWidget
                amount={Math.round(ratedAmount)}
                goal={goal}
                bonusGoal={bonusGoal}
                className={widgetClassName}
                nextBonusRoundGoal={nextBonusRoundGoal}
                totalGoalAmount={totalGoalAmount}
                currency={currency}
                currencySign={currencySign(currency)}
                totalPercent={gradation.totalPercent}
                goalPercent={gradation.goalPercent}
                goal1Percent={gradation.goal1Percent}
                goal2Percent={gradation.goal2Percent}
                hideProgressBar={hideProgressBar}
                hideGoalAmount={hideGoalAmount}
                hideRaisedAmount={hideRaisedAmount}
            />
        </PageSection>
    );
}

StatisticWidgets.propTypes = {
    handleTimerStop: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'rounded']),
    showClockGraphic: PropTypes.bool,
    widgetClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default StatisticWidgets;
