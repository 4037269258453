import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../Text/Text';
import Checkbox from 'client/components/CampaignPage/components/Checkbox/Checkbox';
import classNames from 'classnames';
import { changeColorAlpha } from '../../../../helpers/colorUtils';
import TipSelector from './TipSelector';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        margin: `${spacing(3)}px 0`,
        fontFamily: typography.fontFamily,
        background: `${changeColorAlpha(palette.primary.light, 0.1)}`,
        padding: spacing(3),
        borderRadius: 5,
        '@media (max-width: 600px)': {
            padding: spacing(2),
        },
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(3),
        '@media (max-width: 600px)': {
            alignItems: 'start',
        },
    },
    topContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: spacing(2),
        flexWrap: 'wrap',
        flex: 1,
    },
    checkbox: {
        cursor: 'pointer',
        padding: 0,
        paddingInlineEnd: spacing(3),
        '& svg': {
            top: 4,
            left: 1,
            width: 15,
            height: 15,
            background: palette.primary.main,

            'html[dir=rtl] &': {
                left: 'auto',
                right: 1,
            },
        },
        '& svg path': {
            stroke: `${palette.background.default} !important`,
        },
    },
    label: {
        fontSize: typography.pxToRem(16),
        color: palette.primary.main,
        display: 'flex',
        fontWeight: 'bold',
    },
    bottom: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: typography.pxToRem(15),
        lineHeight: '22px',
        marginBottom: spacing(3),

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(13),
            lineHeight: '19px',
        },
    },
    info: {
        color: palette.text.primary,
        '& span': {
            color: palette.primary.main,
        },
    },
    amount: {
        minWidth: 165,
        flexShrink: 0,
        fontWeight: 500,
        color: palette.primary.main,
        textAlign: 'right',
    },
    tipInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '& p': {
            color: palette.text.primary,
            fontSize: '1rem',
            lineHeight: '19px',
        },
        '& span': {
            color: palette.primary.main,
            fontSize: typography.pxToRem(19),
            flexShrink: 0,
            fontFamily: typography.fontFamily,
            fontWeight: 500,
            marginLeft: 'auto',
        },
    },
}));

function TipWidget(props) {
    const {
        amount = 0,
        className,
        currencySign = '$',
        defaultTipPercent = 0,
        onTipPercentChange,
        percents = [],
        tipPercent = 0,
        tippingCheckoutText = '',
        tippingThankYouText = '',
    } = props;
    const classes = useStyles();
    const [tipSelected, setTipSelected] = useState(false);
    const options = useMemo(
        () =>
            [...percents]
                .sort((p, c) => p - c)
                .map(percent => {
                    const percentTip = (amount * percent) / 100;
                    return {
                        name: `${percent}% (${currencySign}${percentTip.toLocaleString(
                            'en-EN',
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            },
                        )})`,
                        value: String(percent),
                    };
                }),
        [amount, percents, currencySign],
    );

    const handleChange = value => {
        if (typeof onTipPercentChange === 'function') {
            onTipPercentChange(value);
        }
    };

    const handleCheckboxChecked = evt => {
        if (evt.target.checked) {
            let newPercent = defaultTipPercent;
            if (defaultTipPercent) {
                newPercent = defaultTipPercent;
            } else if (percents.length > 0 && percents[0]) {
                newPercent = percents[0];
            } else if (percents.length > 1 && percents[1]) {
                newPercent = percents[1];
            } else {
                newPercent = 1; //collect 1%
            }
            onTipPercentChange(newPercent);
            setTipSelected(true);
        } else {
            onTipPercentChange(0);
            setTipSelected(false);
        }
    };

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.top}>
                <Checkbox
                    className={classes.checkbox}
                    checked={!!tipPercent}
                    type="checkbox"
                    onChange={handleCheckboxChecked}
                />
                <div className={classes.topContent}>
                    <Text className={classes.label} html>
                        {tippingThankYouText}
                    </Text>
                    {!isNaN(tipPercent) && ( //do not remove this check isNaN. It makes sure correct checkbox is checked while loading dropdown for first time.
                        <TipSelector
                            options={options}
                            tipSelected={tipSelected}
                            onChange={handleChange}
                            value={String(tipPercent)}
                            amount={amount}
                            currencySign={currencySign}
                        />
                    )}
                </div>
            </div>

            {tippingCheckoutText && (
                <div className={classes.tipInfo}>
                    <Text html className={classes.info}>
                        {tippingCheckoutText}
                    </Text>
                </div>
            )}
        </div>
    );
}

TipWidget.propTypes = {
    amount: PropTypes.number,
    className: PropTypes.string,
    currencySign: PropTypes.string,
    defaultTipPercent: PropTypes.number,
    fee: PropTypes.number,
    layersCount: PropTypes.number,
    onTipPercentChange: PropTypes.func,
    percents: PropTypes.array,
    tipPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tippingCheckoutText: PropTypes.string,
    tippingThankYouText: PropTypes.string,
    tipSelected: PropTypes.bool,
};

export default TipWidget;
