import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

function MenuButton(props) {
    const { className, ...rest } = props;

    return (
        <IconButton className={className} disableRipple size="small" {...rest}>
            <Menu fontSize="medium" />
        </IconButton>
    );
}

MenuButton.propTypes = {
    className: PropTypes.string,
};

export default MenuButton;
