const fp = require('lodash/fp');
const { CAMPAIGN_CUSTOM_LOGIC_TYPES } = require('../constants/campaign');
const { isNumber } = require('../helpers/number');

const PAGE_SETTINGS_TRANSLATION_PROPS = ['label', 'description'];

function translateDonationTierPageSettings(pageSettings, from, to) {
    for (const prop of PAGE_SETTINGS_TRANSLATION_PROPS) {
        if (from?.[prop] && to?.[prop] && pageSettings[prop] === from[prop]) {
            pageSettings = fp.setWith(Object, prop, to[prop], pageSettings);
        }
    }

    return pageSettings;
}

function selectDonationTier(donationTiersSettings, currency, amount) {
    const currencyRate = donationTiersSettings.currencyRates.find(
        r => r.currency === currency,
    );

    // rate to convert currency to primary currency
    const rate = currencyRate?.rate || 1;

    // convert amount in currency to primary currency
    // since the donation tier amounts are in primary currency
    const convertedAmount = Number(amount) * rate;

    const donationTier =
        donationTiersSettings.donationTiers.findLast(
            tier => convertedAmount >= tier.minValue,
        ) || null;

    return donationTier;
}

function selectPurimDonationTier(
    donationTiersSettings,
    currency,
    amount,
    data,
) {
    if (!donationTiersSettings?.donationTiers?.length || !currency || !amount) {
        return null;
    }

    if (!isNumber(data?.numDonors)) {
        return null;
    }

    const evyonimCount = Number(data.numDonors) * 2;
    const amountPerEvyon = Number(amount) / evyonimCount;

    const donationTier = selectDonationTier(
        donationTiersSettings,
        currency,
        amountPerEvyon,
    );
    if (!donationTier) {
        return null;
    }

    return {
        ...donationTier,
        showTooltip: true,
        allDonationTiers: donationTiersSettings.donationTiers,
    };
}

function getSelectedDonationTier(
    donationTiersSettings,
    currency,
    amount,
    customLogicData,
) {
    if (!donationTiersSettings?.donationTiers?.length || !currency || !amount) {
        return null;
    }

    switch (customLogicData?.customLogicType) {
        case CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM:
            return selectPurimDonationTier(
                donationTiersSettings,
                currency,
                amount,
                customLogicData,
            );

        default:
            return selectDonationTier(donationTiersSettings, currency, amount);
    }
}

module.exports = {
    translateDonationTierPageSettings,
    selectDonationTier,
    selectPurimDonationTier,
    getSelectedDonationTier,
};
