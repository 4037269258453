import React from 'react';
import PropTypes from 'prop-types';
import DonorsTabContent from '../donorsList/DonorsTabContent';
import TeamsTabContent from '../layersList/TeamsTabContent';
import AboutTabContent from './AboutTabContent';

function TabContent({ multiplier, tab, donorListProps }) {
    if (tab.tabContentId === 'donors') {
        return <DonorsTabContent multiplier={multiplier} {...donorListProps} />;
    } else if (tab.tabContentId === 'about') {
        return <AboutTabContent />;
    } else if (tab.layer) {
        return <TeamsTabContent />;
    }
    return null;
}

TabContent.propTypes = {
    donorListProps: PropTypes.object,
    multiplier: PropTypes.number,
    tab: PropTypes.object,
};

export default TabContent;
