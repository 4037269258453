import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CountdownWidgetItem from '../CountdownWidgetItem/CountdownWidgetItem';
import CountdownWidgetGraph from '../CountdownWidgetGraph/CountdownWidgetGraph';
import classNames from 'classnames';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '@media (max-width: 600px)': {
            padding: 15,
        },
    },
    rounded: {
        height: '100%',
        padding: 0,
    },
    img: {
        display: 'block',
        maxWidth: 136,
        width: '100%',
        margin: '0 25px 0 15px',

        'html[dir=rtl] &': {
            order: 1,
        },
        '@media (max-width: 600px)': {
            maxWidth: 80,
            margin: '0 15px 0 0',
        },
    },
    counters: {
        display: 'flex',
        margin: 0,
        gap: spacing(2),
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',

        'html[dir=rtl] &': {
            direction: 'ltr',
        },
    },
    marginAuto: {
        margin: 'auto',
    },
}));

const DAY = 0;
const HOUR = 1;
const MINUTE = 2;
const SECOND = 3;

function CountdownWidget(props) {
    const {
        className,
        onStop,
        onTick,
        date,
        startAt,
        children = null,
        visibleParts = 3,
        hideTime = false,
        hideClock = false,
        variant = 'standard',
        fontWeight = 600,
    } = props;
    const classes = useStyles();
    const [range, setRange] = useState([
        { name: 'Day', value: 0 },
        { name: 'Hour', value: 0 },
        { name: 'Minute', value: 0 },
        { name: 'Second', value: 0 },
    ]);
    const [timerPercent, setTimerPercent] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(0);
    const interval = useRef();

    const updateRange = useCallback((timeRangeIndex, value) => {
        const newRange = [...range];
        newRange[timeRangeIndex].value = value;
        setRange(newRange);
    }, []);

    const updateTimerPercent = () => {
        const fullDiff = date - startAt;
        const currentDiff = date - Math.max(Date.now(), startAt);

        setTimerPercent(Math.floor((currentDiff / fullDiff) * 100));
    };

    const updateTimerSeconds = seconds => {
        setTimerSeconds(Math.floor(100 - (100 / 60) * seconds));
    };

    const createVisibleRange = useCallback(
        range => {
            let visibleRange = range.slice(-visibleParts);

            for (let slot = 0; slot < range.length; slot++) {
                if (
                    range.length - slot === visibleParts ||
                    range[slot].value > 0
                ) {
                    visibleRange = range.slice(slot, slot + visibleParts);
                    break;
                }
            }

            return visibleRange;
        },
        [visibleParts],
    );

    const countDown = useCallback(() => {
        const diffSeconds = (date - Date.now()) / 1000;

        if (diffSeconds > 0) {
            let leftSeconds;
            let leftMinutes;
            let leftHours;
            let seconds;
            let minutes;
            let hours;
            let days;

            seconds = Math.floor(diffSeconds % 60);
            leftSeconds = diffSeconds - seconds;
            leftMinutes = leftSeconds / 60;
            minutes = Math.floor(leftMinutes % 60);
            leftHours = (leftMinutes - minutes) / 60;
            hours = Math.floor(leftHours % 24);
            days = Math.floor((leftHours - hours) / 24);

            if (typeof onTick === 'function') {
                onTick({ days, hours, minutes, seconds });
            }

            updateRange(SECOND, seconds);
            updateRange(MINUTE, minutes);
            updateRange(HOUR, hours);
            updateRange(DAY, days);
            updateTimerPercent();
            updateTimerSeconds(seconds);
        } else {
            if (typeof onStop === 'function') {
                clearInterval(interval.current);
                onStop();
            }
        }
    }, [date]);

    useEffect(() => {
        countDown();
        interval.current = setInterval(countDown, 1000);
        return () => {
            clearInterval(interval.current);
        };
    }, [date]);

    const renderCountdown = useCallback(
        timePart => (
            <CountdownWidgetItem
                key={timePart.name}
                value={String(timePart.value).padStart(timePart.pad || 2, '0')}
                label={timePart.name}
                variant={variant}
                fontWeight={fontWeight}
            />
        ),
        [],
    );

    if (hideClock && hideTime) {
        return null;
    }

    if (children) {
        return children({
            range: createVisibleRange(range),
            timerPercent,
        });
    }

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.rounded]: variant === 'rounded' },
                className,
                'stats-widget-countdown',
            )}
        >
            {!hideClock && (
                <CountdownWidgetGraph
                    className={classNames(classes.img, {
                        [classes.marginAuto]: hideTime,
                    })}
                    percent={timerPercent}
                    seconds={timerSeconds}
                />
            )}
            {!hideTime && (
                <div
                    className={classNames(classes.counters, {
                        [classes.marginAuto]: hideTime,
                    })}
                >
                    {createVisibleRange(range).map(renderCountdown)}
                </div>
            )}
        </div>
    );
}

CountdownWidget.propTypes = {
    date: PropTypes.number.isRequired,
    startAt: PropTypes.number.isRequired,
    className: PropTypes.string,
    onStop: PropTypes.func,
    onTick: PropTypes.func,
    children: PropTypes.func,
    visibleParts: PropTypes.number,
    hideClock: PropTypes.bool,
    hideTime: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'rounded']),
    fontWeight: PropTypes.number,
};

export default CountdownWidget;
