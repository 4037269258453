import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PageSection from '../../PageSection/PageSection';
import Perk from './Perk';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
    selectPageName,
    selectSelectedCampaign,
} from '../../../features/campaign/campaignSlice';
import { createPageOptionsSelector } from 'client/components/GivingOptionsEditor/givingSelectors';
import { getPerkLogo } from 'client/helpers/getPerkLogo';

const useStyles = makeStyles(({ spacing }) => ({
    perkSection: {
        marginTop: spacing(4),
        padding: 0,
    },
    perks: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: spacing(2),
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    },
}));

function Perks({ givings, isPublished, enableDonations }) {
    const classes = useStyles();
    const campaign = useSelector(selectSelectedCampaign);
    const pageName = useSelector(selectPageName);
    const selectPageOptions = useCallback(
        createPageOptionsSelector(pageName, campaign.defaultPage),
        [pageName, campaign?.defaultPage],
    );
    const perks = givings.filter(perk => {
        const currency = selectPageOptions(perk, 'currency');
        const isActive = selectPageOptions(perk, 'isActive');
        if (
            campaign.payments[pageName].currencies.includes(currency) &&
            !perk.isHidden &&
            isActive
        ) {
            return perk;
        }
    });

    return (
        <PageSection className={classNames(classes.perkSection, 'perks')}>
            <div className={classes.perks}>
                {perks.map(perk => (
                    <Perk
                        {...perk}
                        logo={getPerkLogo(perk, campaign, pageName)}
                        key={perk.id}
                        disabled={!enableDonations || !isPublished}
                        className={classNames(
                            perks.length <= 3 || perks.length % 3 === 0
                                ? 'perk-3'
                                : '',
                            perks.length % 4 === 0 ? 'perk-4' : '',
                            perks.length % 5 === 0 ? 'perk-5' : '',
                        )}
                    />
                ))}
            </div>
        </PageSection>
    );
}

Perks.propTypes = {
    givings: PropTypes.array,
    isPublished: PropTypes.bool,
    enableDonations: PropTypes.bool,
};

export default Perks;
