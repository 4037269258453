import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, MenuItem, ClickAwayListener, Paper } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { LANGUAGES_INFO } from 'common/constants';
import GlobeIcon from './GlobeIcon';
import useIsMobile from 'client/hooks/isMobile';

const useStyles = makeStyles(({ palette, spacing, typography, zIndex }) => ({
    selector: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: `${spacing(1)}px ${spacing(2)}px`,
        color: palette.text.primary,
        fontSize: '1rem',
        textTransform: 'uppercase',
        userSelect: 'none',
        '@media (max-width: 600px)': {
            padding: `${spacing(0.5)}px ${spacing(1)}px`,
            fontSize: typography.pxToRem(14),
            minWidth: 'auto',
        },
    },
    label: {
        maxWidth: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media (max-width: 600px)': {
            maxWidth: 80,
        },
    },
    selectorItem: {
        whiteSpace: 'normal',
        textAlign: 'start',
    },
    popper: {
        zIndex: zIndex.modal,
        maxWidth: 200,
        '@media (max-width: 600px)': {
            maxWidth: 150,
        },
    },
    paper: {
        overflowY: 'auto',
    },
    icon: {
        marginInlineStart: spacing(1),
        '@media (max-width: 600px)': {
            marginInlineStart: spacing(0.5),
            fontSize: typography.pxToRem(18),
        },
    },
    globeIcon: {
        fill: palette.text.primary,
        marginInlineEnd: spacing(1),
    },
}));

function twoLetterCodeToLocalName(code) {
    return LANGUAGES_INFO[code]['localName'] || 'Unknown';
}

function LanguageSelector({ onChange, languages, value, showIcon = true }) {
    const [languageLabel, setLanguageLabel] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { isMobile } = useIsMobile();

    function getLanguageLabel(lang) {
        if (lang?.displayPageName) {
            return lang?.pageName;
        }
        if (isMobile) {
            return lang?.value?.toUpperCase();
        }
        return twoLetterCodeToLocalName(lang.value);
    }

    const handleMenuClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleMenuToggle = event => {
        if (anchorEl) {
            handleMenuClose();
        } else {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    };

    const handleChange = lang => {
        setLanguageLabel(getLanguageLabel(lang));
        handleMenuClose();
        if (typeof onChange === 'function') {
            onChange(lang.slug);
        }
    };

    useEffect(() => {
        if (value) {
            const label = getLanguageLabel(
                languages.find(lang => lang.pageName === value),
            );
            setLanguageLabel(label);
        }
    }, [value]);

    if (languages.length < 2) {
        return null;
    }

    return (
        <>
            <div className={classes.selector} onClick={handleMenuToggle}>
                {showIcon && !isMobile && (
                    <GlobeIcon className={classes.globeIcon} />
                )}
                <span className={classes.label}>{languageLabel}</span>
                <KeyboardArrowDown className={classes.icon} />
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                className={classes.popper}
                placement="bottom"
                disablePortal
            >
                <ClickAwayListener onClickAway={handleMenuClose}>
                    <Paper elevation={4} className={classes.paper}>
                        {languages.map(lang => (
                            <MenuItem
                                key={lang.pageName}
                                onClick={() => handleChange(lang)}
                                className={classes.selectorItem}
                            >
                                {getLanguageLabel(lang)}
                            </MenuItem>
                        ))}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
}

LanguageSelector.propTypes = {
    languages: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    showIcon: PropTypes.bool,
};

export default LanguageSelector;
