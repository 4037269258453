import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useIsMobile from 'client/hooks/isMobile';
import { getPaletteColor } from 'common/helpers/palette';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        margin: `${spacing(2)}px ${spacing(0.5)}px`,
        padding: `${spacing(0.5)}px ${spacing(1)}px`,
        display: 'flex',
        width: 'fit-content',
        background: palette.grey[300],
        borderRadius: 30,

        '@media (max-width: 1023px)': {
            margin: `${spacing(1)}px ${spacing(0.5)}px`,
        },
    },
    item: {
        display: 'flex',
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(14),
        color: palette.text.primary,
        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(12),
        },
    },
    link: {
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    },
    icon: {
        margin: `0 ${spacing(-1)}px`, // to get rid of the hard-coded separator margin from MUI
        'html[dir=rtl] &': {
            transform: 'rotate(180deg)',
        },
        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(12),
        },
    },
}));

function Breadcrumbs(props) {
    const { className, items, color = 'primary' } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const { isMobile } = useIsMobile();

    const colorCode = getPaletteColor(palette, color, palette.primary.main);

    return (
        <MuiBreadcrumbs
            component="div"
            separator={
                <NavigateNextIcon
                    className={classes.icon}
                    style={{ color: colorCode }}
                    fontSize="small"
                />
            }
            className={classNames(classes.root, className)}
            maxItems={isMobile ? 2 : 4}
            itemsAfterCollapse={2}
            itemsBeforeCollapse={1}
            aria-label="breadcrumb"
        >
            {items.map(item =>
                item.url ? (
                    <Link
                        key={item.title}
                        className={classNames(classes.item, classes.link)}
                        href={item.url}
                        style={{ color: colorCode }}
                    >
                        {item.title}
                    </Link>
                ) : (
                    <Typography
                        key={item.title}
                        className={classNames(classes.item, classes.current)}
                    >
                        {item.title}
                    </Typography>
                ),
            )}
        </MuiBreadcrumbs>
    );
}

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default Breadcrumbs;
