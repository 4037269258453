import { create } from 'client/helpers/axios';

const repository = {
    axios: create({ baseUrl: '/dashboard/metrics' }),

    async getDonationCounts() {
        const response = await this.axios.get('/donation');
        return response.data;
    },
};

export default repository;
