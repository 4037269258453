import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import { useSelect } from 'react-select-search';
import { ReactComponent as ArrowIcon } from './ArrowIcon.svg';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        minWidth: 160,
        flexShrink: 0,
        '@media (max-width: 600px)': {
            minWidth: 140,
        },
    },
    input: {
        display: 'flex',
        width: '100%',
        background: palette.background.default,
        border: `2px solid ${palette.primary.main}`,
        padding: spacing(1),
        paddingInlineStart: spacing(2),
        paddingInlineEnd: spacing(3.5),
        margin: 0,
        borderRadius: 999,
        whiteSpace: 'nowrap',
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        lineHeight: typography.pxToRem(18),
        fontWeight: 'bold',
        color: palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
    },
    arrow: {
        position: 'absolute',
        right: spacing(1.5),
        top: 14,
        pointerEvents: 'none',
        width: spacing(1.5),
        height: spacing(1.5),

        'html[dir=rtl] &': {
            right: 'auto',
            left: spacing(1.5),
        },
        '& path': {
            stroke: palette.primary.main,
        },
    },
    opened: {
        transform: 'rotate(180deg)',
    },
    options: {
        position: 'absolute',
        top: '100%',
        left: 0,
        minWidth: '100%',
        background: palette.background.default,
        listStyle: 'none',
        padding: `${spacing(0.5)}px 0`,
        margin: 0,
        boxShadow: '0px 0px 10px #8B8B8B29',
        borderRadius: 5,

        'html[dir=rtl] &': {
            left: 'auto',
            right: 0,
        },
    },
    option: {
        padding: `${spacing(0.5)}px ${spacing(1)}px`,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: spacing(1),
            top: '50%',
            transform: 'translateY(-50%)',
            width: 12,
            height: 12,
            border: `1px solid ${palette.text.primary}`,
            borderRadius: 2,
            background: palette.background.default,
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                left: 'auto',
                right: spacing(1),
            },
        },
        '&::after': {
            content: '""',
            display: 'none',
            width: 7,
            height: 4,
            borderStyle: 'solid',
            borderWidth: '1px 1px 0 0',
            borderColor: palette.primary.main,
            transform: 'scale(1, 1.5) translateY(-50%) rotate(135deg)',
            position: 'absolute',
            left: 11,
            top: 'calc(50% - 1px)',
            pointerEvents: 'none',

            'html[dir=rtl] &': {
                left: 'auto',
                right: spacing(2),
            },
        },
    },
    optionBtn: {
        background: 'none',
        display: 'block',
        width: '100%',
        border: 0,
        padding: 0,
        paddingInlineStart: spacing(2.5),
        margin: 0,
        textAlign: 'left',
        fontSize: typography.pxToRem(15),
        color: palette.text.primary,
    },
    selected: {
        '& button': {
            color: palette.primary.main,
        },
        '&::before': {
            borderColor: palette.primary.main,
        },
        '&::after': {
            display: 'block',
        },
    },
    otherBtn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: typography.pxToRem(15),
        color: palette.text.primary,
        padding: 0,
        paddingInlineStart: spacing(2.5),
    },
    otherSelected: {
        '& div': {
            color: palette.primary.main,
        },
        '& input': {
            color: palette.primary.main,
            borderBottomColor: palette.primary.main,
        },
    },
    otherInput: {
        border: 0,
        borderBottom: `1px solid ${palette.text.primary}`,
        margin: `0 ${spacing(0.5)}px`,
        padding: `${spacing(0.25)}px ${spacing(0.5)}px`,
        color: palette.text.primary,
        fontSize: typography.pxToRem(15),
    },
}));

function TipSelector(props) {
    const {
        amount,
        currencySign = '$',
        onChange,
        options,
        tipSelected,
        value,
    } = props;
    const classes = useStyles();
    const otherMin = 0;
    const otherMax = 999;

    const [opened, setOpened] = useState(false);
    const [isOtherSelected, setOtherSelected] = useState(
        !options.find(option => option.value == value),
    );
    const [otherValue, setOtherValue] = useState(value || 0);

    const [snapshot, valueProps, optionProps] = useSelect({
        options,
        value: String(value),
        onChange,
    });

    const optionClick = event => {
        event.preventDefault();
        setOtherSelected(false);
        setOpened(false);
    };

    const otherClick = event => {
        event.preventDefault();
        setOtherSelected(true);
        setOpened(false);
        onChange(otherValue);
    };
    const selectClick = event => {
        event.preventDefault();
        setOpened(!opened);
    };
    const otherChange = event => {
        let tipPercent = event.target?.value;
        if (!/^\d{1,3}\.?\d{0,2}$/.test(tipPercent)) {
            return;
        }
        if (tipPercent) {
            tipPercent = Math.round(Number(tipPercent) * 100) / 100;
        }
        if (tipPercent < otherMin) {
            tipPercent = otherMin;
        } else if (tipPercent > otherMax) {
            tipPercent = otherMax;
        }
        setOtherValue(tipPercent);
        if (isOtherSelected) {
            onChange(tipPercent);
        }
    };
    const otherKeyDown = event => {
        if (event.keyCode === 13 || event.keyCode === 27) {
            setOpened(false);
        }
    };
    const getDisplayValue = () => {
        const tip = value || 0;
        return `${tip}% (${currencySign}${((amount * tip) / 100).toLocaleString(
            'en-EN',
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        )})`;
    };

    useEffect(() => {
        setOpened(tipSelected);
    }, [tipSelected]);

    return (
        <ClickAwayListener onClickAway={() => setOpened(false)}>
            <div className={classes.root}>
                <button
                    className={classes.input}
                    {...valueProps}
                    onClick={selectClick}
                >
                    {getDisplayValue()}
                </button>
                <ArrowIcon
                    className={classNames(classes.arrow, {
                        [classes.opened]: opened,
                    })}
                />
                {opened && (
                    <ul className={classes.options}>
                        {snapshot.options.map(option => {
                            return (
                                <li
                                    className={classNames(classes.option, {
                                        [classes.selected]:
                                            !isOtherSelected &&
                                            option.value ===
                                                snapshot?.value?.value,
                                    })}
                                    key={option.value}
                                >
                                    <button
                                        {...optionProps}
                                        onClick={optionClick}
                                        value={option.value}
                                        className={classes.optionBtn}
                                    >
                                        {option.name}
                                    </button>
                                </li>
                            );
                        })}
                        <li
                            className={classNames(classes.option, {
                                [classes.selected]: isOtherSelected,
                                [classes.otherSelected]: isOtherSelected,
                            })}
                        >
                            <button
                                {...optionProps}
                                onClick={otherClick}
                                value={otherValue}
                                className={classes.optionBtn}
                            >
                                Other
                            </button>
                            <div className={classes.otherBtn}>
                                <input
                                    type="number"
                                    className={classes.otherInput}
                                    min={otherMin}
                                    max={otherMax}
                                    value={otherValue}
                                    onChange={otherChange}
                                    onKeyDown={otherKeyDown}
                                />
                                <span>%</span>
                            </div>
                        </li>
                    </ul>
                )}
            </div>
        </ClickAwayListener>
    );
}

TipSelector.propTypes = {
    amount: PropTypes.number,
    currencySign: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    tipSelected: PropTypes.bool,
    value: PropTypes.string,
};

export default TipSelector;
