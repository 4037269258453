import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    base: {
        position: 'relative',
    },
    wrapper: {
        position: 'relative',
    },
    truncate: {
        overflow: 'hidden',
        width: 'fit-content',
        '-webkit-box-orient': 'vertical',
        paddingRight: 0,
        paddingLeft: 0,
        'html[dir=rtl] &': {
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    truncateEllipsis: {
        paddingRight: 18,
        paddingLeft: 0,

        'html[dir=rtl] &': {
            paddingRight: 0,
            paddingLeft: 18,
        },
    },
    show: {
        overflowWrap: 'break-word',
    },
    ellipsis: {
        position: 'absolute',
        top: 0,
        right: 0,
        'html[dir=rtl] &': {
            right: 'auto',
            left: 0,
        },
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function TruncatedText({
    align = 'initial',
    justify = 'initial',
    text,
    textOverflow = 'ellipsis',
    width = '25ch',
    minHeight = 30,
    lines = 1,
    cursor = 'auto',
    expandOnHover = false,
    showEllipsis = false,
    ellipsisPlaceholder = '...',
}) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [truncate, setTruncate] = useState(true);

    const toggleExpanded = () => {
        if (!expandOnHover) {
            setExpanded(prev => !prev);
        }
    };

    const toggleTruncate = () => {
        setTruncate(prev => !prev);
    };

    const toggleExpandForHover = expand => {
        if (expandOnHover) {
            setExpanded(expand);
        }
    };

    const baseStyles = {
        maxWidth: width,
        minWidth: width,
        textAlign: justify,
    };
    if (truncate) {
        baseStyles.whiteSpace = lines > 1 ? 'normal' : 'nowrap';
        baseStyles.textOverflow = textOverflow;
        baseStyles.display =
            lines > 1 || showEllipsis ? '-webkit-box' : 'block';
        baseStyles.WebkitLineClamp = lines;
        baseStyles.cursor = cursor;
    }

    return (
        <div
            className={classes.wrapper}
            onMouseEnter={() => toggleExpandForHover(true)}
            onMouseLeave={() => toggleExpandForHover(false)}
        >
            <Collapse
                className={classNames({
                    [classes.alignCenter]: align === 'center',
                })}
                tabIndex="-1"
                onFocus={toggleExpanded}
                onBlur={toggleExpanded}
                in={expanded}
                collapsedHeight={minHeight}
                onEnter={toggleTruncate}
                onExited={toggleTruncate}
                timeout="auto"
            >
                <div
                    className={classNames(
                        classes.base,
                        truncate ? classes.truncate : classes.show,
                        truncate && showEllipsis
                            ? classes.truncateEllipsis
                            : null,
                    )}
                    style={baseStyles}
                >
                    {text}
                    {showEllipsis && !expanded && (
                        <div className={classes.ellipsis}>
                            {ellipsisPlaceholder}
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

TruncatedText.propTypes = {
    align: PropTypes.oneOf(['initial', 'center']),
    justify: PropTypes.oneOf(['initial', 'start', 'center', 'end']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    textOverflow: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lines: PropTypes.number,
    cursor: PropTypes.string,
    expandOnHover: PropTypes.bool,
    showEllipsis: PropTypes.bool,
    ellipsisPlaceholder: PropTypes.string,
};

export default TruncatedText;
