import React from 'react';
import ClassicDetailsBar from './ClassicDetailsBar';
import ModernDetailsBar from './ModernDetailsBar';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectPageDesign } from '../../features/campaign/campaignSlice';

function ItemDetailsBar(props) {
    const pageDesign = useSelector(selectPageDesign);

    switch (pageDesign) {
        case CAMPAIGN_PAGE_DESIGNS.MODERN:
            return <ModernDetailsBar {...props} />;

        case CAMPAIGN_PAGE_DESIGNS.CLASSIC:
        default:
            return <ClassicDetailsBar {...props} />;
    }
}

export default ItemDetailsBar;
