// @ts-check

/**
 * @param {any} value
 * @returns {value is number}
 */
function isNumber(value) {
    return (
        !isNaN(Number(value)) &&
        !Array.isArray(value) &&
        value !== null &&
        value !== '' &&
        value !== Infinity &&
        value !== -Infinity
    );
}

/**
 * @template U
 * @param {any} value
 * @param {U} defaultValue
 * @returns {number | U}
 */
function toNumber(value, defaultValue) {
    return isNumber(value) ? Number(value) : defaultValue;
}

module.exports = { isNumber, toNumber };
