import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ensureHttpsProtocol } from 'common/helpers/url';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'client/componentLibrary/Button';
import {
    CAMPAIGN_PAGE_DESIGNS,
    SUPPORTED_PAGE_DESIGNS,
} from 'common/constants';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            '& > a + a': {
                marginInlineStart: spacing(2),
            },
            '@media (max-width: 600px)': {
                display: 'none',
            },
        },
        loginLnk: {
            textDecoration: 'none',
            marginRight: 25,
            color: palette.text.primary,
            fontWeight: 500,
        },
    }),
    { name: 'HeaderButtons' },
);

function HeaderButtons({ scheduleLink, lang, pageDesign }) {
    const classes = useStyles();

    const getScheduleDemoLink = () => {
        if (scheduleLink?.link) {
            // for backward compatibility, before we added validation.
            return ensureHttpsProtocol(scheduleLink.link);
        }

        return `https://www.causematch.com/${
            lang && lang == 'he' ? lang + '/' : ''
        }request-a-demo/?ref=cm2`;
    };

    const getScheduleDemoLinkLabel = () => {
        if (scheduleLink?.label) {
            return scheduleLink.label;
        }

        return (
            <FormattedMessage
                id="PageHeader.scheduleDemo"
                defaultMessage="Schedule a Demo"
            />
        );
    };

    return (
        <div className={classNames(classes.root, 'header-buttons')}>
            <a className={classes.loginLnk} href="/login">
                <FormattedMessage
                    id="HeaderButtons.logIn"
                    defaultMessage="Log in"
                />
            </a>

            <Button
                className="schedule-a-demo"
                color={
                    pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN
                        ? 'accent'
                        : 'secondary'
                }
                square={pageDesign !== CAMPAIGN_PAGE_DESIGNS.MODERN}
                variant="outlined"
                link
                href={getScheduleDemoLink()}
            >
                {getScheduleDemoLinkLabel()}
            </Button>
        </div>
    );
}

HeaderButtons.propTypes = {
    scheduleLink: PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
    }),
    lang: PropTypes.string,
    pageDesign: PropTypes.oneOf(SUPPORTED_PAGE_DESIGNS),
};

export default HeaderButtons;
