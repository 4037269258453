const GATEWAY_TAGS = Object.freeze({
    PAYMENT_PROCESSOR: 'payment_processor',
    DAF: 'daf',
    VOUCHER: 'voucher',
    RECOMMENDED: 'recommended',
    US_ONLY: 'us_only',
    UK_ONLY: 'uk_only',
    ADMIN_ONLY: 'admin_only',
});

const GATEWAYS = Object.freeze({
    stripe: {
        label: 'Stripe',
        flow: 'intent',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: true,
        tipping: true,
        receiptName: true,
        support: {
            card: { all: true },
            googlepay: { all: true },
            applepay: { all: true },
            cashapp: { currencies: ['USD'] },
            ideal: { currencies: ['EUR'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR, GATEWAY_TAGS.RECOMMENDED],
        displayOrder: 0,
    },
    yaadpay: {
        label: 'YaadPay',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['ILS', 'USD', 'GBP', 'EUR'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 5,
    },
    offline: {
        label: 'Offline',
        flow: 'offline',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        defaultPaymentMethod: 'offline',
        tags: [],
        displayOrder: -1,
    },
    api: {
        label: 'API',
        flow: 'offline',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        defaultPaymentMethod: 'offline',
        tags: [],
        displayOrder: -2,
    },
    payme: {
        label: 'Payme',
        flow: { card: 'charge', bit: 'awayPre' },
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        paymentMethods: ['card', 'bit'],
        support: {
            card: { currencies: ['GBP', 'USD', 'ILS', 'EUR'] },
            bit: { currencies: ['ILS'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR, GATEWAY_TAGS.ADMIN_ONLY],
        displayOrder: 12,
    },
    cardknox: {
        label: 'Cardknox',
        flow: {
            card: 'charge',
            googlepay: 'charge',
            pledger: 'charge',
            matbia: 'charge',
            applepay: 'awayPre',
        },
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        paymentMethods: ['card', 'googlepay', 'pledger', 'matbia', 'applepay'],
        support: {
            card: { all: true },
            googlepay: { all: true },
            pledger: { currencies: ['USD'] },
            matbia: { currencies: ['USD'] },
            applepay: { currencies: ['USD'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 1,
    },
    nedarim: {
        label: 'Nedarim',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['ILS', 'USD'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 4,
    },
    nmi: {
        label: 'NMI',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['USD'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 7,
    },
    paypal: {
        label: 'Paypal',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            paypal: { all: true },
        },
        defaultPaymentMethod: 'paypal',
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 6,
    },
    banquest: {
        label: 'Banquest',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['USD'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 8,
    },
    payfast: {
        label: 'Payfast',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['ZAR'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 10,
    },
    meshulam: {
        label: 'Meshulam',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['ILS'] },
            bit: { currencies: ['ILS'] },
            googlepay: { currencies: ['ILS'] },
            applepay: { currencies: ['ILS'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR, GATEWAY_TAGS.RECOMMENDED],
        displayOrder: 3,
    },
    tranzila: {
        label: 'Tranzila',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['ILS'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR, GATEWAY_TAGS.ADMIN_ONLY],
        displayOrder: 11,
    },

    chariot: {
        label: 'Chariot',
        flow: 'charge',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        receiptName: true,
        support: {
            chariot: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'chariot',
        tags: [GATEWAY_TAGS.DAF, GATEWAY_TAGS.US_ONLY],
        displayOrder: 13,
    },

    donorsfund: {
        label: 'The Donors Fund',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            donorsfund: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'donorsfund',
        isVoucher: true,
        tags: [GATEWAY_TAGS.DAF, GATEWAY_TAGS.US_ONLY],
        displayOrder: 14,
    },

    ojcfund: {
        label: 'OJC Fund',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            ojcfund: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'ojcfund',
        isVoucher: true,
        tags: [GATEWAY_TAGS.DAF, GATEWAY_TAGS.US_ONLY],
        displayOrder: 15,
    },
    aac: {
        label: 'Achisomoch',
        flow: 'awayPre',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            aac: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'aac',
        isVoucher: true,
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 16,
    },
    asserbishvil: {
        label: 'Asser Bishvil',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            asserbishvil: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'asserbishvil',
        isVoucher: true,
        apiUrl: 'https://online.asserbishvil.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 17,
    },
    broom: {
        label: 'Broom',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            broom: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'broom',
        isVoucher: true,
        apiUrl: 'https://online.broomfoundation.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 18,
    },
    belz: {
        label: 'Belz',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            belz: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'belz',
        isVoucher: true,
        apiUrl: 'https://belzvouchers.com/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 19,
    },
    cmz: {
        label: 'CMZ',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            cmz: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'cmz',
        isVoucher: true,
        apiUrl: 'https://online.cmz.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 20,
    },
    kolyom: {
        label: 'Kol Yom',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            kolyom: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'kolyom',
        isVoucher: true,
        apiUrl: 'https://online.kolyomtrust.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 21,
    },
    mhtrust: {
        label: 'MH Trust',
        flow: 'awayPre',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            mhtrust: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'mhtrust',
        isVoucher: true,
        apiUrl: 'https://mhtrust.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 22,
    },
    yadshlomo: {
        label: 'Yad Shlomo',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            yadshlomo: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'yadshlomo',
        isVoucher: true,
        apiUrl: 'https://yadshlomo.org/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 23,
    },
    zoreyatzedokos: {
        label: 'Zoreya Tzedokos',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            zoreyatzedokos: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'zoreyatzedokos',
        isVoucher: true,
        apiUrl: 'https://online.zoreyatzedokos.org.uk/api',
        tags: [GATEWAY_TAGS.VOUCHER, GATEWAY_TAGS.UK_ONLY],
        displayOrder: 24,
    },
    authorize: {
        label: 'Authorize',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: true,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['USD', 'CAD', 'GBP', 'EUR', 'AUD', 'NZD'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 2,
    },
    peach: {
        label: 'Peach',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: true,
        tipping: false,
        receiptName: false,
        support: {
            card: { currencies: ['ILS', 'USD', 'GBP', 'EUR', 'CAD'] },
            bit: { currencies: ['ILS'] },
        },
        tags: [GATEWAY_TAGS.PAYMENT_PROCESSOR],
        displayOrder: 9,
    },
});

module.exports = {
    GATEWAY_TAGS,
    GATEWAYS,
};
