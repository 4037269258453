import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import SearchIcon from 'client/components/Icons/Search';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        display: 'inline-block',
        position: 'relative',
        width: props => props.width,
    },

    search: {
        width: '100%',
        height: 28,
        paddingInlineStart: '15px',
        paddingInlineEnd: '35px',
        border: `2px solid ${palette.primary.main}`,
        borderRadius: 13,
        color: palette.text.primary,
        fontSize: '0.938rem',
        fontFamily: typography.fontFamily,
        boxSizing: 'border-box',
        '&::placeholder': {
            color: palette.primary.main,
            opacity: 1,
        },
        '&::-ms-input-placeholder': {
            color: palette.primary.main,
        },
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },

    icon: {
        stroke: palette.primary.main,
        width: 14,
        height: 14,
        position: 'absolute',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        'html[dir=rtl] &': {
            right: 'auto',
            left: 10,
        },
    },
}));

function SearchInput(props) {
    const { formatMessage } = useIntl();
    const {
        placeholder = formatMessage({
            id: 'SearchInput.placeholder',
            defaultMessage: 'Search...',
        }),
        width = 'auto',
        className,
        value: initialValue,
        onChange,
        delay = 200,
        ...rest
    } = props;
    const timer = useRef();
    const [value, setValue] = useState(initialValue);
    const classes = useStyles({ width });
    const handleChange = useCallback(event => {
        setValue(event.target.value);
        if (typeof onChange === 'function') {
            event.persist();
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                onChange(event.target.value);
            }, delay);
        }
    }, []);

    return (
        <div className={classNames(classes.root, className)}>
            <input
                type="text"
                placeholder={placeholder}
                {...rest}
                value={value}
                onChange={handleChange}
                className={classes.search}
            />
            <SearchIcon className={classes.icon} />
        </div>
    );
}

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    delay: PropTypes.number,
};

export default SearchInput;
