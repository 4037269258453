import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ArrowLabel from '../ArrowLabel';
import CheckmarkIcon from 'client/components/Icons/Checkmark';
import { getPaletteColor } from 'common/helpers/palette';

const normalizeGoals = goals => {
    if (!Array.isArray(goals) || goals.length < 1) {
        return [];
    }

    let previousPercent = 0;
    const normalizedGoals = goals
        .map((goal, index) => {
            if (isNaN(goal?.percent)) return null;

            const normalizedPercent = Math.min(Number(goal.percent) || 0, 100);
            const color = goal?.color || 'primary';

            // Check if the current goal  is greater than the previous one
            if (index > 0 && normalizedPercent <= previousPercent) {
                return null;
            }

            previousPercent = normalizedPercent;

            return { percent: normalizedPercent, color };
        })
        .filter(goal => goal !== null)
        .map((goal, index, arr) => {
            const goalReached =
                index === arr.length - 1
                    ? goal.percent >= 100
                    : arr[index + 1].percent > goal.percent;

            return { ...goal, goalReached };
        })
        .reverse(); // for ordered rendering
    return normalizedGoals;
};

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    return {
        root: {
            width: '100%',
            background: palette.background.paperBorder,
            borderRadius: 100,
            position: 'relative',
        },
        progressBar: {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            borderRadius: 100,
            '&::after': {
                content: '""',
                position: 'absolute',
                left: `50%`,
                top: '100%',
                borderStyle: 'solid',
                borderWidth: '8px 3px 0px 3px',
                borderColor: 'transparent',
            },

            'html[dir=rtl] &': {
                transform: 'rotate(180deg)',
                left: 'auto',
                right: 0,
            },
        },
        label: {
            position: 'absolute',
            bottom: spacing(2.75),
            transform: 'translateX(-58%)',
            lineHeight: 1,
            left: `var(--progress-percent)`,

            'html[dir=rtl] &': {
                transform: 'translateX(43%)',
                left: 'auto !important',
                right: `var(--progress-percent)`,
            },
        },
        progressCircle: {
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: spacing(1.25),
            height: spacing(1.25),
            borderRadius: '50%',
            backgroundColor: palette.background.default,
            border: `${spacing(0.5)}px solid transparent`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: '100%',
        },
        checkmark: {
            fontSize: typography.pxToRem(10),
            'html[dir=rtl] &': {
                transform: 'rotate(180deg)',
            },
        },
    };
});

function Progress(props) {
    const {
        className,
        labelClassName,
        goals = [],
        label = '',
        showLabel = false,
        size = 8,
        style,
    } = props;
    const { palette } = useTheme();

    const normalizedGoals = normalizeGoals(goals);

    if (normalizedGoals?.length < 1) return null;

    const topGoal = normalizedGoals[0];
    const classes = useStyles();

    return (
        <div
            className={classNames(classes.root, className)}
            style={{ height: size, ...style }}
        >
            {showLabel && label && (
                <ArrowLabel
                    className={classNames(classes.label, labelClassName)}
                    elevation={1}
                    label={label}
                    color={topGoal?.color}
                    style={{ '--progress-percent': `${topGoal.percent}%` }}
                />
            )}
            {normalizedGoals.map(({ color, goalReached, percent }, index) => {
                const colorCode = getPaletteColor(
                    palette,
                    color,
                    palette.primary.main,
                );

                return (
                    <div
                        key={index}
                        className={classNames(classes.progressBar)}
                        style={{
                            width: `${percent}%`,
                            background: colorCode,
                        }}
                    >
                        <div
                            className={classes.progressCircle}
                            style={{
                                borderColor: colorCode,
                                zIndex: normalizedGoals.length - index,
                            }}
                        >
                            {goalReached && (
                                <span className={classes.checkmark}>
                                    <CheckmarkIcon color={colorCode} />
                                </span>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

Progress.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    goals: PropTypes.arrayOf(
        PropTypes.shape({
            percent: PropTypes.number,
            color: PropTypes.string,
        }),
    ),
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    size: PropTypes.number,
    style: PropTypes.object,
};

export default Progress;
