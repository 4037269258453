import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined as TooltipIcon } from '@material-ui/icons';
import { isLightColor } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        marginInlineStart: spacing(1.5),
        marginInlineEnd: spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        fontSize: '1rem',

        '@media (max-width: 400px)': {
            marginInlineStart: spacing(1),
            marginInlineEnd: spacing(1),
        },
    },
    small: {
        fontSize: '0.9rem',

        '@media (max-width: 600px)': {
            marginInlineStart: spacing(1),
            marginInlineEnd: spacing(1),
        },
    },
    donationTier: {
        borderRadius: spacing(0.75),
        padding: `${spacing(0.5)}px ${spacing(1.25)}px`,
        fontWeight: 900,
        maxWidth: `20ch`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '@media (max-width: 600px)': {
            fontSize: 13,
            maxWidth: `15ch`,
            padding: `${spacing(0.5)}px ${spacing(0.75)}px`,

            '[data-size="small"] &': {
                fontSize: 11,
            },
        },
    },
    tooltipIcon: {
        marginInlineStart: spacing(1),
        cursor: 'pointer',
        color: palette.accent.main,
        position: `static !important`,
        pointerEvents: `all !important`,

        '[data-size="small"] &': {
            fontSize: 20,
        },

        '@media (max-width: 600px)': {
            marginInlineStart: spacing(0.5),
            fontSize: 17,
        },
    },
    tooltip: {
        backgroundColor: palette.accent.main,
        borderRadius: spacing(0.5),
        padding: spacing(1.5),
    },
    tooltipContent: {
        color: '#fff',
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
    },
    tooltipLabel: {
        fontWeight: 900,
    },
}));

function DonationTier({ donationTier, size = 'normal' }) {
    const classes = useStyles();
    const { palette } = useTheme();
    const labelRef = useRef();
    const [isLabelTruncated, setIsLabelTruncated] = useState(false);

    if (!donationTier?.label) {
        return null;
    }

    useLayoutEffect(() => {
        if (labelRef.current) {
            setIsLabelTruncated(
                labelRef.current.offsetWidth < labelRef.current.scrollWidth,
            );
        }
    }, [donationTier.label, labelRef.current]);

    const renderLabel = () => {
        return (
            <span
                ref={labelRef}
                className={classes.donationTier}
                style={{
                    backgroundColor: donationTier.color,
                    color: isLightColor(donationTier.color, 0.65)
                        ? palette.accent.main
                        : palette.background.paper,
                }}
            >
                {donationTier.label}
            </span>
        );
    };

    const renderTooltipTitle = () => {
        if (!donationTier?.allDonationTiers?.length) {
            return null;
        }

        return donationTier.allDonationTiers.map(tier => (
            <div key={tier.entityId}>
                <Typography
                    className={classNames(
                        classes.tooltipContent,
                        classes.tooltipLabel,
                    )}
                >
                    {tier.label}
                </Typography>
                <Typography className={classes.tooltipContent}>
                    {tier.description}
                </Typography>
            </div>
        ));
    };

    return (
        <div
            className={classNames(classes.root, {
                [classes.small]: size === 'small',
            })}
            data-size={size}
        >
            {isLabelTruncated ? (
                <Tooltip title={donationTier.label} enterTouchDelay={0}>
                    {renderLabel()}
                </Tooltip>
            ) : (
                renderLabel()
            )}
            {donationTier?.showTooltip && (
                <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={renderTooltipTitle()}
                    enterTouchDelay={0}
                >
                    <TooltipIcon className={classes.tooltipIcon} />
                </Tooltip>
            )}
        </div>
    );
}

DonationTier.propTypes = {
    donationTier: PropTypes.object,
    size: PropTypes.oneOf(['normal', 'small']),
};

export default DonationTier;
