import * as yup from 'yup';
import {
    PAYMENT_METHODS,
    PAYMENT_METHODS_VALUES,
    PAYMENT_GATEWAYS,
    FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES,
    CURRENCIES,
} from 'common/constants';
import {
    getDefaultFieldLabel,
    isDefaultFieldAllowed,
} from 'client/helpers/checkoutFields';
import { isGiftAidField } from 'client/helpers/giftAid';
import { currencySign, getSingleDonationAmount } from 'common/helpers';
import { maxDonationAmount } from 'common/helpers/donation';

const yupType = (type, label, formatMessage) => {
    switch (type) {
        case 'number':
            return yup.number().typeError(
                formatMessage(
                    {
                        id: `DonationForm.field.invalidNumber`,
                        defaultMessage: `${label} should be a valid number`,
                    },
                    {
                        field: label,
                    },
                ),
            );
        case 'text':
        case 'textarea':
        case 'email':
        case 'dropdown':
            return yup.string();
        case 'date':
            return yup.string().nullable();
        case 'checkbox':
            return yup.boolean();
        case 'checkboxGroup':
            return yup.array().of(yup.string());
        case 'radioGroup':
        default:
            return yup.mixed();
    }
};

const yupRequired = (yup, type, message) => {
    let rule = yup.required(message);

    if (type === 'checkbox') {
        rule = rule.test('is-checked', message, Boolean);
    } else if (type === 'checkboxGroup') {
        rule = rule.test(
            'is-checked',
            message,
            value => Array.isArray(value) && value.some(Boolean),
        );
    }

    return rule;
};

const yupDefaultField = (field, config, paymentGateway, formatMessage) => {
    const fieldLabel = getDefaultFieldLabel(formatMessage, {
        key: field,
        field: config,
    });
    const required = schema =>
        yupRequired(
            schema,
            config.type,
            formatMessage(
                {
                    id: `DonationForm.field.required`,
                    defaultMessage: `${config.label} is required`,
                },
                {
                    field: fieldLabel,
                },
            ),
        );

    let schema = null;
    if (config.isEnabled && config.isRequired) {
        schema = required(yupType(config.type, config.label, formatMessage));
    } else if (isGiftAidField(field)) {
        schema = yupType(config.type, config.label, formatMessage).when(
            'giftAid',
            {
                is: value => Boolean(value),
                then: required,
            },
        );
    }

    const maxLength100Fields = [
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'country',
    ];
    if (maxLength100Fields.includes(field)) {
        schema = schema || yupType(config.type, config.label, formatMessage);
        schema = schema.max(
            100,
            formatMessage(
                {
                    id: `DonationForm.displayName.max.length`,
                },
                {
                    max: 100,
                },
            ),
        );
    }

    if (field === 'phone' && paymentGateway === PAYMENT_GATEWAYS.MESHULAM) {
        schema = schema || yupType(config.type, config.label, formatMessage);
        schema = schema.matches(
            /^5\d{8}$/,
            formatMessage({
                id: 'DonationForm.phone.invalid',
                defaultMessage: 'Phone number is not valid',
            }),
        );
    }

    return schema;
};

const fiscalSponsorRules = (
    { formatMessage },
    { enableFiscalSponsor, fiscalSponsor },
) => {
    if (!enableFiscalSponsor) {
        return {};
    }

    const rules = {};
    if (fiscalSponsor === FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES.PEF) {
        rules['pefTermsCheckbox'] = yupRequired(
            yup.boolean(),
            'checkbox',
            formatMessage({
                id: 'DonationForm.pefTerms.required',
                defaultMessage: 'Please accept the PEF Terms & Conditions',
            }),
        );
    }

    return rules;
};

export default (
    formatMessage,
    paymentGateway,
    fiscalSponsorConfig,
    minDonation = 5,
    customFields = {},
    defaultFields = {},
    donationLayersNames,
) => {
    const whenCardValidationAllow = (validation, selectedPaymentGateway) => {
        return yup.mixed().when('paymentMethod', {
            is: value =>
                value === 'card' && paymentGateway === selectedPaymentGateway,
            then: validation,
        });
    };

    const whenVoucherValidationAllow = (validation, selectedPaymentMethod) => {
        return yup.mixed().when('paymentMethod', {
            is: value => value === selectedPaymentMethod,
            then: validation,
        });
    };

    const additionalValidationRules = (
        customFields = {},
        defaultFields = {},
        paymentGateway,
    ) => {
        const rules = {};

        if (Object.keys(customFields).length > 0) {
            const customFieldsRules = {};

            for (const [field, config] of Object.entries(customFields)) {
                let schema = null;
                if (config.required) {
                    const message = formatMessage(
                        {
                            id: `DonationForm.field.required`,
                            defaultMessage: `${config.label} is required`,
                        },
                        {
                            field: config.label,
                        },
                    );

                    schema = yupRequired(
                        yupType(config.type, config.label, formatMessage),
                        config.type,
                        message,
                    );
                }
                customFieldsRules[field] = schema;
            }

            if (Object.keys(customFieldsRules).length > 0) {
                rules['customFields'] = yup.object().shape(customFieldsRules);
            }
        }

        for (const [field, config] of Object.entries(defaultFields)) {
            if (
                isDefaultFieldAllowed({
                    name: field,
                    config,
                    paymentGateway,
                }) ||
                isGiftAidField(field)
            ) {
                let schema = yupDefaultField(
                    field,
                    config,
                    paymentGateway,
                    formatMessage,
                );

                if (config.type === 'email') {
                    schema = (schema || yup.string()).email(
                        formatMessage(
                            {
                                id: `DonationForm.field.invalid`,
                                defaultMessage: `${config.label} is invalid`,
                            },
                            {
                                field: config.label,
                            },
                        ),
                    );
                }

                if (field === 'phone') {
                    rules['phonePrefix'] = yup.mixed().test(
                        'is-required',
                        formatMessage({
                            id: 'DonationForm.field.countryCodeIsNotSelected',
                            defaultMessage: 'Please select a country code',
                        }),
                        (value, context) => {
                            if (
                                !value &&
                                (config.isRequired || context.parent.phone)
                            ) {
                                return false;
                            }

                            return true;
                        },
                    );
                }

                if (schema) {
                    rules[field] = schema;
                }
            }
        }

        return rules;
    };

    const amountRequiredText = formatMessage({
        id: 'DonationForm.amountRequired',
        defaultMessage: 'Amount is required',
    });

    const defaultRules = {
        paymentMode: yup
            .mixed()
            .oneOf([
                'regular',
                'installments',
                'recurring',
                'unlimitedRecurring',
            ])
            .default('regular'),
        paymentMethod: yup
            .mixed()
            .oneOf(PAYMENT_METHODS_VALUES)
            .default('card'),
        layerItems: yup.mixed(),
        currency: yup.mixed().oneOf(CURRENCIES),
        amount: yup
            .number()
            .integer()
            .positive(amountRequiredText)
            .required(amountRequiredText)
            .when('currency', (currency, schema) => {
                return schema.max(
                    maxDonationAmount(currency),
                    formatMessage({
                        id: 'DonationForm.maximumAmount',
                        defaultMessage: 'Maximum allowed amount is exceeded',
                    }),
                );
            })
            .when(
                ['layerItems', 'currency'],
                (layerItems, currency, schema) => {
                    if (layerItems && layerItems.length > 1) {
                        const newMinDonationAmount =
                            minDonation * layerItems.length;
                        const errorMessage = formatMessage(
                            {
                                id: 'DonationForm.minAmountAfterSplit',
                                defaultMessage:
                                    'Required minimum donation amount is {minTotalAmount} (you have chosen to donate to {count} {layers}, the donation amount is split equally among them and minimum allowed for each is {minDonation})',
                            },
                            {
                                minTotalAmount: `${currencySign(
                                    currency,
                                )}${newMinDonationAmount}`,
                                count: layerItems.length,
                                layers: donationLayersNames,
                                minDonation: `${currencySign(
                                    currency,
                                )}${minDonation}`,
                            },
                        );
                        return schema.test(
                            'checkLayerItems',
                            errorMessage,
                            function (value, testContext) {
                                const layerItems =
                                    testContext.parent.layerItems;
                                if (
                                    getSingleDonationAmount(
                                        value,
                                        layerItems.length,
                                    ) < minDonation
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        );
                    } else {
                        return schema.min(
                            minDonation,
                            formatMessage(
                                {
                                    id: 'DonationForm.minimumAmount',
                                    defaultMessage:
                                        'Required minimum donation amount is {minDonation}',
                                },
                                { minDonation },
                            ),
                        );
                    }
                },
            )
            .when('paymentMethod', {
                is: value => value === PAYMENT_METHODS.BIT,
                then: schema =>
                    schema.max(
                        3600,
                        formatMessage(
                            {
                                id: 'DonationForm.bit.maximumAmount',
                                defaultMessage:
                                    'Maximum donation amount allowed with Bit is {maxDonation} ILS',
                            },
                            { maxDonation: 3600 },
                        ),
                    ),
            }),
        donorsFundDonor: whenVoucherValidationAllow(
            yup.string().required(
                formatMessage({
                    id: 'DonationForm.cardEmailIsRequired',
                    defaultMessage: 'Card Number/Email is required',
                }),
            ),
            'donorsfund',
        ),
        donorsFundDonorAuth: whenVoucherValidationAllow(
            yup.string().required(
                formatMessage({
                    id: 'DonationForm.cvvPinIsRequired',
                    defaultMessage: 'CVC/PIN is required',
                }),
            ),
            'donorsfund',
        ),
        ojcFundCard: whenVoucherValidationAllow(
            yup.string().required(
                formatMessage({
                    id: 'DonationForm.cardNumber.required',
                    defaultMessage: 'Card Number is required',
                }),
            ),
            'ojcfund',
        ),
        ojcFundExpiry: whenVoucherValidationAllow(
            yup
                .string()
                .required(
                    formatMessage({
                        id: 'DonationForm.expire.required',
                        defaultMessage: 'Expiry Date is required',
                    }),
                )
                .matches(
                    /^(\d\d\d\d|\d\d\/\d\d(\d\d)?)$/,
                    formatMessage({
                        id: 'DonationForm.expire.invalid',
                        defaultMessage: 'Card expiration date is invalid',
                    }),
                ),
            'ojcfund',
        ),
        accountNumber: yup.string().when('paymentMethod', {
            is: value => value === 'aac',
            then: yup.string().required(
                formatMessage({
                    id: 'DonationForm.accountNumberRequired',
                    defaultMessage: 'Account Number is required',
                }),
            ),
        }),
        firstName: yup
            .string()
            .max(100)
            .required(
                formatMessage({
                    id: 'DonationForm.firstName.required',
                    defaultMessage: 'First Name is required',
                }),
            ),
        lastName: yup
            .string()
            .max(100)
            .required(
                formatMessage({
                    id: 'DonationForm.lastName.required',
                    defaultMessage: 'Last Name is required',
                }),
            ),
        comment: yup.string(),
        terms: yup.bool().oneOf(
            [true],
            formatMessage({
                id: 'DonationForm.terms.checked',
                defaultMessage: 'Field must be checked',
            }),
        ),
        enableDisplayName: yup.bool(),
        displayName: yup
            .string()
            .max(
                100,
                formatMessage(
                    {
                        id: 'DonationForm.displayName.max.length',
                    },
                    {
                        max: 100,
                    },
                ),
            )
            .when('enableDisplayName', {
                is: true,
                then: yup.string().required(
                    formatMessage({
                        id: 'DonationForm.displayName.required',
                    }),
                ),
            }),
        idealBank: yup.object().when('paymentMethod', {
            is: 'ideal',
            then: yup.object().test(
                'ideal-bank',
                formatMessage({
                    id: 'DonationForm.idealBank.required',
                    defaultMessage: 'You must select a bank to continue',
                }),
                value => value && typeof value === 'object' && value.value,
            ),
        }),
        cardNumber: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.cardNumber.required',
                        defaultMessage: 'Card number is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.STRIPE,
        ),
        ccv: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.ccv.required',
                        defaultMessage: 'Card CCV is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.STRIPE,
        ),
        expire: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.expire.required',
                        defaultMessage: 'Card expiration date is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.STRIPE,
        ),
        nmiCardNumber: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.cardNumber.required',
                        defaultMessage: 'Card number is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.NMI,
        ),
        nmiCvv: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.ccv.required',
                        defaultMessage: 'Card CVV is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.NMI,
        ),
        nmiExpire: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.expire.required',
                        defaultMessage: 'Card expiration date is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.NMI,
        ),
        banquestCardNumber: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.cardNumber.required',
                        defaultMessage: 'Card number is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.BANQUEST,
        ),
        banquestExpiry: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.expire.required',
                        defaultMessage: 'Card expiration date is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.BANQUEST,
        ),
        banquestCvv: whenCardValidationAllow(
            yup
                .mixed()
                .requiredCardElement(
                    formatMessage({
                        id: 'DonationForm.ccv.required',
                        defaultMessage: 'Card CVV is required',
                    }),
                )
                .cardElementError(),
            PAYMENT_GATEWAYS.BANQUEST,
        ),
    };

    return yup.object().shape({
        ...defaultRules,
        ...additionalValidationRules(
            customFields,
            defaultFields,
            paymentGateway,
        ),
        ...fiscalSponsorRules({ formatMessage }, fiscalSponsorConfig),
    });
};
