import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';
import ClientError from 'client/helpers/clientError';

class DonationMessageRepository extends Repository {
    async sendBatchMessage(messageProps) {
        try {
            return await this.axios.post(`/batch`, messageProps);
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new ClientError(message, error.response.data.errorData);
        }
    }

    async getMessageCost(campaignId) {
        try {
            return await this.axios.get(`/cost/${campaignId}`);
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async getAvailableTransportTypes({
        filters,
        excludedDonationIds,
        messageSource,
    }) {
        try {
            const response = await this.axios.post(`/availableTransportTypes`, {
                filters: {
                    ...filters,
                    excludedDonationIds,
                },
                messageSource,
            });
            return response.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async getMessageBatchCost(body) {
        try {
            const response = await this.axios.post(`/previewSmsCost`, body);
            return response.data;
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new ClientError(message, error.response.data.errorData);
        }
    }
}

export default new DonationMessageRepository({
    baseUrl: '/donation/messaging',
});
