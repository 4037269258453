import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Confetti from 'client/components/Confetti';
import updateThemePalette from 'client/helpers/campaign/updateThemePalette';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { defaultCampaignTheme } from 'client/theme';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import HeroSlider from '../components/HeroSlider/HeroSlider';
import ItemDetailsBar from '../components/ItemDetailsBar';
import PageCenterText from '../components/PageCenterText/PageCenterText';
import NewPerks from '../components/Perks/ClassicDesign/Perks';
import OldPerks from '../components/Perks/LegacyDesign/Perks';
import SharePanel from '../components/SharePanel/SharePanel';
import {
    selectCampaignPageData,
    selectCampaignSlug,
    selectCurrencies,
    selectEnableDonations,
    selectGivings,
    selectHeroSlides,
    selectHideRdpMenu,
    selectLandingData,
    selectMatchRatio,
    selectSelectedLayerItem,
    selectUseUpdatedPerkDesign,
} from '../features/campaign/campaignSlice';
import CheckoutPopup from '../features/checkout/CheckoutPopup';
import DonationWidget from '../features/checkout/DonationWidget';
import StatisticWidgets from '../features/recentDonationPanel/StatisticWidgets';
import RecentDonationPanel from '../features/recentDonationPanel/Tabs';
import SuggestedAmountsWidget from '../features/checkout/SuggestedAmountsWidget';

const useStyles = makeStyles(({ palette }) => ({
    textSection: {
        marginTop: 45,
    },
    bodyBanner: {
        marginTop: 60,
    },
    statisticWidget: {
        padding: '15px 20px',
        background: palette.grey[100],
        borderRadius: 3,
    },
}));

function ClassicCampaignPage({
    campaign,
    handleDonateNowClick,
    handleTimerStop,
    layerItemRaisedAmount,
    isMobile,
    rdpTabs,
}) {
    const classes = useStyles();
    const {
        showFirstTagline = false,
        showSecondTagline = false,
        showAboveBodyText = false,
        firstTagline = null,
        secondTagline = null,
        aboveBodyText = null,
        metaTitle,
        metaDescription,
        shareButtons,
    } = useSelector(selectLandingData);
    const campaignSlug = useSelector(selectCampaignSlug);
    const {
        bodyImage,
        mobileBodyImage,
        shortDescription,
        showShortDescription,
        name,
        isLayerItem,
        isMobileBodyImageEnabled,
        palette: paletteOverrides,
    } = useSelector(selectCampaignPageData);
    const {
        images: heroSlides,
        isVideoEnabled,
        video,
        mobileImages: mobileHeroSlides,
        interval,
        isMobileImagesEnabled,
    } = useSelector(selectHeroSlides);
    const multiplier = useSelector(selectMatchRatio);
    const currencies = useSelector(selectCurrencies);
    const givings = useSelector(selectGivings);
    const useUpdatedPerkDesign = useSelector(selectUseUpdatedPerkDesign);
    const enableDonations = useSelector(selectEnableDonations);
    const pageLayerItem = useSelector(selectSelectedLayerItem);
    const hideRdpMenu = useSelector(selectHideRdpMenu);

    const getBodyImageSrc = () => {
        if (isMobile) {
            return isMobileBodyImageEnabled ? mobileBodyImage : '';
        }
        return bodyImage;
    };

    const theme = updateThemePalette(defaultCampaignTheme, paletteOverrides);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="**/donate">
                        <CheckoutPopup />
                    </Route>
                    <Route>
                        <>
                            <Confetti />
                            {(heroSlides.length > 0 ||
                                mobileHeroSlides?.length > 0 ||
                                isVideoEnabled) && (
                                <HeroSlider
                                    slides={heroSlides}
                                    video={video}
                                    isVideoEnabled={isVideoEnabled}
                                    mobileSlides={mobileHeroSlides}
                                    interval={interval}
                                    isMobileImagesEnabled={
                                        isMobileImagesEnabled
                                    }
                                />
                            )}
                            {isLayerItem && (
                                <ItemDetailsBar
                                    name={name}
                                    avatarSrc={pageLayerItem.logo}
                                    currency={pageLayerItem.currency}
                                    goalAmount={Math.round(
                                        Number(pageLayerItem.goal),
                                    )}
                                    bonusGoalAmount={Math.round(
                                        Number(pageLayerItem?.bonusGoal),
                                    )}
                                    hasEnteredBonusGoal={
                                        pageLayerItem?.statistics
                                            ?.hasEnteredBonusGoal
                                    }
                                    amountRaised={layerItemRaisedAmount}
                                    donorsCount={Number(
                                        pageLayerItem.statistics
                                            ? pageLayerItem.statistics
                                                  .donorsCount
                                            : pageLayerItem.donorsCount,
                                    )}
                                />
                            )}
                            {showFirstTagline && (
                                <PageCenterText
                                    className="tagline-1"
                                    alignment="auto"
                                    html
                                >
                                    {firstTagline}
                                </PageCenterText>
                            )}
                            <StatisticWidgets
                                handleTimerStop={handleTimerStop}
                                widgetClassName={classes.statisticWidget}
                            />
                            <SuggestedAmountsWidget />
                            <DonationWidget
                                disabled={!campaign.published}
                                multiplier={multiplier}
                                currencies={currencies}
                                handleDonateNowClick={handleDonateNowClick}
                            />
                            {showShortDescription && (
                                <PageCenterText
                                    className="short-description"
                                    alignment="auto"
                                    html
                                >
                                    {shortDescription}
                                </PageCenterText>
                            )}
                            {givings.length !== 0 && useUpdatedPerkDesign ? (
                                <NewPerks
                                    givings={givings}
                                    isPublished={campaign.published}
                                    enableDonations={enableDonations}
                                />
                            ) : (
                                <OldPerks
                                    givings={givings}
                                    isPublished={campaign.published}
                                    enableDonations={enableDonations}
                                />
                            )}
                            {showAboveBodyText && (
                                <PageCenterText
                                    className={classNames(
                                        classes.textSection,
                                        'above-body-text',
                                    )}
                                    alignment="auto"
                                    html
                                >
                                    {aboveBodyText}
                                </PageCenterText>
                            )}
                            <HeroBanner
                                className={classNames(
                                    'body-banner',
                                    classes.bodyBanner,
                                )}
                                src={getBodyImageSrc()}
                            />
                            {showSecondTagline && (
                                <PageCenterText
                                    className={classNames(
                                        classes.textSection,
                                        'tagline-2',
                                    )}
                                    alignment="auto"
                                    html
                                >
                                    {secondTagline}
                                </PageCenterText>
                            )}

                            {!hideRdpMenu && (
                                <RecentDonationPanel
                                    multiplier={multiplier}
                                    campaignId={campaign.id}
                                    tabs={rdpTabs}
                                />
                            )}
                        </>
                    </Route>
                </Switch>
            </BrowserRouter>
            {campaignSlug && shareButtons && (
                <SharePanel
                    campUrl={window.location.href.split(/#|\?/)[0]}
                    title={metaTitle}
                    text={metaDescription}
                />
            )}
        </ThemeProvider>
    );
}

ClassicCampaignPage.propTypes = {
    campaign: PropTypes.object.isRequired,
    handleDonateNowClick: PropTypes.func,
    handleTimerStop: PropTypes.func.isRequired,
    layerItemRaisedAmount: PropTypes.number,
    isMobile: PropTypes.bool,
    rdpTabs: PropTypes.array,
};

export default ClassicCampaignPage;
