const LANGUAGES_INFO = Object.freeze({
    ar: {
        id: 'ar',
        i18n: 'language.arabic',
        name: 'Arabic',
        localName: 'عربي',
        shortname: 'Ar',
        dir: 'rtl',
        locale: 'ar_AR',
    },
    de: {
        id: 'de',
        i18n: 'language.german',
        name: 'German',
        localName: 'Deutsch',
        shortname: 'De',
        locale: 'de_DE',
        flagCode: 'DE',
    },
    en: {
        id: 'en',
        i18n: 'language.english',
        name: 'English',
        localName: 'English',
        shortname: 'En',
        locale: 'en_US',
        flagCode: 'US',
    },
    es: {
        id: 'es',
        i18n: 'language.spanish',
        name: 'Spanish',
        localName: 'Español',
        shortname: 'Es',
        locale: 'es_LA',
        flagCode: 'ES',
    },
    fr: {
        id: 'fr',
        i18n: 'language.french',
        name: 'French',
        localName: 'Français',
        shortname: 'Fr',
        locale: 'fr_FR',
        flagCode: 'FR',
    },
    he: {
        id: 'he',
        i18n: 'language.hebrew',
        name: 'Hebrew',
        localName: 'עברית',
        shortname: 'עב',
        dir: 'rtl',
        locale: 'he_IL',
        flagCode: 'IL',
    },
    nl: {
        id: 'nl',
        i18n: 'language.dutch',
        name: 'Dutch',
        localName: 'Nederlands',
        shortname: 'Nl',
        locale: 'nl_NL',
        flagCode: 'NL',
    },
    pt: {
        id: 'pt',
        i18n: 'language.portuguese',
        name: 'Portuguese',
        localName: 'Português',
        shortname: 'Pt',
        locale: 'pt_BR',
        flagCode: 'BR',
    },
    ru: {
        id: 'ru',
        i18n: 'language.russian',
        name: 'Russian',
        localName: 'Русский',
        shortname: 'Ru',
        locale: 'ru_RU',
        flagCode: 'RU',
    },
    yi: {
        id: 'yi',
        i18n: 'language.yiddish',
        name: 'Yiddish',
        localName: 'יידיש',
        shortname: 'Yi',
        dir: 'rtl',
        locale: 'yi_UA',
    },
});

const LANGUAGES = Object.freeze(Object.values(LANGUAGES_INFO));

const PEACH_LANGUAGES = Object.freeze({
    he: 'he',
    en: 'en',
    fr: 'fr',
    ru: 'ru',
    es: 'es',
    de: 'de',
    ar: 'ar',
});

module.exports = {
    LANGUAGES_INFO,
    LANGUAGES,
    PEACH_LANGUAGES,
};
