import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        padding: 0,
        margin: 0,
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
}));

function PageWrapper(props) {
    const { className, children } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>{children}</div>
    );
}

PageWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default PageWrapper;
