import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IdealBankElement, useElements } from '@stripe/react-stripe-js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStripeContext } from './StripeProvider';
import withController from '../../hocs/withController';
import withFormInput from '../../hocs/withFormInput';
import FormGroup from '../../components/FormGroup/FormGroup';
import focusHandler from '../../../../helpers/focusHandler';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(() => ({
    fields: {
        marginBottom: '25px',
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    input: {
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            padding: '1px 0',
            marginLeft: '-0.5px',
            marginRight: '-1px',
            width: '101%',
        },
        'html[dir="rtl"] & > div': {
            padding: '1px 0',
        },
        '@media (max-width: 600px)': {
            '& > div': {
                padding: 0,
            },
        },
    },
}));

const BankSelector = withController(withFormInput(IdealBankElement));

function IdealBankSelector({ control, dirtyFields, errors }) {
    const classes = useStyles();
    const elements = useElements();
    const { palette, spacing } = useTheme();
    const IDEAL_ELEMENT_OPTIONS = {
        style: {
            base: {
                padding: `${spacing(2)}px ${spacing(1.5)}px`,
                color: palette.text.primary,
                fontSize: '1rem',
                '::placeholder': {
                    color: changeColorAlpha(palette.text.primary, 0.85),
                },
            },
        },
    };
    useEffect(() => {
        if (!elements) {
            return;
        }
        function resizeHandler() {
            const idealElement = elements.getElement(IdealBankElement);
            if (window.innerWidth <= 600) {
                idealElement.update({
                    style: { base: { padding: '12px 10px', fontSize: '13px' } },
                });
            } else {
                idealElement.update({
                    style: { base: { padding: '16px 12px', fontSize: '16px' } },
                });
            }
        }
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [elements]);

    return (
        <FormGroup inline className={classes.fields}>
            <BankSelector
                options={IDEAL_ELEMENT_OPTIONS}
                className={classes.input}
                name="idealBank"
                id="idealBank"
                type="dropdown"
                width="100%"
                required
                control={control}
                dirtyFields={dirtyFields}
                errors={errors}
                defaultValue={{ value: '' }}
                onFocus={focusHandler('idealBank')}
                rules={{ required: true }}
            />
        </FormGroup>
    );
}

IdealBankSelector.propTypes = {
    control: PropTypes.object.isRequired,
    dirtyFields: PropTypes.object,
    errors: PropTypes.object,
};

export default withStripeContext(IdealBankSelector);
