import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        marginBottom: 25,
        width: ({ width }) => width,

        '@media (max-width: 600px)': {
            marginBottom: 20,
        },
    },
    inlineRoot: {
        marginBottom: 0,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
    },
    inline: {
        flexDirection: 'row',
        '& > *': {
            marginInlineEnd: props => {
                const gap = props.gap ? props.gap : '0px';
                if (typeof gap === 'number') {
                    return `${gap}px !important`;
                }
                return `${gap} !important`;
            },
            '&:last-child': {
                marginInlineEnd: '0 !important',
            },
        },
    },
    wrap: {
        flexWrap: 'wrap',
    },
    alignCenter: {
        alignItems: 'center',
    },
    label: {
        marginBottom: 10,
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        whiteSpace: 'nowrap',

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(11),
            fontWeight: 300,
            marginBottom: 5,
        },
    },
}));

function FormGroup(props) {
    const {
        children,
        className,
        inline = false,
        wrap = false,
        gap = 'inherit',
        label = '',
        flexStart = false,
        width = 'auto',
    } = props;
    const classes = useStyles({ gap, width });

    return (
        <div
            className={classNames(
                classes.root,
                { [classes.inlineRoot]: inline },
                className,
            )}
        >
            {label && <div className={classes.label}>{label}</div>}
            <div
                className={classNames(classes.main, {
                    [classes.inline]: inline,
                    [classes.wrap]: wrap,
                    [classes.alignCenter]: inline && !flexStart,
                })}
            >
                {children}
            </div>
        </div>
    );
}

FormGroup.propTypes = {
    children: PropTypes.node,
    inline: PropTypes.bool,
    wrap: PropTypes.bool,
    flexStart: PropTypes.bool,
    label: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

export default FormGroup;
