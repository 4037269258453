const { GATEWAYS } = require('../constants');
const _ = require('lodash');

const pageNameKey = Symbol('pageName');
const currencyKey = Symbol('currency');

function campaignTitle(campaign) {
    const defaultPage = campaign?.defaultPage;
    return (
        _.get(campaign, 'name') ||
        _.get(
            campaign,
            `landing.locales.${defaultPage}.title`,
            _.get(
                campaign,
                `landing.locales.${defaultPage}.name`,
                _.get(campaign, `locales.${defaultPage}.pageName`, ''),
            ),
        )
    );
}

function campaignOrganization(campaign, page = campaign.defaultPage) {
    return _.get(campaign, `locales.${page}.name`, campaignTitle(campaign));
}

function campaignGatewayConfigs(campaign) {
    return _.flatMap(campaign.payments, (pageValues, pageName) =>
        _.flatMap(
            _.pick(pageValues, pageValues.currencies),
            (gateways, currency) =>
                gateways.map(gateway => ({
                    ...gateway,
                    [pageNameKey]: pageName,
                    [currencyKey]: currency,
                })),
        ),
    );
}

function uniqCampaignGateways(campaign) {
    const gatewaysOrder = _.keys(GATEWAYS);
    const uniqGateways = _.uniq(
        _.map(campaignGatewayConfigs(campaign), ({ gateway }) => gateway),
    );
    return _.sortBy(uniqGateways, gateway => gatewaysOrder.indexOf(gateway));
}

function paymentGatewayLabel(gateway) {
    return GATEWAYS[gateway]?.label;
}

function getStripeCommissions(campaign) {
    const stripeCommissions = campaignGatewayConfigs(campaign)
        .filter(({ gateway }) => gateway === 'stripe')
        .map(gatewayConfig => {
            const pageName = gatewayConfig[pageNameKey];
            const currency = gatewayConfig[currencyKey];
            return {
                pageName,
                currency,
                commission: Number(gatewayConfig?.commission),
                subscriptionCommission: Number(
                    gatewayConfig?.subscriptionCommission,
                ),
            };
        });
    return _.groupBy(stripeCommissions, 'pageName');
}

module.exports = {
    campaignGatewayConfigs,
    campaignTitle,
    campaignOrganization,
    paymentGatewayLabel,
    getStripeCommissions,
    uniqCampaignGateways,
};
