const { roundAmount } = require('../helpers');

const donationAmount = ({
    amount,
    totalAmount,
    months = 1,
    countLayerItems = 1,
    tip = 0,
    fee = 0,
    isLimitedRecurring = false,
    isInstallments = false,
    isUnlimitedRecurring = false,
    paymentGateway,
}) => {
    const amountObject = new DonationAmount(
        amount,
        totalAmount,
        months,
        countLayerItems,
        tip,
        fee,
        isLimitedRecurring,
        isInstallments,
        isUnlimitedRecurring,
        paymentGateway,
    );

    return amountObject;
};

class DonationAmount {
    constructor(
        amount,
        totalAmount,
        months = 1,
        countLayerItems = 1,
        tip = 0,
        fee = 0,
        isLimitedRecurring = false,
        isInstallments = false,
        isUnlimitedRecurring = false,
        paymentGateway,
    ) {
        this.amount = Number(amount);
        this.setTotalAmount(totalAmount ? totalAmount : this.amount);
        this.setFee(fee);
        this.setTip(tip);
        this.months = Math.max(1, months);
        this.countLayerItems = Math.max(1, countLayerItems);
        this.isLimitedRecurring = isLimitedRecurring;
        this.isInstallments = isInstallments;
        this.isUnlimitedRecurring = isUnlimitedRecurring;
        this.paymentGateway = paymentGateway;
    }

    setFee(fee) {
        if (fee < 0 || fee > 100) {
            throw new Error('Fee percent cannot be more than 100');
        }
        this.fee = fee;
        return this;
    }

    setTip(tip) {
        if (tip < 0 || tip > 100) {
            throw new Error('tip percent cannot be more than 100');
        }
        this.tip = tip;
        return this;
    }

    setTotalAmount(totalAmount) {
        this.totalAmount = Number(totalAmount);
        return this;
    }

    setMonths(months) {
        this.months = Math.max(1, months);
        return this;
    }

    setCountLayerItems(countLayerItems) {
        this.countLayerItems = Math.max(1, countLayerItems);
        return this;
    }

    setIsLimitedRecurring(isLimitedRecurring) {
        this.setIsLimitedRecurring = isLimitedRecurring;
        return this;
    }

    setUnlimitedRecurring(isUnlimitedRecurring) {
        this.isUnlimitedRecurring = isUnlimitedRecurring;
        return this;
    }

    get tipAmount() {
        return roundAmount(this.totalAmount * (this.tip / 100));
    }

    get withTip() {
        return roundAmount(this.totalAmount + this.tipAmount);
    }

    get tipPercentage() {
        return this.tip;
    }

    get monthFactor() {
        return this.isLimitedRecurring || this.isUnlimitedRecurring
            ? this.months
            : 1;
    }

    get perMonthWithTip() {
        //Leaving this in for legacy donations, ought to remove later
        //Stripe Installments is in fact a subscription!
        //Banquest installments is in fact also a subscription (recurring schedule)!
        if (
            this.isInstallments &&
            ['stripe', 'banquest'].includes(this.paymentGateway)
        ) {
            return roundAmount(
                (this.totalAmount + this.tipAmount) / this.months,
            );
        }
        return roundAmount(this.totalAmount + this.tipAmount);
    }

    get total() {
        const totalAmount = roundAmount(this.amount + this.tipAmount);

        if (this.isLimitedRecurring || this.isUnlimitedRecurring) {
            return roundAmount(totalAmount * this.months);
        }

        return totalAmount;
    }
}

module.exports = donationAmount;
