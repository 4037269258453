import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const areLinesClamped = ref => {
    if (ref) {
        const scrollHeight = ref.current?.scrollHeight;
        const clientHeight = ref.current?.clientHeight;
        return scrollHeight > clientHeight + 1; // due to a 1 pixel offset with the scroll height
    }
    return false;
};

export const PerkContent = ({
    addEndGutter,
    clamp = false,
    classes,
    className,
    description,
    disabled,
    imageBorderColor,
    handleOpenPopup,
    hasAmount,
    hasDescription,
    hasName,
    logo,
    name,
    noLogo,
    renderPerkAmountLabel,
    showAmountLabel = true,
    showPerkPrice = false,
    onLinesClamped = () => {},
}) => {
    const descRef = useRef();
    const nameRef = useRef();

    const centerContent = noLogo || (!hasName && !hasAmount);
    const largeLogo =
        logo &&
        ((!hasName && !hasAmount) ||
            (hasName && !hasAmount && !hasDescription) ||
            (!hasName && hasAmount && !hasDescription));
    const descriptionOnly = noLogo && !hasName && hasDescription && !hasAmount;
    const largeName =
        (noLogo && hasName && !hasDescription) ||
        (logo && hasName && !hasDescription && !hasAmount);
    const largeAmount = hasAmount && (noLogo || !hasName);

    useEffect(() => {
        if (areLinesClamped(descRef) || areLinesClamped(nameRef)) {
            onLinesClamped();
        }
    }, [descRef, nameRef]);

    return (
        <div
            className={classNames(
                classes.content,
                {
                    [classes.disabled]: disabled,
                    [classes.contentEndGutter]: addEndGutter,
                    [classes.centerContent]: centerContent,
                },
                className,
                'perk-content',
            )}
            onClick={handleOpenPopup}
        >
            <div
                className={classNames(classes.contentSection, {
                    [classes.columnContentSection]: largeLogo,
                })}
            >
                {logo && (
                    <figure
                        className={classNames(classes.imagePlaceholder, {
                            [classes.largeLogo]: largeLogo,
                        })}
                    >
                        <img
                            src={logo}
                            className={classes.logo}
                            style={{ border: `4px solid ${imageBorderColor}` }}
                        />
                    </figure>
                )}
                <div className={classes.contentTop}>
                    {hasName && (
                        <div
                            className={classNames(classes.text, classes.title, {
                                [classes.lineClamp]: clamp,
                                [classes.largeTitle]: largeName,
                            })}
                            ref={nameRef}
                        >
                            {name}
                        </div>
                    )}
                    {hasAmount && (
                        <>
                            {showAmountLabel && (
                                <div>
                                    {!largeAmount && (
                                        <div>
                                            <FormattedMessage id="Perks.donate" />
                                            :
                                        </div>
                                    )}
                                    <div
                                        className={classNames(classes.amount, {
                                            [classes.largeAmount]: largeAmount,
                                        })}
                                    >
                                        {renderPerkAmountLabel('total')}
                                    </div>
                                </div>
                            )}
                            {showPerkPrice && (
                                <div
                                    className={classNames(classes.amount, {
                                        [classes.largeAmount]: largeAmount,
                                    })}
                                >
                                    {renderPerkAmountLabel()}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {hasDescription && (
                <div
                    className={classNames(classes.text, classes.description, {
                        [classes.lineClamp]: clamp,
                        [classes.largeDescription]: descriptionOnly,
                    })}
                    ref={descRef}
                >
                    {description}
                </div>
            )}
        </div>
    );
};

PerkContent.propTypes = {
    addEndGutter: PropTypes.bool,
    centerContent: PropTypes.bool,
    clamp: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    description: PropTypes.string,
    descriptionOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    imageBorderColor: PropTypes.string,
    handleOpenPopup: PropTypes.func,
    hasAmount: PropTypes.bool,
    hasDescription: PropTypes.bool,
    hasName: PropTypes.bool,
    largeAmount: PropTypes.bool,
    largeName: PropTypes.bool,
    largeLogo: PropTypes.bool,
    logo: PropTypes.string,
    name: PropTypes.string,
    noLogo: PropTypes.bool,
    onLinesClamped: PropTypes.func,
    renderPerkAmountLabel: PropTypes.func,
    showAmountLabel: PropTypes.bool,
    showPerkPrice: PropTypes.bool,
};
