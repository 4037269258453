import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CashAppLogo from 'client/components/CampaignPage/components/CashAppLogo/CashAppLogo';
import { withStripeContext } from './StripeProvider';
import {
    paymentFailed,
    paymentIterationComplete,
    setGatewayRedirect,
    startPaymentProcess,
} from '../checkout/checkoutSlice';
import focusElement from 'client/helpers/focusElement';
import api from 'client/services/api';

const STATUS_WATCH_DELAY = 1500;

function isMobile() {
    const screenSize = Math.min(window.screen.width, window.screen.height);
    if (screenSize > 450) {
        // if the smaller screen dimension is greater than 450px
        // then this is either a tablet or larger device
        return false;
    }

    if (window.matchMedia) {
        return !window.matchMedia('(any-pointer: fine)').matches;
    }

    const isTouchSupported =
        'ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch) ||
        navigator.maxTouchPoints > 1 ||
        window.navigator.msMaxTouchPoints > 0;

    return isTouchSupported;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    cashAppLogo: {
        width: 148,
        height: 32,
    },

    closeButton: {
        position: 'absolute',
        top: spacing(1),
        right: spacing(1),
    },

    qrCodeRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: spacing(3),
    },

    qrCodeImage: {
        width: 200,
        height: 200,
    },

    qrCodeMessage: {
        paddingTop: spacing(2),
        color: palette.text.primary,

        '& a': {
            color: palette.text.primary,
        },
    },
}));

function CashAppQrCode({ qrCode, onCancel }) {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        if (qrCode?.expiresAt) {
            let timeoutId;
            const timer = () => {
                const secondsRemaining = Math.max(
                    Math.round(qrCode.expiresAt - Date.now() / 1000),
                    0,
                );

                setTimeLeft(secondsRemaining);

                if (secondsRemaining > 0) {
                    timeoutId = setTimeout(timer, 1000);
                }
            };

            timer();

            return () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        }
    }, [qrCode?.expiresAt]);

    if (!qrCode) {
        return null;
    }

    const cashAppLink = (
        <a href="https://cash.app/" target="_blank" rel="noreferrer">
            Cash App
        </a>
    );

    return (
        <Dialog open={Boolean(qrCode)}>
            <DialogTitle>
                <CashAppLogo className={classes.cashAppLogo} />
                <IconButton
                    className={classes.closeButton}
                    size="small"
                    onClick={onCancel}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className={classes.qrCodeRoot}>
                    <img
                        className={classes.qrCodeImage}
                        src={qrCode.imageUrlSvg}
                        alt={formatMessage(
                            {
                                id: 'DonationForm.cashApp.scan',
                                defaultMessage:
                                    "Use {cashAppLink} or your phone's camera to scan and pay.",
                            },
                            { cashAppLink: 'Cash App' },
                        )}
                    />
                    <Typography className={classes.qrCodeMessage}>
                        <FormattedMessage
                            id="DonationForm.cashApp.scan"
                            defaultMessage="Use {cashAppLink} or your phone's camera to scan and pay."
                            values={{ cashAppLink }}
                        />
                    </Typography>
                    {timeLeft <= 10 && (
                        <Typography className={classes.qrCodeMessage}>
                            <FormattedMessage
                                id="DonationForm.cashApp.refresh"
                                defaultMessage="QR code will refresh in {timeLeft} seconds."
                                values={{ timeLeft }}
                            />
                        </Typography>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
CashAppQrCode.propTypes = {
    qrCode: PropTypes.object,
    onCancel: PropTypes.func,
};

function StripeCashAppButton({
    makeDonation,
    renderButton,
    onDonateFailed,
    onDonateSuccess,
}) {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { getValues, handleSubmit, formState } = useFormContext();

    const stripe = useStripe();
    const [qrCode, setQrCode] = useState(null);
    const watchRef = useRef({
        isMounted: false,
        isProcessing: false,
        statusTimeoutId: null,
        qrCodeTimeoutId: null,
    });

    const setQrCodeData = qrCodeData => {
        let qrCode = null;
        if (qrCodeData) {
            qrCode = {
                imageUrlSvg: qrCodeData.image_url_svg,
                imageUrlPng: qrCodeData.image_url_png,
                expiresAt: qrCodeData.expires_at,
            };
        }

        setQrCode(qrCode);

        return qrCode;
    };

    const checkIsValid = useCallback(
        async event => {
            event.preventDefault();
            return new Promise((resolve, reject) =>
                handleSubmit(() => resolve(true), reject)(),
            ).catch(errors => {
                if (errors?.amount) {
                    setTimeout(() => focusElement('[name="amount"]'), 25);
                }
                return false;
            });
        },
        [formState.isValid],
    );

    const clearWatch = () => {
        if (watchRef.current?.statusTimeoutId) {
            clearTimeout(watchRef.current.statusTimeoutId);
            watchRef.current.statusTimeoutId = null;
        }
        if (watchRef.current?.qrCodeTimeoutId) {
            clearTimeout(watchRef.current.qrCodeTimeoutId);
            watchRef.current.qrCodeTimeoutId = null;
        }

        setQrCodeData(null);

        watchRef.current.isProcessing = false;
    };

    const watchPaymentIntent = (payload, qrCode) => {
        const donationTransactionId =
            payload.data.donations[0].donationTransactionId;
        const clientSecret = payload.data.clientSecret;

        let lastQrCode = qrCode;

        const handleWatchSuccess = () => {
            clearWatch();

            dispatch(paymentIterationComplete());
            if (typeof onDonateSuccess === 'function') {
                onDonateSuccess(payload);
            }
        };

        const handleWatchFailure = error => {
            clearWatch();

            handleError(error);
        };

        const watchStatus = async () => {
            if (!watchRef.current?.isMounted) {
                return;
            }
            watchRef.current.statusTimeoutId = null;

            const result = await api.donation.getTransactionStatus(
                donationTransactionId,
            );
            if (result?.status === 'success') {
                handleWatchSuccess();
            } else if (result?.status === 'failure') {
                handleWatchFailure(
                    new Error('DonationForm.error.paymentFailed'),
                );
            } else {
                watchRef.current.statusTimeoutId = setTimeout(
                    watchStatus,
                    STATUS_WATCH_DELAY,
                );
            }
        };

        const checkPaymentIntent = async () => {
            const { paymentIntent, error } = await stripe.retrievePaymentIntent(
                clientSecret,
            );

            if (error) {
                throw new Error(error.message);
            }
            if (paymentIntent.status === 'requires_payment_method') {
                throw new Error('DonationForm.error.paymentFailed');
            }

            if (paymentIntent.status === 'succeeded') {
                return true;
            }

            const nextAction =
                paymentIntent.next_action
                    .cashapp_handle_redirect_or_display_qr_code;

            lastQrCode = setQrCodeData(nextAction.qr_code);

            return false;
        };

        const qrCodeDelay = () => lastQrCode.expiresAt * 1000 - Date.now();

        const watchQrCode = async () => {
            if (!watchRef.current?.isMounted) {
                return;
            }
            watchRef.current.qrCodeTimeoutId = null;

            try {
                const isSuccess = await checkPaymentIntent();

                if (isSuccess) {
                    handleWatchSuccess();
                } else {
                    watchRef.current.qrCodeTimeoutId = setTimeout(
                        watchQrCode,
                        qrCodeDelay(),
                    );
                }
            } catch (error) {
                handleWatchFailure(error);
            }
        };

        watchRef.current.qrCodeTimeoutId = setTimeout(
            watchQrCode,
            qrCodeDelay(),
        );
        watchRef.current.statusTimeoutId = setTimeout(
            watchStatus,
            STATUS_WATCH_DELAY,
        );
    };

    const handleError = error => {
        if (typeof onDonateFailed === 'function') {
            onDonateFailed(error);
        }
        dispatch(
            paymentFailed(
                formatMessage({
                    id: error.message,
                    defaultMessage: error.message,
                }),
            ),
        );
    };

    const handleCancel = () => {
        clearWatch();
        dispatch(paymentIterationComplete());
    };

    const handleClick = async event => {
        const isValid = await checkIsValid(event);
        if (
            !isValid ||
            !watchRef.current?.isMounted ||
            watchRef.current?.isProcessing
        ) {
            return;
        }
        watchRef.current.isProcessing = true;

        const { layerItems, ...donationData } = getValues();

        const billingDetails = {
            name: [donationData.firstName, donationData.lastName]
                .filter(Boolean)
                .join(' '),
            email: donationData.email,
            phone: donationData.phone,
            //TODO: Add address to stripe
        };
        if (billingDetails.phone === '') {
            delete billingDetails.phone;
        }
        const paymentMethodResult = await stripe.createPaymentMethod({
            type: 'cashapp',
            billing_details: billingDetails,
        });
        if (paymentMethodResult.error) {
            return handleError(new Error(paymentMethodResult.error.message));
        }

        const response = await makeDonation(
            donationData,
            layerItems,
            paymentMethodResult.paymentMethod,
        );

        if (!response?.data?.clientSecret) {
            return handleError(new Error('DonationForm.error.paymentFailed'));
        }

        dispatch(startPaymentProcess(1));
        const cashappResult = await stripe.confirmCashappPayment(
            response.data.clientSecret,
            {
                payment_method: paymentMethodResult.paymentMethod.id,
                return_url: `${window.location.href}${response.data.redirectQuery}`,
            },
            {
                handleActions: false,
            },
        );
        if (cashappResult.error) {
            return handleError(new Error(cashappResult.error.message));
        }

        if (cashappResult.paymentIntent.status === 'requires_action') {
            const nextAction =
                cashappResult.paymentIntent.next_action
                    .cashapp_handle_redirect_or_display_qr_code;

            if (isMobile()) {
                dispatch(setGatewayRedirect());
                setTimeout(() => {
                    watchRef.current.isProcessing = false;
                    window.location = nextAction.mobile_auth_url;
                }, 1);
            } else {
                const qrCode = setQrCodeData(nextAction.qr_code);
                watchPaymentIntent(
                    response,
                    qrCode,
                    cashappResult.paymentIntent,
                );
            }
        } else if (typeof onDonateSuccess === 'function') {
            onDonateSuccess(response);
            watchRef.current.isProcessing = false;
        }
    };

    useEffect(() => {
        watchRef.current = {
            isMounted: true,
            isProcessing: false,
            statusTimeoutId: null,
            qrCodeTimeoutId: null,
        };

        return () => {
            if (watchRef.current?.statusTimeoutId) {
                clearTimeout(watchRef.current.statusTimeoutId);
            }
            if (watchRef.current?.qrCodeTimeoutId) {
                clearTimeout(watchRef.current.qrCodeTimeoutId);
            }
            watchRef.current = null;
        };
    }, []);

    return (
        <>
            <CashAppQrCode qrCode={qrCode} onCancel={handleCancel} />
            {renderButton(handleClick)}
        </>
    );
}

StripeCashAppButton.propTypes = {
    makeDonation: PropTypes.func,
    renderButton: PropTypes.func,
    onDonateFailed: PropTypes.func,
    onDonateSuccess: PropTypes.func,
};

export default withStripeContext(StripeCashAppButton);
