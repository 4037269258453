import { makeStyles } from '@material-ui/styles';
import { currencySign } from 'common/helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDonationFlasher } from './donationFlasherSlice';
import cls from 'classnames';

/**
 * in seconds
 * @type {number}
 */
const FADE_TIME = 1;

const useStyles = makeStyles(() => ({
    root: {
        position: 'sticky',
    },
    rdpNoticeContainer: {
        fontSize: '13px',
        display: 'table',
        minHeight: '50px',
        textAlign: 'center',
        borderRadius: '4px',
        opacity: '0.9',
        filter: 'alpha(opacity=90)',
        color: 'black',
        backgroundColor: 'orange',
        '-webkit-animation': `$alternatecolors 0.5s infinite alternate`,
        animation: `$alternatecolors 0.5s infinite alternate`,
        width: '100%',
        zIndex: '1',
        top: '0',
    },
    rdpNotice: {
        margin: '15px',
        fontSize: '16px',
        color: 'black',
        height: 'auto',
        textAlign: 'center',
    },
    fadeIn: {
        opacity: 1,
        visibility: 'visible',
        transition: `opacity ${FADE_TIME}s`,
    },
    fadeOut: {
        opacity: 0,
        visibility: 'collapse',
        minWidth: '100%',
        transition: `opacity ${FADE_TIME}s, visibility ${FADE_TIME}s`,
    },
    '@keyframes alternatecolors': {
        '100%': { backgroundColor: 'lightgray' },
    },
}));

function DonationFlasher() {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { isVisible, visibleDonations } = useSelector(selectDonationFlasher);

    return (
        <div
            className={cls(
                classes.root,
                classes.rdpNoticeContainer,
                isVisible ? classes.fadeIn : classes.fadeOut,
                'rdp-flasher',
            )}
        >
            <div className={classes.rdpNotice}>
                <FormattedMessage
                    id="Flasher.newDonation"
                    defaultMessage="New {donationsCount, plural, one {Donation} other {Donations}} Received!"
                    values={{
                        donationsCount: visibleDonations.length,
                    }}
                />
                <br />
                {visibleDonations.map((donation, index) => (
                    <b key={donation.id}>
                        {donation.isAnonymous
                            ? formatMessage({
                                  id: 'user.anon',
                                  defaultMessage: 'Anonymous',
                              })
                            : donation.name}
                        :{' '}
                        {donation.isAmountHidden
                            ? formatMessage({
                                  id: 'DonateCard.hiddenAmount',
                                  defaultMessage: 'Donated',
                              })
                            : `${currencySign(donation.currency)} ${Math.round(
                                  donation.amount *
                                      donation.multiplier *
                                      donation.recurringMonths,
                              )}`}
                        {visibleDonations.length !== index + 1 && ', '}
                    </b>
                ))}
            </div>
        </div>
    );
}

DonationFlasher.propTypes = {
    delay: PropTypes.number,
    showTime: PropTypes.number,
};

export default DonationFlasher;
