import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '../Avatar/Avatar';
import { scrollToElement } from '../../hooks/scrollTo';
import { ReactComponent as LinkIcon } from './LinkIcon.svg';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { currencySign } from 'common/helpers';
import Text from '../Text/Text';
import getLayerLabel from 'client/helpers/campaign/getLayerLabel';
import classNames from 'classnames';
import Progress from 'client/componentLibrary/Progress';
import { getProgressGradation } from 'common/helpers/layerItemProgressGradation';
import Card from 'client/componentLibrary/Card';
import Button from 'client/componentLibrary/Button';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        padding: `${spacing(4)}px ${spacing(5)}px`,
        position: 'relative',
        fontFamily: typography.fontFamily,

        '@media (max-width: 600px)': {
            padding: `${spacing(4)}px ${spacing(2)}px ${spacing(3)}px`,
        },
    },
    bonusGoalBanner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: '16%',
        padding: '0 0.5em',
        letterSpacing: '0.05rem',
        backgroundColor: palette.primary.main,
        borderRadius: `0 0 ${spacing(0.5)}px ${spacing(0.5)}px`,
        color: palette.primary.contrastText,
        fontSize: '1.125rem',
        fontWeight: 600,
        '@media (max-width: 600px)': {
            left: 'auto',
            right: '50%',
            transform: 'translate(50%)',
            fontSize: typography.pxToRem(14),
            padding: `0 ${spacing(0.5)}px`,
        },
        '[dir=rtl] &': {
            right: 'auto',
            left: '16%',
            '@media (max-width: 600px)': {
                right: 'auto',
                left: '50%',
                transform: 'translate(-50%)',
            },
        },
    },
    bonusRound: {
        border: `1.5px solid ${palette.primary.main} !important`,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(4),
        overflow: 'hidden',
    },
    avatar: {
        marginRight: spacing(2),
        flexShrink: 0,

        'html[dir=rtl] &': {
            marginRight: 0,
            marginLeft: spacing(2),
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    name: {
        color: palette.text.primary,
        fontWeight: 'bold',
        fontSize: '1.75rem',

        '@media (max-width: 600px)': {
            fontSize: '1.25rem',
        },
    },
    description: {
        color: palette.text.primary,
        fontWeight: 400,
        fontSize: '1rem',
        wordBreak: 'break-word',

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(14),
        },
    },
    info: {
        margin: `${spacing(2)}px 0`,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: `${spacing(2)}px 0 0`,
        },
    },
    counts: {
        marginTop: spacing(1),
        textAlign: 'center',
        display: 'flex',
        maxWidth: '100%',
        gap: spacing(2),
        justifyContent: 'flex-end',
        '@media (max-width: 600px)': {
            width: '100%',
            gap: spacing(1),
            overflowX: 'scroll',
            padding: `${spacing(1)}px 0`,
            margin: `${spacing(1)}px 0`,
        },
    },
    count: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: palette.text.primary,
        fontSize: typography.pxToRem(18),
        lineHeight: 1,
        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(15),
        },
    },
    countAmount: {
        fontWeight: 600,
        fontSize: typography.pxToRem(20),
        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(16),
        },
    },
    amounts: {
        display: 'flex',
        flexDirection: 'column',
        color: palette.text.primary,
        marginTop: spacing(1),
        '& svg': {
            marginRight: 5,
            '@media (max-width: 600px)': {
                marginTop: spacing(0.5),
            },
        },
        '& strong': {
            color: palette.text.primary,
            fontSize: '1.5rem',
            marginRight: spacing(1),

            '@media (max-width: 600px)': {
                fontSize: '1.25rem',
                flexDirection: 'column',
            },
        },
        'html[dir=rtl] &': {
            '& svg': {
                marginRight: 0,
                marginLeft: 5,
            },
            '& strong': {
                marginRight: 0,
                marginLeft: spacing(1),
            },
        },
    },
    amountsFirstRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: spacing(0.5),
    },
    originalGoalText: {
        fontWeight: 600,
    },
    close: {
        position: 'absolute',
        left: spacing(1),
        top: spacing(1),
        cursor: 'pointer',
        padding: spacing(1),
        margin: 0,
        background: 'none',
        border: 0,

        'html[dir=rtl] &': {
            left: 'auto',
            right: spacing(1),
        },

        '&:hover, &:focus': {
            filter: 'brightness(0)',
        },
        '& svg': {
            display: 'block',
            width: 12,
            height: 12,
        },
        '@media (max-width: 600px)': {
            top: spacing(0.5),
            left: spacing(0.5),
            'html[dir=rtl] &': {
                left: 'auto',
                right: spacing(0.5),
            },
        },
    },
    viewLink: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'absolute',
        color: palette.text.primary,
        top: spacing(2),
        right: spacing(3),
        cursor: 'pointer',

        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
        '& svg': {
            fill: palette.text.primary,
            marginLeft: spacing(1),
        },
        'html[dir=rtl] &': {
            right: 'auto',
            left: spacing(3),
            '& svg': {
                marginLeft: 0,
                marginRight: spacing(1),
            },
        },
        '@media (max-width: 600px)': {
            top: spacing(1.5),
            right: spacing(1.5),
            fontSize: '0.9rem',
        },
    },
    goalText: {
        '@media (max-width: 600px)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    itemAmount: {
        color: palette.text.primary,
        fontSize: '1.75rem',
        '@media (max-width: 600px)': {
            fontSize: '1.125rem',
        },
    },
    itemProgress: {
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    loadingMsg: {
        textAlign: 'center',
        padding: '1em',
        color: palette.text.primary,
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            flexWrap: 'nowrap',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    viewBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing(1),
        '@media (max-width: 600px)': {
            justifyContent: 'space-around',
            gap: spacing(0.5),
            flexWrap: 'wrap',
            margin: `${spacing(2)}px 0`,
        },
    },
}));

function ModernItemCard(props) {
    const {
        className,
        avatarSrc,
        name,
        description,
        donorsCount,
        amount,
        onView,
        onClose,
        goalAmount = 0,
        currency = 'USD',
        isDisableAutoscroll,
        childLayers,
        baseGoalPercent = Math.round((amount / (goalAmount || 1)) * 100),
        hasEnteredBonusGoal = false,
        bonusGoalAmount = 0,
        selectedChildLayer,
        childLayerButtonClick,
        onDonate,
        allowDonations,
        donorsClick,
    } = props;
    const { locale: lang } = useIntl();
    const classes = useStyles({ hasEnteredBonusGoal });
    const cardRef = useRef();

    const totalGoalAmount =
        Math.round(
            hasEnteredBonusGoal ? goalAmount + bonusGoalAmount : goalAmount,
        ) || 1;

    const { goalPercent, bonusGoalPercent } = getProgressGradation(
        amount,
        goalAmount,
        bonusGoalAmount,
        hasEnteredBonusGoal,
    );
    const goals = [
        {
            percent: goalPercent,
            color: 'secondary',
        },
        {
            percent: bonusGoalPercent,
            color: 'primary',
        },
    ];

    useEffect(() => {
        if (cardRef && cardRef.current && !isDisableAutoscroll) {
            scrollToElement(cardRef.current);
        }
    }, []);

    return (
        <Card
            className={classNames(
                classes.root,
                { [classes.bonusRound]: hasEnteredBonusGoal },
                className,
            )}
            ref={cardRef}
        >
            {hasEnteredBonusGoal && (
                <div className={classes.bonusGoalBanner}>
                    <FormattedMessage
                        id="common.bonusGoal"
                        defaultMessage="Bonus Goal!"
                    />
                </div>
            )}
            <div className={classes.header}>
                <Avatar size={90} className={classes.avatar}>
                    <img
                        src={
                            avatarSrc
                                ? avatarSrc
                                : 'https://via.placeholder.com/40x40'
                        }
                        alt=""
                    />
                </Avatar>
                <div>
                    <div className={classes.name}>{name}</div>
                    {description && (
                        <div className={classes.description}>{description}</div>
                    )}
                </div>
            </div>
            {goalAmount > 0 && !isNaN(baseGoalPercent) && (
                <Progress
                    goals={goals}
                    size={12}
                    showLabel
                    label={`${baseGoalPercent}%`}
                />
            )}
            {!isNaN(amount) ? (
                <div className={classes.info}>
                    <div className={classes.amounts}>
                        <div className={classes.amountsFirstRow}>
                            <strong>
                                <Text className={classes.itemAmount}>
                                    {currencySign(currency)}
                                    {amount.toLocaleString('en-EN')}
                                </Text>
                            </strong>
                            {goalAmount > 0 ? (
                                <div className={classes.itemProgress}>
                                    <FormattedMessage
                                        id={
                                            hasEnteredBonusGoal
                                                ? 'Amount.layerItemBonusGoal'
                                                : 'Amount.layerItemGoal'
                                        }
                                        values={{ name }}
                                    />{' '}
                                    {currencySign(currency)}
                                    {totalGoalAmount.toLocaleString('en-EN')}
                                </div>
                            ) : (
                                <span>
                                    <FormattedMessage
                                        id="Amount.raised"
                                        defaultMessage="Raised"
                                    />
                                </span>
                            )}
                        </div>
                        {hasEnteredBonusGoal && (
                            <div>
                                <Box mt={0.5} />
                                <span className={classes.originalGoalText}>
                                    <FormattedMessage
                                        id={'common.originalGoal'}
                                    />
                                </span>
                                {': '}
                                {currencySign(currency)}
                                {Math.round(goalAmount).toLocaleString('en-EN')}
                            </div>
                        )}
                    </div>
                    <div className={classes.counts}>
                        {!!donorsCount && (
                            <div className={classes.count}>
                                <span className={classes.countAmount}>
                                    {donorsCount}
                                </span>
                                <FormattedMessage id="Amount.donors" />
                            </div>
                        )}
                        {childLayers.map(layer => (
                            <div key={layer.id} className={classes.count}>
                                <span className={classes.countAmount}>
                                    {layer.layerItemsCount}
                                </span>
                                <span>{getLayerLabel(layer, lang)}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={classes.loadingMsg}>
                    Analytics in progress...
                </div>
            )}
            <a onClick={onView} className={classes.viewLink}>
                <FormattedMessage id="Link.viewPage" /> <LinkIcon />
            </a>
            {typeof onClose === 'function' && (
                <button className={classes.close} onClick={onClose}>
                    <CloseIcon />
                </button>
            )}
            <div className={classes.actions}>
                {childLayers.length ? (
                    <div className={classes.viewBtns}>
                        <Button
                            // className={classNames(
                            //     classes.viewBtn,
                            // )}
                            color="accent"
                            variant={
                                selectedChildLayer ? 'outlined' : 'contained'
                            }
                            onClick={donorsClick}
                        >
                            <FormattedMessage id="Amount.donors" />
                        </Button>
                        {childLayers.map(layer => (
                            <Button
                                // className={classNames(
                                //     classes.viewBtn,
                                //     selectedChildLayer === layer.id
                                //         ? 'selected'
                                //         : null,
                                // )}
                                color="accent"
                                variant={
                                    selectedChildLayer === layer.id
                                        ? 'contained'
                                        : 'outlined'
                                }
                                key={layer.id}
                                onClick={() => childLayerButtonClick(layer.id)}
                            >
                                {getLayerLabel(layer, lang)}
                            </Button>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
                {allowDonations && (
                    <Button onClick={onDonate}>
                        <FormattedMessage id="DonateButton.text" />
                    </Button>
                )}
            </div>
        </Card>
    );
}

ModernItemCard.propTypes = {
    children: PropTypes.node,
    onView: PropTypes.func,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    donorsCount: PropTypes.number,
    teamsCount: PropTypes.number,
    amount: PropTypes.number,
    goalAmount: PropTypes.number,
    className: PropTypes.string,
    onClose: PropTypes.func,
    currency: PropTypes.string,
    isDisableAutoscroll: PropTypes.bool,
    childLayers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            locales: PropTypes.object,
            layerItemsCount: PropTypes.number,
        }),
    ),
    hasEnteredBonusGoal: PropTypes.bool,
    bonusGoalAmount: PropTypes.number,
    baseGoalPercent: PropTypes.number,
    allowDonations: PropTypes.bool,
    onDonate: PropTypes.func,
    donorsClick: PropTypes.func,
    selectedChildLayer: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    childLayerButtonClick: PropTypes.func,
};

export default ModernItemCard;
