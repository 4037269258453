import React from 'react';

function ExpandArrows(props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            >
                <path data-name="Right" d="M3 17.3V21h3.7" />
                <path d="m10 14-6.2 6.2M14 10l6.2-6.2" />
                <path data-name="Right" d="M21 6.7V3h-3.7" />
            </g>
        </svg>
    );
}

export default ExpandArrows;
