import { compose } from 'redux';
import { create } from 'client/helpers/axios';

const repository = {
    axios: create({ baseUrl: '/umbrella-campaigns' }),

    async create(umbrellaCampaignData) {
        const response = await this.axios.post('', umbrellaCampaignData);
        return response.data;
    },

    async findById(umbrellaCampaignId) {
        const response = await this.axios.get(`/${umbrellaCampaignId}`);
        return response.data;
    },

    async list() {
        const response = await this.axios.get('');
        return response.data;
    },

    async update(umbrellaCampaignId, umbrellaCampaignData) {
        const response = await this.axios.put(
            `/${umbrellaCampaignId}`,
            umbrellaCampaignData,
        );
        return response.data;
    },

    async delete(umbrellaCampaignId) {
        const response = await this.axios.delete(`/${umbrellaCampaignId}`);
        return response.data;
    },

    async addLanguage(umbrellaCampaignId, umbrellaCampaignLanguageData) {
        await this.axios.post(
            `/${umbrellaCampaignId}/lang`,
            umbrellaCampaignLanguageData,
        );
    },

    async fetchLanguage(umbrellaCampaignId, languageCode) {
        const response = await this.axios.get(
            `/${umbrellaCampaignId}/lang/${languageCode}`,
        );
        return deserializeLanguage(response.data);
    },

    async updateLanguage(
        umbrellaCampaignId,
        languageCode,
        umbrellaCampaignLanguageData,
    ) {
        const response = await this.axios.put(
            `/${umbrellaCampaignId}/lang/${languageCode}`,
            serializeLanguage(umbrellaCampaignLanguageData),
        );
        return deserializeLanguage(response.data);
    },

    async deleteLanguage(umbrellaCampaignId, languageCode) {
        const response = await this.axios.delete(
            `/${umbrellaCampaignId}/lang/${languageCode}`,
        );
        return response.data;
    },
};

function serializeLanguage(umbrellaCampaignLanguageData) {
    return compose(
        prepareGalleryImages('sliderImages'),
        prepareGalleryImages('sliderMobileImages'),
    )(umbrellaCampaignLanguageData);
}

function deserializeLanguage(umbrellaCampaignLanguage) {
    return compose(
        primeGalleryImages('sliderImages'),
        primeGalleryImages('sliderMobileImages'),
    )(umbrellaCampaignLanguage);
}

function primeGalleryImages(fieldName) {
    // insert a null value if the array is empty
    // so the initial image selector appears in UI
    return formData => {
        const gallery = formData[fieldName] || [];
        if (gallery.length > 0) {
            return formData;
        }
        return { ...formData, [fieldName]: [null] };
    };
}

function prepareGalleryImages(fieldName) {
    // remove falsy values before submitting to API
    return formData => {
        const gallery = formData[fieldName] || [];
        return { ...formData, [fieldName]: gallery.filter(Boolean) };
    };
}

export default repository;
