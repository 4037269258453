import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const paddingWithIcon = (vertical, horizontal, icon, dir = 'ltr') => {
    return props => {
        if (dir === 'ltr') {
            return `${vertical} ${
                props.endIcon ? icon : horizontal
            } ${vertical} ${props.icon ? icon : horizontal}`;
        }

        return `${vertical} ${props.icon ? icon : horizontal} ${vertical} ${
            props.endIcon ? icon : horizontal
        }`;
    };
};

// @todo: add error state handling
const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        width: props => props.width || '100%',
        background: palette.background.default,
        border: `1px solid ${palette.text.primary}`,
        position: 'relative',
        borderRadius: 5,
        boxSizing: 'border-box',
        '& svg': {
            position: 'absolute',
            left: props => (props.icon ? 19 : 'auto'),
            right: props => (props.endIcon ? 19 : 'auto'),
            top: 14,
            fill: palette.text.primary,

            'html[dir=rtl] &': {
                left: props => (props.endIcon ? 19 : 'auto'),
                right: props => (props.icon ? 19 : 'auto'),
            },
        },

        '@media (max-width: 600px)': {
            '& svg': {
                left: props => (props.icon ? 15 : 'auto'),
                right: props => (props.endIcon ? 15 : 'auto'),
                top: 12,

                'html[dir=rtl] &': {
                    left: props => (props.endIcon ? 15 : 'auto'),
                    right: props => (props.icon ? 15 : 'auto'),
                },
            },
        },
    },
    input: {
        display: 'block',
        width: '100%',
        minHeight: 54,
        border: 'none',
        outline: 'none',
        background: 'none',
        boxSizing: 'border-box',
        fontFamily: typography.fontFamily,
        fontSize: 16,
        resize: props => (props.resize ? 'vertical' : 'none'),
        padding: paddingWithIcon('10px', '15px', '45px'),
        color: palette.text.primary,
        '&::placeholder': {
            color: changeColorAlpha(palette.text.primary, 0.85),
        },
        '&::-moz-placeholder': {
            color: 'black',
        },
        'html[dir=rtl] &': {
            padding: paddingWithIcon('10px', '15px', '45px', 'rtl'),
        },

        '@media (max-width: 600px)': {
            minHeight: 44,
        },
    },
}));

function Textarea(props) {
    const { className, icon, endIcon, width, resize, inputRef, ...rest } =
        props;
    const classes = useStyles({ icon, endIcon, width, resize });

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <textarea
                ref={inputRef}
                className={classes.input}
                {...rest}
            ></textarea>
            {!!icon && icon}
            {!!endIcon && endIcon}
        </div>
    );
}

Textarea.propTypes = {
    icon: PropTypes.node,
    endIcon: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resize: PropTypes.bool,
    className: PropTypes.string,
    inputRef: PropTypes.func,
};

export default Textarea;
