import React from 'react';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectPageDesign } from '../../features/campaign/campaignSlice';
import ClassicItemCard from './ClassicItemCard';
import ModernItemCard from './ModernItemCard';

export default function ItemCardPopup(props) {
    const pageDesign = useSelector(selectPageDesign);

    switch (pageDesign) {
        case CAMPAIGN_PAGE_DESIGNS.MODERN:
            return <ModernItemCard {...props} />;

        case CAMPAIGN_PAGE_DESIGNS.CLASSIC:
        default:
            return <ClassicItemCard {...props} />;
    }
}
