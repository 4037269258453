import React from 'react';
import ModernTeamCard from './ModernTeamCard';
import ClassicTeamCard from './ClassicTeamCard';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectPageDesign } from '../../features/campaign/campaignSlice';

function TeamCard(props) {
    const pageDesign = useSelector(selectPageDesign);

    switch (pageDesign) {
        case CAMPAIGN_PAGE_DESIGNS.MODERN:
            return <ModernTeamCard {...props} />;

        case CAMPAIGN_PAGE_DESIGNS.CLASSIC:
        default:
            return <ClassicTeamCard {...props} />;
    }
}

export default TeamCard;
