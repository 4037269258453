import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Button from 'client/componentLibrary/Button';
import Progress from 'client/componentLibrary/Progress';
import useIsMobile from 'client/hooks/isMobile';
import { PAYMENT_METHODS } from 'common/constants';
import { currencySign } from 'common/helpers';
import { getProgressGradation } from 'common/helpers/layerItemProgressGradation';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { isLightColor } from '../../../../helpers/colorUtils';
import Avatar from '../Avatar/Avatar';

const MOBILE_THRESHOLD = 600;

const useStyles = makeStyles(({ palette, spacing, zIndex }) => {
    const bgColor = palette.accent.dark;
    return {
        root: {
            backgroundColor: bgColor,
            padding: `${spacing(1)}px ${spacing(3)}px ${spacing(0.5)}px`,
            justifyContent: 'center',
            position: 'sticky',
            zIndex: zIndex.appBar,
            color: palette.accent.contrastText,
            borderRadius: `0 0 ${spacing(3)}px ${spacing(3)}px`,
        },
        avatar: {
            flexShrink: 0,
            backgroundColor: bgColor,
        },
        logo: {
            objectFit: 'cover',
        },
        title: {
            marginLeft: spacing(2),
            paddingRight: spacing(1),

            'html[dir=rtl] &': {
                marginLeft: 0,
                paddingRight: 0,
                marginRight: spacing(2),
                paddingLeft: spacing(1),
            },
        },
        statsContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: `0 ${spacing(3)}px`,
        },
        stats: {
            display: 'flex',
            gap: spacing(1),
            justifyContent: 'space-evenly',
        },
        stat: {
            textAlign: 'center',
        },
        statData: {
            color: isLightColor(palette.accent.dark, 0.7)
                ? palette.text.primary
                : palette.accent.contrastText,
            fontWeight: 700,
        },
        statTitle: {
            fontSize: '1rem',
        },
        progressBar: {
            margin: `${spacing(2)}px auto`,
            width: '90%',
        },
        '@media (max-width: 1024px)': {
            root: {
                padding: `${spacing(0.5)}px ${spacing(5)}px`,
            },
            name: {
                display: 'block',
                fontWeight: 600,
            },
            title: {
                fontSize: '1rem',
            },
            statData: {
                fontSize: '1.2rem',
            },
        },
        '@media (max-width: 820px)': {
            root: {
                top: '0 !important',
                padding: `${spacing(0.5)}px ${spacing(1)}px`,
            },
            avatar: {
                border: null,
            },
            title: {
                fontSize: '0.9rem',
                maxWidth: 185,
                marginLeft: spacing(1),
                'html[dir=rtl] &': {
                    marginLeft: 0,
                    marginRight: spacing(1),
                },
            },
            stats: {
                margin: 0,
            },
            statData: {
                fontSize: '1rem',
            },
            statTitle: {
                fontSize: '0.85rem',
            },
            mobileCollapse: {
                margin: 0,
            },
            expandedMobileCollapse: {
                margin: `${spacing(1.5)}px 0`,
            },
        },
        [`@media (max-width: ${MOBILE_THRESHOLD}px)`]: {
            root: {
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: spacing(0.5),
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: 85,
                    height: 4,
                    backgroundColor: palette.accent.light,
                    borderRadius: 4,
                },
            },
            expandedStats: {
                justifyContent: 'space-between',
            },
        },
    };
});

function ModernDetailsBar(props) {
    const {
        name,
        avatarSrc,
        currency,
        amountRaised,
        goalAmount,
        donorsCount,
        bonusGoalAmount = 0,
        hasEnteredBonusGoal = false,
        showProgress = true,
        showDonateButton = false,
        handleDonateNowClick,
    } = props;
    const history = useHistory();
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { width } = useIsMobile();
    const isMobile = width <= MOBILE_THRESHOLD;
    const [stats, setStats] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const scrollOffset = 60; // to prevent scroll triggering on expand
    const top = 0;
    let avatarSize = 85;
    if (isMobile) {
        avatarSize = 55;
    }
    const { goalPercent, bonusGoalPercent } = getProgressGradation(
        amountRaised,
        goalAmount,
        bonusGoalAmount,
        hasEnteredBonusGoal,
    );
    const goals = [
        {
            percent: goalPercent,
            color: 'secondary',
        },
        {
            percent: bonusGoalPercent,
            color: 'primary',
        },
    ];

    function updateStats() {
        let updatedStats = [];

        updatedStats.push({
            key: 'amountRaised',
            title: formatMessage({
                id: 'Amount.raised',
                defaultMessage: 'Raised',
            }),
            data: formatAmount(amountRaised),
        });

        if (goalAmount && goalAmount > 0) {
            if (hasEnteredBonusGoal) {
                updatedStats.push({
                    key: 'totalGoalAmount',
                    title: formatMessage({
                        id: 'DonationWidget.bonusTitle',
                        defaultMessage: 'Bonus Goal',
                    }),
                    data: formatAmount(goalAmount + bonusGoalAmount),
                });
            } else {
                updatedStats.push({
                    key: 'goalAmount',
                    title: formatMessage({
                        id: 'Amount.goal',
                        defaultMessage: 'Goal',
                    }),
                    data: formatAmount(goalAmount),
                });
            }
            updatedStats.push({
                key: 'percentageRaised',
                title: formatMessage({
                    id: 'Amount.progress',
                    defaultMessage: 'Progress',
                }),
                data: Math.round((amountRaised / goalAmount) * 100) + '%',
            });
        }
        updatedStats.push({
            key: 'donorsCount',
            title: formatMessage({
                id: 'Amount.donors',
                defaultMessage: 'Donors',
            }),
            data: donorsCount?.toLocaleString() || 0,
        });

        if (isMobile && updatedStats.length > 2 && !isExpanded) {
            setStats(
                updatedStats.filter(stat =>
                    ['amountRaised'].includes(stat.key),
                ),
            );
        } else {
            setStats(updatedStats);
        }
    }

    function formatAmount(amount) {
        return currencySign(currency) + amount.toLocaleString();
    }

    function handleItemBarClick() {
        if (isMobile) {
            setIsExpanded(!isExpanded);
        }
    }

    function onDonateNowClick() {
        handleDonateNowClick(PAYMENT_METHODS.CARD, history);
    }

    function renderStats() {
        return (
            <div className={classes.statsContainer}>
                <div
                    className={classNames(classes.stats, {
                        [classes.expandedStats]: isExpanded,
                    })}
                >
                    {stats.map(stat => (
                        <div key={stat.key} className={classes.stat}>
                            <Typography
                                variant="h4"
                                className={classNames(classes.statData)}
                            >
                                {stat.data}
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.statTitle}
                            >
                                {stat.title}
                            </Typography>
                        </div>
                    ))}
                </div>
                {showProgress && !isMobile && (
                    <Progress
                        className={classes.progressBar}
                        size={6}
                        goals={goals}
                    />
                )}
            </div>
        );
    }

    useEffect(() => {
        if (!isMobile && isExpanded) {
            setIsExpanded(false);
        }
        updateStats();
    }, [props, isMobile, isExpanded, formatMessage]);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolling =
                window.scrollY >= scrollOffset + lastScrollY ||
                window.scrollY <= lastScrollY - scrollOffset;
            if (isMobile && isScrolling) {
                setIsExpanded(false);
                setLastScrollY(window.scrollY);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile, lastScrollY, isExpanded]);

    return (
        <AppBar
            className={classNames(classes.root)}
            style={{ top }}
            component="section"
            onClick={handleItemBarClick}
        >
            <Toolbar disableGutters>
                {avatarSrc && (
                    <>
                        <Avatar
                            size={avatarSize}
                            className={classes.avatar}
                            style={{
                                minWidth: avatarSize,
                                minHeight: avatarSize,
                            }}
                        >
                            <img
                                className={classes.logo}
                                src={avatarSrc}
                                alt={name}
                                width={avatarSize}
                                height={avatarSize}
                            />
                        </Avatar>
                    </>
                )}

                <Typography
                    variant="h5"
                    className={classNames(classes.title, {
                        [classes.glowTextShadow]: hasEnteredBonusGoal,
                    })}
                >
                    <span className={classes.name}>{name}</span>
                </Typography>
                {!isExpanded && renderStats()}
                {showDonateButton && !isMobile && (
                    <Button onClick={onDonateNowClick} color="secondary">
                        <FormattedMessage
                            id="DonateButton.text"
                            defaultMessage="Donate Now!"
                        />
                    </Button>
                )}
            </Toolbar>
            <Collapse
                in={isExpanded}
                className={classNames(classes.mobileCollapse, {
                    [classes.expandedMobileCollapse]: isExpanded,
                })}
            >
                {renderStats()}
            </Collapse>
            {showProgress && isMobile && (
                <Progress
                    className={classes.progressBar}
                    size={4}
                    goals={goals}
                />
            )}
        </AppBar>
    );
}

ModernDetailsBar.propTypes = {
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    currency: PropTypes.string,
    goalAmount: PropTypes.number,
    amountRaised: PropTypes.number,
    donorsCount: PropTypes.number,
    bonusGoalAmount: PropTypes.number,
    hasEnteredBonusGoal: PropTypes.bool,
    showProgress: PropTypes.bool,
    showDonateButton: PropTypes.bool,
    handleDonateNowClick: PropTypes.func,
};

export default ModernDetailsBar;
