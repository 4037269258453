import { inRange, isNumber } from 'lodash';
import { isHexColor, isRgbColor } from 'common/helpers/color';

export { isHexColor, isRgbColor } from 'common/helpers/color';

export function rgbValuesToHexString(r, g, b, a) {
    let hexString =
        '#' +
        [r, g, b]
            .map(value => Math.round(value).toString(16).padStart(2, '0'))
            .join('');
    if (isNumber(a) && inRange(a, 0, 1)) {
        hexString += Math.round(a * 255)
            .toString(16)
            .padStart(2, '0');
    }
    return hexString;
}

export function rgbValuesFromRgbString(rgbString = '') {
    const [r, g, b, a] = rgbString.match(/[\d.]+/g)?.map(Number);
    if (
        [r, g, b].every(
            value => Number.isInteger(value) && inRange(value, 0, 256),
        )
    ) {
        const values = [r, g, b];
        if (inRange(a, 0, 1)) {
            values.push(a);
        }
        return values;
    }
}

export function rgbValuesFromHexString(hexColor = '') {
    return hexColor
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (_m, r, g, b) => '#' + r + r + g + g + b + b,
        )
        .substring(1)
        .match(/.{2}/g)
        .map(value => parseInt(value, 16));
}

export function getColorLuminance(color) {
    let colorValues;
    if (isHexColor(color)) {
        colorValues = rgbValuesFromHexString(color);
    } else if (isRgbColor(color)) {
        colorValues = rgbValuesFromRgbString(color);
    } else {
        throw new Error('Invalid color format, use HEX or RGB.');
    }

    const [r, g, b] = colorValues;

    // Convert RGB to luminance (YIQ color space)
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
}

export function isLightColor(color, luminanceThreshold = 0.5) {
    const luminance = getColorLuminance(color);
    return luminance > luminanceThreshold;
}

export function getTextShadeByColor(baseColor, luminanceThreshold = 0.5) {
    return isLightColor(baseColor, luminanceThreshold) ? 'dark' : 'light';
}

export function adjustColor(baseColor, percentage = 0, returnHex = true) {
    const validPercentage = Math.max(-100, Math.min(100, percentage));

    let colorValues;

    if (isHexColor(baseColor)) {
        colorValues = rgbValuesFromHexString(baseColor);
    } else if (isRgbColor(baseColor)) {
        colorValues = rgbValuesFromRgbString(baseColor);
    } else {
        throw new Error('Invalid color format, use HEX or RGB.');
    }

    const adjustment = (validPercentage / 100) * 255;

    const adjustColorValue = colorValue =>
        Math.round(Math.max(0, Math.min(255, colorValue + adjustment)));

    const adjustedColorValues = colorValues.map(adjustColorValue);

    const adjustedColor = returnHex
        ? rgbValuesToHexString(...adjustedColorValues)
        : `rgb(${adjustedColorValues.join(', ')})`;

    return adjustedColor;
}

export function generateColorScale(
    baseColor,
    size = 3,
    scalingFactor = 80,
    returnHex = true,
) {
    const colorValues = isHexColor(baseColor)
        ? rgbValuesFromHexString(baseColor)
        : rgbValuesFromRgbString(baseColor);

    const scaleColorValue = (colorValue, factor) =>
        Math.round(
            Math.max(0, Math.min(255, colorValue - factor * scalingFactor)),
        );

    const colorScale = Array.from({ length: size }, (_, index) => {
        const factor = index - 1;
        const scaledColorValues = colorValues.map(colorValue =>
            scaleColorValue(colorValue, factor),
        );
        return returnHex
            ? rgbValuesToHexString(...scaledColorValues)
            : `rgb(${scaledColorValues.join(', ')})`;
    });

    return colorScale;
}

export function changeColorAlpha(baseColor, alpha = 1) {
    const updatedAlpha = Math.min(1, Math.max(0, Number(alpha)));

    if (isHexColor(baseColor)) {
        const [r, g, b] = rgbValuesFromHexString(baseColor);

        return rgbValuesToHexString(r, g, b, updatedAlpha);
    }

    if (isRgbColor(baseColor)) {
        const [r, g, b] = rgbValuesFromRgbString(baseColor);
        if (updatedAlpha === 1) {
            return `rgb(${r}, ${g}, ${b})`;
        } else {
            return `rgba(${r}, ${g}, ${b}, ${updatedAlpha})`;
        }
    }

    return baseColor;
}

function getColorContrastRatio(color1, color2) {
    const lum1 = getColorLuminance(color1);
    const lum2 = getColorLuminance(color2);

    // 0.05 is a constant added to prevent division by zero
    // and to account for perceptual differences at very low luminance levels (WCAG)
    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}

export function isTextColorLegible(
    textColor,
    bgColor,
    contrastThreshold = 4.5,
) {
    const contrastRatio = getColorContrastRatio(bgColor, textColor);

    return contrastRatio > contrastThreshold;
}
