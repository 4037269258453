import React from 'react';
import PropTypes from 'prop-types';

function CheckmarkIcon({ width = 8, height = 6.5, color = '#FFF', ...props }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 8 6.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs id="defs1" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 7.6532338,0.24285005 c 0.41817,0.360771 0.46469,0.99221995 0.10392,1.41038995 l -3.88235,4.5 c -0.18996,0.22018 -0.46636,0.34677 -0.75716,0.34677 -0.2908,0 -0.56719,-0.12659 -0.75715,-0.34677 L 0.24284386,3.69869 C -0.11792714,3.28053 -0.07139884,2.64908 0.34676686,2.2883 0.76493286,1.92753 1.3963838,1.97406 1.7571538,2.39223 l 1.36049,1.57693 3.1252,-3.62238695 c 0.36077,-0.4181656 0.99222,-0.464694 1.41039,-0.103923 z"
                fill={color}
            />
        </svg>
    );
}

CheckmarkIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

export default CheckmarkIcon;
