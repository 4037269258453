import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCustomLogicSettings } from '../../campaign/campaignSlice';
import { CAMPAIGN_CUSTOM_LOGIC_TYPES } from 'common/constants/campaign';
import PurimGivingWidget from './PurimGivingWidget';

const CustomLogicComponents = {
    [CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM]: PurimGivingWidget,
};

function CustomGivingWidget({ children }) {
    const customLogicSettings = useSelector(selectCustomLogicSettings);

    const CustomLogicComponent =
        CustomLogicComponents[customLogicSettings?.customLogicType];
    if (!CustomLogicComponent) {
        return <>{children}</>;
    }

    return <CustomLogicComponent>{children}</CustomLogicComponent>;
}

CustomGivingWidget.propTypes = {
    children: PropTypes.node,
};

export default CustomGivingWidget;
