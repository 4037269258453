import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import MultiplierIcon from '../MultiplierIcon/MultiplierIcon';
import Card from 'client/componentLibrary/Card';

const useStyles = makeStyles(
    ({ palette, spacing, typography }) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: spacing(2),

            '@media (max-width: 600px)': {
                padding: spacing(1.5),
            },
        },

        matchingText: {
            display: 'inline-flex',
            fontSize: '1rem',
            color: palette.text.primary,
            alignItems: 'center',

            '&:empty': {
                display: 'none',
            },
            '& svg': {
                marginInlineEnd: spacing(1),
                width: 28,

                '& g': {
                    direction: 'ltr',
                },
            },
            '@media (max-width: 600px)': {
                fontSize: typography.pxToRem(14),
            },
        },
    }),
    { name: 'additionalInfoWidget' },
);

export const AdditionalInfoWidget = ({
    className,
    multiplier,
    multiplierText = '',
}) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { palette } = useTheme();
    const getText = useCallback(() => {
        if (multiplierText) {
            return multiplierText;
        }

        switch (multiplier) {
            case 1:
                return null;
            case 2:
                return formatMessage({
                    id: 'Matching.double',
                    defaultMessage:
                        'Matching Donors Will Double Your Donation!',
                });
            case 3:
                return formatMessage({
                    id: 'Matching.triple',
                    defaultMessage:
                        'Matching Donors Will Triple Your Donation!',
                });
            case 4:
                return formatMessage({
                    id: 'Matching.quadruple',
                    defaultMessage:
                        'Matching Donors Will Quadruple Your Donation!',
                });
            default:
                return formatMessage({
                    id: 'Matching.multiply',
                    defaultMessage:
                        'Matching Donors Will Multiply Your Donation!',
                });
        }
    }, [multiplier, multiplierText, formatMessage]);

    return (
        <Card className={classNames(classes.root, className)}>
            <div className={classes.content}>
                {multiplier > 1 && (
                    <div className={classes.matchingText}>
                        {multiplier && (
                            <MultiplierIcon
                                fill={palette.text.primary}
                                multiplier={multiplier}
                            />
                        )}
                        {getText()}
                    </div>
                )}
            </div>
        </Card>
    );
};

AdditionalInfoWidget.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    donationText: PropTypes.string,
    multiplier: PropTypes.number,
    multiplierText: PropTypes.string,
    showDonationText: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdditionalInfoWidget);
