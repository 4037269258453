import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ spacing }) => ({
    root: {
        display: 'grid',
        gridGap: spacing(4),
        gap: spacing(4),
        gridTemplateColumns: `repeat(2, 1fr)`,

        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr !important',
            gridGap: `${spacing(2)}px !important`,
            gap: `${spacing(2)}px !important`,
        },
    },
}));

function ItemCardsGrid({ children, className, columns = 2, gap = 4, listRef }) {
    const { spacing } = useTheme();
    const classes = useStyles();

    return (
        <div
            className={classNames(classes.root, className)}
            ref={listRef}
            style={{
                gridGap: spacing(gap),
                gap: spacing(gap),
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            }}
        >
            {children}
        </div>
    );
}

ItemCardsGrid.propTypes = {
    children: PropTypes.node,
    columns: PropTypes.number,
    gap: PropTypes.string,
    className: PropTypes.string,
    listRef: PropTypes.any,
};

export default ItemCardsGrid;
