import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DonationTabPanel from '../DonationTabPanel/DonationTabPanel';
import FormGroup from '../FormGroup/FormGroup';
import FormLabel from '../FormLabel/FormLabel';
import Select from '../Select/Select';
import SearchInput from '../SearchInput/SearchInput';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            margin: `${spacing(2)}px 0`,
            alignItems: 'center',
            gap: spacing(1),
        },

        formLabel: {
            marginInlineEnd: `${spacing(1)}px !important`,
            color: palette.text.primary,

            '@media (max-width: 600px)': {
                display: 'none',
            },
        },

        select: {
            borderRadius: spacing(1),
            border: `1px solid ${palette.background.paperBorder}`,
            '& select': {
                paddingInlineStart: `${spacing(1)}px`,
                paddingInlineEnd: 0,
                marginInlineEnd: spacing(3),
                color: palette.text.primary,
                height: 35,
            },
            '&::before': {
                display: 'none',
            },
            '&::after': {
                border: `solid ${palette.accent.dark}`,
                borderWidth: '2px 0 0 0',
                borderInlineEnd: `2px solid ${palette.accent.dark}`,
            },
            '@media (max-width: 600px)': {
                width: 130,
                '& select': {
                    paddingInlineEnd: spacing(2),
                },
            },
        },

        search: {
            width: 160,
            '& input': {
                height: 37,
                border: `1px solid ${palette.background.paperBorder}`,
                borderRadius: spacing(1),
                color: palette.text.primary,
                paddingInlineEnd: spacing(4),
                '&::placeholder': {
                    color: palette.text.primary,
                },
                '&::-ms-input-placeholder': {
                    color: palette.text.primary,
                },
            },
            '& svg': {
                stroke: palette.text.primary,
            },
            '@media (max-width: 600px)': {
                width: 130,
            },
        },
    }),
    { name: 'DonationTabContentSearch' },
);

function RdpTabContentSearch(props) {
    const {
        onChange,
        order = '',
        searchText = '',
        sortOptions = null,
        disabled = false,
    } = props;
    const classes = useStyles();
    const [query, setQuery] = useState({ order, searchText });

    function changeQuery(property) {
        return event => {
            const value =
                typeof event.target !== 'undefined'
                    ? event.target.value
                    : event;
            const newQuery = {
                ...query,
                [property]: value,
                page: 1,
            };
            setQuery(newQuery);
            onChange(newQuery);
        };
    }

    return (
        <DonationTabPanel className={classNames(classes.root, 'rdp-filter')}>
            {!!sortOptions && (
                <FormGroup inline>
                    <FormLabel className={classes.formLabel}>
                        <FormattedMessage
                            id="DonationTabPanel.sortBy"
                            defaultMessage="Sort by:"
                        />
                    </FormLabel>
                    <Select
                        className={classes.select}
                        disabled={disabled}
                        value={query.order}
                        onChange={changeQuery('order')}
                        options={Object.entries(sortOptions).map(
                            ([value, label]) => ({
                                name: label,
                                value,
                            }),
                        )}
                    />
                </FormGroup>
            )}
            <FormGroup inline>
                <SearchInput
                    className={classes.search}
                    value={query.searchText}
                    onChange={debounce(changeQuery('searchText'), 300)}
                    width={190}
                />
            </FormGroup>
        </DonationTabPanel>
    );
}

RdpTabContentSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    order: PropTypes.string,
    searchText: PropTypes.string,
    sortOptions: PropTypes.object,
    disabled: PropTypes.bool,
};

export default React.memo(RdpTabContentSearch, (prevProps, nextProps) => {
    return (
        prevProps.order !== nextProps.order ||
        prevProps.searchText !== nextProps.searchText
    );
});
