import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import DonateCard from '../../components/DonateCard';
import { useIntl } from 'react-intl';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { changeColorAlpha } from 'client/helpers/colorUtils';
import {
    CAMPAIGN_CUSTOM_LOGIC_TYPES,
    CAMPAIGN_PAGE_DESIGNS,
    SUPPORTED_PAGE_DESIGNS,
} from 'common/constants';
import { getPerkLogo } from 'client/helpers/getPerkLogo';
import { useSelector } from 'react-redux';
import {
    selectGivings,
    selectPageName,
    selectSelectedCampaign,
    selectPageSpecificDonationTiers,
} from '../../features/campaign/campaignSlice';

function DonorItem(props) {
    const {
        donor,
        isMatchMyGiftEnabled,
        onMatchMyGiftClicked,
        isDonorPflEnabled,
        isFirstInGroup,
        pageDesign,
    } = props;
    const { formatMessage } = useIntl();
    const { palette } = useTheme();
    const name = donor.isAnonymous
        ? formatMessage({ id: 'user.anon', defaultMessage: 'Anonymous' })
        : donor.name;
    const throughLayerItemName = get(donor, 'layerItem.name', null);
    const throughLayerItemSlug = get(donor, 'layerItem.slug', null);
    const throughLayerItemId = get(donor, 'layerItem.id', null);
    const { filteredQueryString: queryString } = filterQueryParams(
        document.location.search,
    );
    const throughItemUrl = throughLayerItemId
        ? `/${props.campaignSlug}/${
              throughLayerItemSlug || throughLayerItemId
          }/${queryString}`
        : null;
    const multiplier = get(donor, 'multiplier', 1);
    const recurringMonths = get(donor, 'recurringMonths', 1);
    const givingColor = get(donor, 'giving.color');
    const campaign = useSelector(selectSelectedCampaign);
    const pageName = useSelector(selectPageName);
    const givings = useSelector(selectGivings);

    const getDonorGivingLogo = () => {
        const donorGivingLogo = get(donor, 'giving.logo');
        if (donorGivingLogo) {
            return donorGivingLogo;
        }
        const donorGivingId = get(donor, 'giving.id');
        if (donorGivingId) {
            const perk = givings.find(perk => perk.id === donorGivingId);
            const perkLogo = getPerkLogo(perk, campaign, pageName);
            return perkLogo ? perkLogo : null;
        }

        return null;
    };

    const matchMyGiftEnabledForItem = isMatchMyGiftEnabled && !donor.giving?.id;
    const donorPflCount = get(donor, 'donorPflCount');
    const matchMyGiftCount = get(donor, 'matchedDonationsCount');

    const showMatchMyGiftCount = isMatchMyGiftEnabled && matchMyGiftCount > 0;
    const showDonorPflCount =
        isFirstInGroup && isDonorPflEnabled && donorPflCount > 0;
    const totalMatchingCount =
        (showMatchMyGiftCount ? matchMyGiftCount : 0) +
        (showDonorPflCount ? donorPflCount : 0);

    const handleViewThroughItem = () => {
        if (!donor?.layerItem?.isPublished) {
            return false;
        }
        if (window.location.pathname !== throughItemUrl) {
            window.location = throughItemUrl;
        }
    };

    const donationTiers = useSelector(selectPageSpecificDonationTiers);
    const donationTierId = get(
        donor,
        ['customFields', CAMPAIGN_CUSTOM_LOGIC_TYPES.PURIM, 'donationTierId'],
        null,
    );
    const donationTier = get(donationTiers, donationTierId);

    const [backgroundColor, perkOutlineColor] = useMemo(() => {
        let backgroundColor = givingColor
            ? changeColorAlpha(givingColor, 0.2)
            : changeColorAlpha(palette.primary.light, 0.1) || '#FFF';
        let perkOutlineColor = givingColor;

        if (pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN) {
            backgroundColor = palette.background.paper || '#FFF';
        }

        return [backgroundColor, perkOutlineColor];
    }, [pageDesign]);

    return (
        <DonateCard
            donorId={donor.id}
            matchMyGiftCount={donor.matchedDonationsCount}
            amount={donor.amount * multiplier * recurringMonths}
            hideAmount={donor.isAmountHidden || false}
            isMatchMyGiftEnabled={matchMyGiftEnabledForItem}
            onMatchMyGiftClicked={() => {
                onMatchMyGiftClicked(donor);
            }}
            donorPflCount={donor.donorPflCount}
            isFirstInGroup={isFirstInGroup}
            logoUrl={getDonorGivingLogo()}
            perkOutlineColor={perkOutlineColor}
            bgColor={backgroundColor}
            currency={donor.currency}
            name={name}
            color={givingColor || get(donor, 'layerItem.color')}
            greetingText={donor.comment}
            throughText={
                throughLayerItemName
                    ? formatMessage({
                          id: 'DonateCard.through',
                          defaultMessage: 'Donation through ',
                      })
                    : null
            }
            throughLayerItem={donor?.layerItem}
            throughLayerItemName={throughLayerItemName}
            throughItemUrl={throughItemUrl}
            className="rdp-card"
            totalMatchingCount={totalMatchingCount}
            handleViewThroughItem={handleViewThroughItem}
            showDonorPflCount={showDonorPflCount}
            showMatchMyGiftCount={showMatchMyGiftCount}
            donationTier={donationTier}
        />
    );
}

DonorItem.propTypes = {
    donor: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        amount: PropTypes.number,
        multiplier: PropTypes.number,
        recurringMonths: PropTypes.number,
        layerItem: PropTypes.object,
        currency: PropTypes.string,
        isAnonymous: PropTypes.bool,
        isAmountHidden: PropTypes.bool,
        giving: PropTypes.object,
        comment: PropTypes.string,
        matchedDonationsCount: PropTypes.number,
        donorPflCount: PropTypes.number,
    }),
    campaignSlug: PropTypes.string,
    isMatchMyGiftEnabled: PropTypes.bool,
    onMatchMyGiftClicked: PropTypes.func,
    isDonorPflEnabled: PropTypes.bool,
    isFirstInGroup: PropTypes.bool,
    pageDesign: PropTypes.oneOf(SUPPORTED_PAGE_DESIGNS),
};

export default DonorItem;
