import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { currencySign } from 'common/helpers';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = ({ palette, spacing, typography }) => ({
    root: {
        margin: 0,
        padding: spacing(2),
        paddingBottom: 0,
        fontFamily: typography.fontFamily,
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        flexWrap: 'wrap',
        '@media (max-width: 480px)': {
            fontSize: '1.2rem',
        },
    },
    description: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    openBtn: {
        cursor: 'pointer',
        display: 'flex',
        padding: '1px 5px 7px',
        marginRight: 0,
        marginLeft: 'auto',
        '& svg': {
            width: 20,
            height: 20,
        },
        'html[dir=rtl] &': {
            marginLeft: 0,
            marginRight: 'auto',
        },
        '@media (max-width: 480px)': {
            padding: '0 3px 6px',
        },
    },
    closeButton: {
        color: palette.grey[500],
        padding: 5,
        marginLeft: spacing(1),
        'html[dir=rtl] &': {
            marginLeft: 0,
            marginRight: spacing(1),
        },
    },
    units: {
        display: 'flex',
        alignItems: 'center',
    },
    selectUnitsLabel: {
        marginRight: spacing(2),
        'html[dir=rtl] &': {
            marginLeft: spacing(2),
            marginRight: 0,
        },
    },
    gifts: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(1),
        '@media (max-width: 480px)': {
            padding: 10,
        },
    },
    totalTitle: {
        '@media (max-width: 480px)': {
            fontSize: '1.2rem',
        },
    },
    amount: {
        color: palette.secondary.main,
        '@media (max-width: 480px)': {
            fontSize: '1.5rem',
        },
    },
    donateBtn: {
        margin: 0,
        padding: '8px !important',
        height: 'auto !important',
        maxWidth: '315px !important',
    },
    donateBtnLabel: {
        textTransform: 'initial',
        '@media (max-width: 480px)': {
            fontSize: '1rem',
        },
    },
    disabled: {
        opacity: 0.35,
    },
});

const PerkTitle = withStyles(styles)(({ children, classes, onClose }) => {
    return (
        <MuiDialogTitle
            disableTypography
            className={classNames(classes.root, classes.titleRow)}
        >
            <Typography className={classes.title} variant="h5">
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const PerkContent = withStyles(({ spacing }) => ({
    root: {
        padding: spacing(2),
    },
}))(MuiDialogContent);

const PerkActions = withStyles(({ spacing }) => ({
    root: {
        margin: 0,
        padding: spacing(2),
        '@media (max-width: 480px)': {
            justifyContent: 'center',
        },
    },
}))(MuiDialogActions);

const PerkPopup = ({
    classes,
    paymentOptions,
    duration,
    disabled,
    hasName,
    hasDescription,
    isPriceEnabled,
    name,
    description,
    amount,
    totalAmount,
    currency,
    isStockEmpty,
    DonateButton,
    PerkCounter,
    handleDonate,
    PerkGifts,
    handleOpenPopup,
    handleClosePopup,
    isPopupOpen,
    shouldShowGiftsSection,
    shouldShowCounter,
    shouldShowOpenBtn,
}) => {
    const recurringCount = paymentOptions === 'recurring' ? duration : 1;
    const shouldShowPrice = isPriceEnabled && amount > 0;

    const renderAmount = amountType => {
        if (amount > 0) {
            return (
                <>
                    {currencySign(currency)}
                    {amountType === 'total'
                        ? totalAmount.toLocaleString('en-EN')
                        : amount.toLocaleString('en-EN')}
                    {recurringCount > 1 && `×${recurringCount}`}
                </>
            );
        }
        return <></>;
    };

    const handlePopupDonationClick = () => {
        if (!disabled && !isStockEmpty) {
            handleDonate();
        }
        handleClosePopup();
    };

    return (
        <>
            {shouldShowOpenBtn && (
                <div
                    className={classNames(
                        classes.openBtn,
                        'perk-popup-open-btn',
                    )}
                    onClick={handleOpenPopup}
                >
                    <MenuRoundedIcon />
                </div>
            )}
            <Dialog
                onClose={handleClosePopup}
                aria-labelledby="customized-dialog-title"
                open={isPopupOpen}
                fullWidth
            >
                <PerkTitle onClose={handleClosePopup}>
                    <span>{hasName ? name : ''}</span>
                </PerkTitle>
                <PerkContent hidden={!(shouldShowPrice || hasDescription)}>
                    {shouldShowPrice ? (
                        <Typography
                            variant="h4"
                            className={classes.amount}
                            gutterBottom
                        >
                            {renderAmount()}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {hasDescription ? (
                        <Typography
                            className={classes.description}
                            gutterBottom
                        >
                            {description}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </PerkContent>
                {shouldShowGiftsSection ? (
                    <PerkContent className={classes.gifts}>
                        <PerkGifts />
                    </PerkContent>
                ) : (
                    <></>
                )}
                {shouldShowCounter ? (
                    <PerkContent
                        className={classNames(classes.units, {
                            [classes.disabled]: disabled || isStockEmpty,
                        })}
                    >
                        <Typography
                            variant="h6"
                            className={classes.selectUnitsLabel}
                        >
                            <FormattedMessage id="Perks.selectUnits" />
                            {':'}
                        </Typography>
                        <PerkCounter orientation="horizontal" />
                    </PerkContent>
                ) : (
                    <></>
                )}
                <PerkContent dividers>
                    {amount > 0 ? (
                        <div
                            className={classNames(classes.total, {
                                [classes.disabled]: disabled || isStockEmpty,
                            })}
                        >
                            <Typography
                                variant="h5"
                                className={classes.totalTitle}
                            >
                                <FormattedMessage id="DonationForm.totalAmount" />
                            </Typography>
                            <Typography variant="h4" className={classes.amount}>
                                {renderAmount('total')}
                            </Typography>
                        </div>
                    ) : (
                        <></>
                    )}
                    <PerkActions>
                        <DonateButton
                            className={classNames(
                                classes.donateBtn,
                                'perk-popup-donate-btn',
                            )}
                            onClick={handlePopupDonationClick}
                            disabled={disabled || isStockEmpty}
                        >
                            <span className={classes.donateBtnLabel}>
                                {isStockEmpty ? (
                                    <FormattedMessage id="Perks.soldOut" />
                                ) : (
                                    <FormattedMessage
                                        id="Perks.toCheckout"
                                        defaultMessage="Continue to Checkout"
                                    />
                                )}
                            </span>
                        </DonateButton>
                    </PerkActions>
                </PerkContent>
            </Dialog>
        </>
    );
};

PerkPopup.propTypes = {
    classes: PropTypes.object,
    paymentOptions: PropTypes.string,
    duration: PropTypes.number,
    disabled: PropTypes.bool,
    hasName: PropTypes.bool,
    hasDescription: PropTypes.bool,
    isPriceEnabled: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.number,
    totalAmount: PropTypes.number,
    currency: PropTypes.string,
    isStockEmpty: PropTypes.bool,
    DonateButton: PropTypes.object,
    PerkCounter: PropTypes.func,
    handleDonate: PropTypes.func,
    PerkGifts: PropTypes.func,
    handleOpenPopup: PropTypes.func,
    handleClosePopup: PropTypes.func,
    isPopupOpen: PropTypes.bool,
    shouldShowGiftsSection: PropTypes.bool,
    shouldShowCounter: PropTypes.bool,
    shouldShowOpenBtn: PropTypes.bool,
};

export default withStyles(styles)(PerkPopup);
