import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../Text/Text';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Card from 'client/componentLibrary/Card';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        textAlign: 'center',
        padding: spacing(1.5),
        flex: '1 1 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    value: {
        fontSize: '3.25rem',
        lineHeight: 1.2,
        color: palette.text.primary,
        '@media (max-width: 600px)': {
            fontSize: '2.125rem',
            lineHeight: 1.1,
        },
    },
    label: {
        fontSize: '1rem',
        color: palette.text.primary,

        '@media (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
}));

function CountdownWidgetItem({
    className,
    value = '00',
    label = '',
    variant = 'standard',
    fontWeight = 600,
}) {
    const classes = useStyles();
    const rounded = variant === 'rounded';

    return (
        <Card
            backgroundColor={rounded ? 'background.paper' : 'transparent'}
            borderColor={rounded ? 'background.paperBorder' : 'transparent'}
            className={classNames(classes.root, className)}
            style={{ fontWeight }}
        >
            <Text className={classNames(classes.value)}>{value}</Text>
            <Text className={classes.label}>
                <FormattedMessage
                    id={`CountdownWidgetItem.${label.toLowerCase()}`}
                    defaultMessage={`{value, plural, one {${label}} other {${
                        label + 's'
                    }}}`}
                    values={{ value }}
                />
            </Text>
        </Card>
    );
}

CountdownWidgetItem.propTypes = {
    fontWeight: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'rounded']),
};

export default CountdownWidgetItem;
