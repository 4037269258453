import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CountdownWidget from 'client/components/CampaignPage/components/CountdownWidget/CountdownWidget';
import PageSection from 'client/components/CampaignPage/components/PageSection/PageSection';
import {
    selectBonusGoal,
    selectCurrency,
    selectEnableTimer,
    selectEndCampaignTimestamp,
    selectGoal,
    selectHideDonateArea,
    selectHideGoalAmount,
    selectHideProgressBar,
    selectHideRaisedAmount,
    selectHideTime,
    selectProgressGradation,
    selectRatedAmount,
    selectStartCampaignTimestamp,
    selectTotalGoalAmount,
} from 'client/components/CampaignPage/features/campaign/campaignSlice';
import { currencySign } from 'common/helpers';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import AdditionalInfoWidget from '../AdditionalInfoWidget';
import ProgressWidget from '../ProgressWidget';

const useStyles = makeStyles(
    () => ({
        root: {
            padding: 0,
        },
        widget: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        widgetItem: {
            height: '100%',
        },
    }),
    { name: 'DonationWidgets' },
);

function StatsWidget({
    handleTimerStop,
    variant = 'standard',
    showClockGraphic = false,
    multiplier = 1,
}) {
    const classes = useStyles();

    const bonusGoal = useSelector(selectBonusGoal);
    const currency = useSelector(selectCurrency);
    const goal = useSelector(selectGoal);
    const gradation = useSelector(selectProgressGradation);
    const hideGoalAmount = useSelector(selectHideGoalAmount);
    const hideProgressBar = useSelector(selectHideProgressBar);
    const hideRaisedAmount = useSelector(selectHideRaisedAmount);
    const hideDonateArea = useSelector(selectHideDonateArea);
    const ratedAmount = useSelector(selectRatedAmount);
    const totalGoalAmount = useSelector(selectTotalGoalAmount);
    const showProgressWidget = !(
        hideProgressBar &&
        hideGoalAmount &&
        hideRaisedAmount
    );

    const enableTimer = useSelector(selectEnableTimer);
    const endCampaignTimestamp = useSelector(selectEndCampaignTimestamp);
    const hideTime = useSelector(selectHideTime);
    const startCampaignTimestamp = useSelector(selectStartCampaignTimestamp);
    const showCountdownWidget = enableTimer && !hideTime;
    const showInfoWidget = multiplier > 1 && !hideDonateArea;
    const onlyOneSection =
        (showCountdownWidget || showInfoWidget) !== showProgressWidget;
    const fullWidthProgress =
        !showCountdownWidget && showInfoWidget && showProgressWidget;

    if (!(showProgressWidget || showCountdownWidget || showInfoWidget)) {
        return null;
    }

    return (
        <PageSection className={classNames(classes.root, 'stats-widget')}>
            <Box mt={3} />
            <Grid container spacing={2}>
                {showProgressWidget && (
                    <Grid
                        item
                        xs={12}
                        sm={onlyOneSection || fullWidthProgress ? 12 : 7}
                        alignItems="center"
                    >
                        <ProgressWidget
                            amount={Math.round(ratedAmount)}
                            goal={goal}
                            bonusGoal={bonusGoal}
                            totalGoalAmount={totalGoalAmount}
                            currency={currency}
                            currencySign={currencySign(currency)}
                            totalPercent={gradation.totalPercent}
                            goalPercent={gradation.goalPercent}
                            goal1Percent={gradation.goal1Percent}
                            goal2Percent={gradation.goal2Percent}
                            hideProgressBar={hideProgressBar}
                            hideGoalAmount={hideGoalAmount}
                            hideRaisedAmount={hideRaisedAmount}
                        />
                    </Grid>
                )}
                {(showCountdownWidget || showInfoWidget) && (
                    <Grid
                        item
                        xs={12}
                        sm={onlyOneSection || fullWidthProgress ? 12 : 5}
                    >
                        <div className={classes.widget}>
                            {showCountdownWidget && (
                                <CountdownWidget
                                    className={classes.widgetItem}
                                    startAt={startCampaignTimestamp}
                                    date={endCampaignTimestamp}
                                    onStop={handleTimerStop}
                                    hideTime={hideTime}
                                    hideClock={!showClockGraphic}
                                    variant={variant}
                                    fontWeight={500}
                                />
                            )}
                            {showCountdownWidget && showInfoWidget && (
                                <Box mt={2} />
                            )}
                            {showInfoWidget && (
                                <AdditionalInfoWidget
                                    className={classes.widgetItem}
                                    multiplier={multiplier}
                                />
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </PageSection>
    );
}

StatsWidget.propTypes = {
    donationText: PropTypes.string,
    handleTimerStop: PropTypes.func,
    multiplier: PropTypes.number,
    showClockGraphic: PropTypes.bool,
    showDonationText: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'rounded']),
};

export default StatsWidget;
