import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'client/componentLibrary/Card';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    popup: {
        display: 'flex',
        maxWidth: 'unset',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
    },
    popupDialog: {
        borderRadius: spacing(2),
        maxWidth: 400,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: spacing(0.75),
        zIndex: 1,
        '& svg': {
            color: palette.text.primary,
            fontSize: typography.pxToRem(18),
            '@media (max-width: 480px)': {
                fontSize: typography.pxToRem(16),
            },
        },

        'html[dir=rtl] &': {
            right: 'auto',
            left: 0,
        },
    },
}));

const PerkPopup = ({ isPopupOpen, handleClosePopup, children }) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClosePopup}
            aria-labelledby="customized-dialog-title"
            open={isPopupOpen}
            PaperProps={{ classes: { rounded: classes.popupDialog } }}
            fullWidth
        >
            <>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClosePopup}
                >
                    <CloseIcon />
                </IconButton>
                <Card className={classNames(classes.popup)}>{children}</Card>
            </>
        </Dialog>
    );
};

PerkPopup.propTypes = {
    children: PropTypes.node,
    handleClosePopup: PropTypes.func,
    isPopupOpen: PropTypes.bool,
};

export default PerkPopup;
