function getProgressGradation(
    raisedAmount,
    goal,
    bonusGoal,
    hasEnteredBonusGoal,
) {
    const totalGoal = goal + bonusGoal;
    let bonusGoalPercent = 0;
    let goalPercent = (raisedAmount * 100) / goal;

    if (hasEnteredBonusGoal) {
        goalPercent = (goal * 100) / totalGoal;
        bonusGoalPercent = (raisedAmount * 100) / totalGoal;
    }

    return {
        goalPercent,
        bonusGoalPercent,
    };
}

module.exports = {
    getProgressGradation,
};
