import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import VisaIcon from './VisaIcon.png';
import MasterCardIcon from './MasterCardIcon.png';
import DiscoverIcon from './DiscoverIcon.png';
import AmericanExpressIcon from './AmericanExpressIcon.png';
import { ReactComponent as LockIcon } from './LockIcon.svg';
import { useIntl } from 'react-intl';
import { CC_TYPES } from 'common/constants';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {},
    label: {
        marginBottom: spacing(1),
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(12),
        color: palette.text.primary,
        '& svg': {
            marginLeft: 5,
        },
    },
    systems: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            display: 'block',
            marginRight: 9,
            height: 'auto',
            'html[dir=rtl] &': {
                marginRight: 'auto',
                marginLeft: 9,
            },
        },
    },
}));

function SecurePayments(props) {
    const { formatMessage } = useIntl();
    const { className, cardsToHide } = props;
    const classes = useStyles();

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <div className={classes.label}>
                {formatMessage({
                    id: 'DonationForm.securePayments',
                })}{' '}
                <LockIcon />
            </div>
            <div className={classes.systems}>
                {!cardsToHide?.includes(CC_TYPES.VISA) && (
                    <img
                        src={VisaIcon}
                        alt={formatMessage({
                            id: 'securePayments.visa',
                            defaultMessage: 'Visa',
                        })}
                        width={39.5}
                    />
                )}
                {!cardsToHide?.includes(CC_TYPES.MASTERCARD) && (
                    <img
                        src={MasterCardIcon}
                        alt={formatMessage({
                            id: 'securePayments.mastercard',
                            defaultMessage: 'MasterCard',
                        })}
                        width={43}
                    />
                )}
                {!cardsToHide?.includes(CC_TYPES.DISCOVER) && (
                    <img
                        src={DiscoverIcon}
                        alt={formatMessage({
                            id: 'securePayments.discover',
                            defaultMessage: 'Discover',
                        })}
                        width={34}
                    />
                )}
                {!cardsToHide?.includes(CC_TYPES.AMERICAN_EXPRESS) && (
                    <img
                        src={AmericanExpressIcon}
                        alt={formatMessage({
                            id: 'securePayments.americanExpress',
                            defaultMessage: 'American Express',
                        })}
                        width={31}
                    />
                )}
            </div>
        </div>
    );
}

SecurePayments.propTypes = {
    className: PropTypes.string,
    cardsToHide: PropTypes.array,
};

export default SecurePayments;
