import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import FooterLinkGroup from '../FooterLinkGroup/FooterLinkGroup';
import FooterSocialLinks from '../FooterSocialLinks/FooterSocialLinks';
import PageSection from '../PageSection/PageSection';
import classNames from 'classnames';
import { isLightColor } from '../../../../helpers/colorUtils';

const useStyles = makeStyles(
    ({ palette, spacing, zIndex }) => ({
        root: {
            paddingTop: spacing(2),
            paddingBottom: spacing(11),
            width: '100%',
            backgroundColor: palette.background.default,
            zIndex: zIndex.appBar + 1,

            '@media (max-width: 600px)': {
                paddingBottom: spacing(7),
            },
        },
        noWave: {
            paddingTop: spacing(3),
            borderTop: `1px solid ${palette.background.paperBorder}`,
        },
        inner: {
            display: 'flex',
            justifyContent: 'space-between',

            '@media (max-width: 600px)': {
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
            },
        },

        links: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',

            '@media (max-width: 600px)': {
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: '30px 15px 0',
            },
        },

        copyright: {
            color: palette.text.primary,
            marginTop: 20,
            '@media (max-width: 600px)': {
                marginTop: 5,
            },
        },

        wave: {
            fill: palette.background.default,
            filter: `drop-shadow(0px -25px 15px ${
                isLightColor(palette.background.default)
                    ? 'rgba(0, 0, 0, 0.04)'
                    : 'rgba(255, 255, 255, 0.5)'
            })`,
            zIndex: zIndex.appBar,
        },
    }),
    { name: 'PageFooter' },
);

function PageFooter({ className, lang, showWave = true }) {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <>
            {showWave && (
                <svg
                    id="wave"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 75"
                    className={classes.wave}
                >
                    <path
                        fillOpacity="1"
                        d="M 0,23.150723 60,15.429807 C 120,7.708889 240,-7.7329446 360,5.151335 c 120,12.691258 240,54.19119 360,59.16153 120,5.163364 240,-25.720306 360,-35.998777 120,-10.471495 240,0.144766 300,5.115107 l 60,5.163364 V 100.3599 h -60 c -60,0 -180,0 -300,0 -120,0 -240,0 -360,0 -120,0 -240,0 -360,0 -120,0 -240,0 -300,0 H 0 Z"
                    />
                </svg>
            )}
            <footer
                className={classNames(
                    classes.root,
                    { [classes.noWave]: !showWave },
                    className,
                )}
            >
                <PageSection component="div">
                    <div className={classes.inner}>
                        <FooterSocialLinks lang={lang} />
                        <div className={classes.links}>
                            <FooterLinkGroup
                                header={formatMessage({ id: 'Footer.about' })}
                                links={[
                                    {
                                        content: formatMessage({
                                            id: 'Footer.causematch',
                                        }),
                                        href: `https://www.causematch.com/${
                                            lang == 'he' ? lang + '/' : ''
                                        }features-and-services/?ref=cm2`,
                                    },
                                    {
                                        content: formatMessage({
                                            id: 'Footer.jobs',
                                        }),
                                        href: `https://causematch.com/${
                                            lang == 'he' ? lang + '/' : ''
                                        }careers/?ref=cm2`,
                                    },
                                ]}
                            />
                            <FooterLinkGroup
                                header={formatMessage({ id: 'Footer.support' })}
                                links={[
                                    {
                                        content: formatMessage({
                                            id: 'Footer.contact',
                                        }),
                                        href: 'mailto:hello@causematch.com',
                                    },
                                    {
                                        content: '02-302-4099',
                                        href: 'tel:023024099',
                                        dir: 'ltr',
                                    },
                                    {
                                        content: '+1 (917) 3414586',
                                        href: 'tel:+19173414586',
                                        dir: 'ltr',
                                    },
                                ]}
                            />
                            <FooterLinkGroup
                                header={formatMessage({ id: 'Footer.help' })}
                                links={[
                                    {
                                        content: formatMessage({
                                            id: 'Footer.faq',
                                        }),
                                        href: `https://www.causematch.com/faq/?ref=cm2`,
                                    },
                                    {
                                        content: formatMessage({
                                            id: 'Footer.terms',
                                        }),
                                        href: `https://www.causematch.com/${
                                            lang == 'he' ? lang + '/' : ''
                                        }terms-of-service/?ref=cm2`,
                                    },
                                    {
                                        content: formatMessage({
                                            id: 'Footer.privacy',
                                        }),
                                        href: `https://www.causematch.com/${
                                            lang == 'he' ? lang + '/' : ''
                                        }privacy-policy/?ref=cm2`,
                                    },
                                ]}
                            />
                            <FooterLinkGroup
                                header={formatMessage({ id: 'Footer.hello' })}
                                links={[
                                    {
                                        content: formatMessage({
                                            id: 'Footer.blog',
                                        }),
                                        href: 'https://blog.causematch.com/?ref=cm2',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <Typography variant="body1" className={classes.copyright}>
                        © CauseMatch {new Date().getUTCFullYear()} All Rights
                        Reserved
                    </Typography>
                </PageSection>
            </footer>
        </>
    );
}

PageFooter.propTypes = {
    className: PropTypes.string,
    lang: PropTypes.string,
    showWave: PropTypes.bool,
};

export default PageFooter;
