const uuid = require('uuid/v4');

const TRACK_ID_HEADER_NAME = 'X-Cm-Track-Id';
const UUID_REGEX =
    /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}/i;

function uuidMatch(value) {
    return value?.match(UUID_REGEX)?.[0];
}

function setTrackId(trackId) {
    window.localStorage.setItem(TRACK_ID_HEADER_NAME, trackId);
    return trackId;
}

function resetTrackId() {
    return setTrackId(uuid());
}

function getTrackId() {
    let trackId = uuidMatch(window.localStorage.getItem(TRACK_ID_HEADER_NAME));
    if (!trackId) {
        trackId = resetTrackId();
    }
    return trackId;
}

function getTrackIdFromHeaders(headers) {
    return uuidMatch(
        headers[TRACK_ID_HEADER_NAME] ||
            headers[TRACK_ID_HEADER_NAME.toLowerCase()],
    );
}

module.exports = {
    TRACK_ID_HEADER_NAME,
    setTrackId,
    getTrackId,
    getTrackIdFromHeaders,
    resetTrackId,
};
