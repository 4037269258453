import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Text from 'client/components/CampaignPage/components/Text/Text';
import ItemCardsGrid from 'client/components/CampaignPage/components/ItemCardsGrid/ItemCardsGrid';
import DonationTabContentSearch from 'client/components/CampaignPage/components/DonationTabContentSearch/DonationTabContentSearch';
import MoreButton from 'client/components/CampaignPage/components/MoreButton/MoreButton';
import DonationTabContent from 'client/components/CampaignPage/components/DonationTabContent/DonationTabContent';
import Loader from 'client/components/Loader';
import { useIntl } from 'react-intl';
import useAutoLoad from '../../features/recentDonationPanel/useAutoLoad';
import { SORT_LAYER_ITEMS } from '../../features/recentDonationPanel/constants';
import { useSelector } from 'react-redux';
import { selectPageDesign } from '../../features/campaign/campaignSlice';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';
import RdpTabContentSearch from '../RdpTabContentSearch';

const useStyles = makeStyles(({ spacing }) => ({
    root: {},

    moreBtn: {
        display: 'block',
        margin: `${spacing(2)}px auto`,
    },
}));

function LayersTabContent(props) {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const pageDesign = useSelector(selectPageDesign);

    const {
        item: Item,
        emptyText = formatMessage({ id: 'RDP.emptyLayerList' }),
        loadMoreText = formatMessage({ id: 'RDP.loadMore' }),
        columns = 3,
        sortOptions = {
            [SORT_LAYER_ITEMS.NAME_ASC]: formatMessage({
                id: 'SortBy.name.asc',
            }),
            [SORT_LAYER_ITEMS.NAME_DESC]: formatMessage({
                id: 'SortBy.name.desc',
            }),
            [SORT_LAYER_ITEMS.MOST_RAISED]: formatMessage({
                id: 'SortBy.donationsAmount.desc',
            }),
            [SORT_LAYER_ITEMS.LAST_ADDED]: formatMessage({
                id: 'SortBy.lastAdded',
            }),
        },
        query,
        items,
        isLoading,
        isEndCollection,
        allowAutoLoad,
        handleQueryChange,
        handleAppendNextPage,
        handleAutoLoad,
        listRef,
        startScrollTop = 0,
    } = props;

    const SearchComponent =
        pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN
            ? RdpTabContentSearch
            : DonationTabContentSearch;

    useAutoLoad(
        allowAutoLoad && !isLoading && !isEndCollection,
        handleAutoLoad,
    );

    useEffect(() => {
        if (listRef && listRef.current) {
            listRef.current.scrollTop = startScrollTop;
        }
    }, []);

    return (
        <DonationTabContent>
            <SearchComponent
                disabled={isLoading}
                order={query.order}
                searchText={query.searchText}
                sortOptions={sortOptions}
                onChange={handleQueryChange}
            />
            <ItemCardsGrid listRef={listRef} columns={columns}>
                {items.length
                    ? items.map(item => (
                          <Item
                              key={item.id * Math.random()}
                              item={item}
                              listRef={listRef}
                              currency={item.currency}
                          />
                      ))
                    : !isLoading && <Text>{emptyText}</Text>}
            </ItemCardsGrid>
            <Loader
                isLoading={!isEndCollection && (allowAutoLoad || isLoading)}
            />
            {!allowAutoLoad && !isLoading && !isEndCollection && (
                <MoreButton
                    square={pageDesign === CAMPAIGN_PAGE_DESIGNS.CLASSIC}
                    color={
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN
                            ? 'accent'
                            : 'primary'
                    }
                    variant={
                        pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN
                            ? 'outlined'
                            : 'contained'
                    }
                    disabled={isLoading}
                    onClick={handleAppendNextPage}
                    className={classes.moreBtn}
                >
                    {loadMoreText}
                </MoreButton>
            )}
        </DonationTabContent>
    );
}

LayersTabContent.propTypes = {
    item: PropTypes.func.isRequired,
    emptyText: PropTypes.string,
    loadMoreText: PropTypes.string,
    columns: PropTypes.number,
    sortOptions: PropTypes.object,
    query: PropTypes.object,
    items: PropTypes.array,
    isLoading: PropTypes.bool,
    isEndCollection: PropTypes.bool,
    allowAutoLoad: PropTypes.bool,
    handleQueryChange: PropTypes.func,
    handleAppendNextPage: PropTypes.func,
    handleAutoLoad: PropTypes.func,
    listRef: PropTypes.object,
    startScrollTop: PropTypes.number,
};

export default LayersTabContent;
