import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Checkbox from 'client/components/CampaignPage/components/Checkbox/Checkbox';
import DoubleLineHeading from 'client/components/CampaignPage/components/DoubleLineHeading/DoubleLineHeading';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import { ReactComponent as EmailIcon } from 'client/components/CampaignPage/components/FormInput/EmailIcon.svg';
import FormInput from 'client/components/CampaignPage/components/FormInput/FormInput';
import { ReactComponent as HomeIcon } from 'client/components/CampaignPage/components/FormInput/HomeIcon.svg';
import { ReactComponent as PhoneIcon } from 'client/components/CampaignPage/components/FormInput/PhoneIcon.svg';
import { ReactComponent as UserIcon } from 'client/components/CampaignPage/components/FormInput/UserIcon.svg';
import { ReactComponent as EditIcon } from 'client/components/CampaignPage/components/FormTextarea/EditIcon.svg';
import FormTextarea from 'client/components/CampaignPage/components/FormTextarea/FormTextarea';
import LinkedText from 'client/components/CampaignPage/components/LinkedText/LinkedText';
import PaymentMethodCurrencyWidget from 'client/components/CampaignPage/components/PaymentMethodCurrencyWidget/PaymentMethodCurrencyWidget';
import StepHeading from 'client/components/CampaignPage/components/StepHeading/StepHeading';
import Text from 'client/components/CampaignPage/components/Text/Text';
import TipWidget from 'client/components/CampaignPage/components/TipWidget/TipWidget';
import Toggle from 'client/components/CampaignPage/components/Toggle/Toggle';
import TotalAmount from 'client/components/CampaignPage/components/TotalAmount/TotalAmount';
import config from 'client/config';
import filterQueryParams from 'client/helpers/filterQueryParams';
import focusElement from 'client/helpers/focusElement';
import { getNedarimConstants } from 'client/helpers/nedarim';
import { paymeValidator } from 'client/helpers/paymeValidator';
import api from 'client/services/api';
import {
    PAYMENT_GATEWAYS,
    PAYMENT_METHODS,
    PAYMENT_MODES,
    UNLIMITED_RECURRING_EFFECTIVE_MONTHS,
} from 'common/constants';
import { currencySign, getSingleDonationAmount } from 'common/helpers';
import { donationFlow } from 'common/helpers/donation';
import { paymentMethodTipping as enableTippingForPaymentMethod } from 'common/helpers/paymentMethods';
import { convertPaymentModeForBackEnd } from 'common/helpers/paymentMode';
import {
    getDefaultPaymentMethod,
    getPaymentConfig,
} from 'common/helpers/payments';
import { getOjcFundExpiry } from 'common/helpers/vouchers';
import { get, isEmpty, omit } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CustomFormInput from '../../components/CustomFormInput/CustomFormInput';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import InstallmentsWidget from '../../components/InstallmentsWidget/InstallmentsWidget';
import RecurringWidget from '../../components/RecurringWidget/RecurringWidget';
import withController from '../../hocs/withController';
import useCloseTab from '../../hooks/closeTab';
import useFormStyling from '../../hooks/formStyling';
import usePostMessage from '../../hooks/postMessage';
import useTimeOnPage from '../../hooks/timeOnPage';
import {
    selectCheckoutData,
    selectCurrencies,
    selectDonorPflSourceId,
    selectMatchRatio,
    selectPageName,
    selectPageSpecificCampaignData,
    selectPayments,
    selectSelectedCampaign,
    selectSelectedLayerItem,
    selectAutoScrollSettings,
} from '../campaign/campaignSlice';
import {
    fetchLayers,
    selectDonationLayers,
    selectLayersIsFetched,
    selectTabs,
} from '../recentDonationPanel/recentDonationPanelSlice';
import BanquestCardInfo from './BanquestCardInfo';
import CardknoxCardInfo from './CardknoxCardInfo';
import DonateButton from './DonateButton';
import DonationFormDefaultFields from './DonationFormDefaultFields';
import GiftFields from './GiftFields';
import LayerItemsSelector from './LayerItemsSelector';
import NedarimCardInfo from './NedarimCardInfo';
import NmiCardInfo from './NmiCardInfo';
import PEFTerms from './PEFTerms';
import PaymeCardInfo from './PaymeCardInfo';
import PaymentError from './PaymentError';
import PaymentMethodSelector from './PaymentMethodSelector';
import PaymentModeSelector from './PaymentModeSelector';
import SuggestedAmounts from './SuggestedAmounts';
import { useStripeContext } from './StripeProvider';
import StripeCardInfo from './StripeCardInfo';
import { selectDonationForm } from './checkoutPopupSlice';
import {
    paymentFailed,
    paymentIterationComplete,
    resetGiving,
    selectAlert,
    selectAutoFilledValues,
    selectBrowserSupport,
    selectCustomFields,
    selectDefaultFields,
    selectDefaultPaymentMode,
    selectDefaultTipPercent,
    selectDonationAmount,
    selectDonationCurrency,
    selectDonationPaymentMethod,
    selectDonationTier,
    selectEnableTipping,
    selectError,
    selectFee,
    selectFiscalSponsorConfig,
    selectGivingAmount,
    selectGivingCount,
    selectGivingId,
    selectGivingPaymentOptions,
    selectInstallmentMonths,
    selectIsGatewayRedirect,
    selectIsTestMode,
    selectMatchedDonor,
    selectMinDonationAmount,
    selectPayment,
    selectPaymentGateway,
    selectPaymentProcess,
    selectRecurringMonths,
    selectTipPercents,
    setGatewayRedirect,
    setPaymentAlert,
    startPaymentProcess,
    updateDonationAmount,
    updateDonationCurrency,
    updateDonationPaymentMethod,
    updateInstallmentMonths,
    updateRecurringMonths,
} from './checkoutSlice';
import validationSchema from './donationFormValidationSchema';
import PaymentAlert from './PaymentAlert';
import { sendAnalyticsEvent } from '../../../../services/analytics';
import IdealBankSelector from './StripeIdealBankSelector';
import CustomLogicWidget from './CustomLogicWidget/CustomLogicWidget';

const ControlledFormInput = withController(FormInput);

function smallFormatter(...chunks) {
    const style = { fontWeight: 400 };
    return <small style={style}>{chunks}</small>;
}

function linkFormatter(href) {
    // eslint-disable-next-line
    return (...chunks) => <a href={href}>{chunks}</a>;
}

function createDonations(pageName, data) {
    const layerItems = data.layerItem ? { ...data.layerItem } : null;

    if (!layerItems) {
        return [createDonation(pageName, data)];
    }

    let donations = [];
    for (let layerIndex in layerItems) {
        donations.push(
            createDonation(pageName, {
                ...data,
                layerItem: layerItems[layerIndex],
                layerItemsCount: Object.keys(layerItems).length,
            }),
        );
    }

    return donations;
}

/**
 * Checkout form data adaptation for server side API
 * @param {string} pageName Currnt page name
 * @param {{}} data
 */
function createDonation(pageName, data) {
    const layerItem = data.layerItem ? { ...data.layerItem } : null;
    let paymentMode = convertPaymentModeForBackEnd(data.paymentMode);

    if (layerItem) {
        layerItem.id = Number(layerItem.value);
    }

    if (data.givingId && data.givingOptions.type === 'recurring') {
        paymentMode = 'monthly';
    }

    const { filteredQueryObject: utmData } = filterQueryParams(
        document.location.search,
    );

    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        accountNumber: data.accountNumber,
        donorsFundDonor: data.donorsFundDonor,
        donorsFundDonorAuth: data.donorsFundDonorAuth,
        ojcFundCard: data.ojcFundCard,
        ojcFundExpiry: getOjcFundExpiry(data.ojcFundExpiry),
        phonePrefix: data.phone && data.phonePrefix ? data.phonePrefix : null,
        phone: data.phone,
        address_1: data.address1,
        address_2: data.address2,
        country: data.country,
        state: data.state,
        city: data.city,
        zip: data.zip,
        isAnonymous: data.isAnonymous,
        giftAid: data.giftAid,
        taxGift: data.taxGift,
        citizenId: data?.citizenId,
        paymentMode,
        months: data.months,
        monthly:
            ['card', 'googlepay', 'applepay', 'cashapp'].includes(
                data.paymentMethod,
            ) && ['installments', 'recurring'].includes(data.paymentMode),
        comment: data.comment,
        meta: {},
        page: pageName,
        amount: getSingleDonationAmount(data.amount, data.layerItemsCount),
        totalAmount: data.amount,
        currency: data.currency,
        paymentMethod: data.paymentMethod,
        layerItem,
        tipPercent: data.tipPercent,
        displayName: data.displayName || null,
        customFields: data?.customFields,
        givingId: data?.givingId || null,
        givingCount: data?.givingCount || 0,
        utmData: utmData,
    };
}

function getLayersNames(formatMessage, layers, locale) {
    return layers.reduce((names, layer, index) => {
        const name = get(layer, ['locales', locale, 'name'], layer.name);

        if (index === 0) {
            names += name;
        } else if (layers.length === index + 1) {
            names += ` ${formatMessage({
                id: 'common.or',
                defaultMessage: 'or',
            })} ${name}`;
        } else {
            names += `, ${name}`;
        }

        return names;
    }, '');
}

function calculateDefaultMonthsValue(
    payment,
    paymentMode,
    recurringMonths,
    installmentMonths,
) {
    if (!paymentMode) {
        return 0;
    }
    if (paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING) {
        return Number(UNLIMITED_RECURRING_EFFECTIVE_MONTHS);
    } else if (paymentMode === PAYMENT_MODES.RECURRING) {
        if (payment.lockRecurringMonths) {
            return Number(payment.recurringDefaultMonths || 12);
        }

        return Number(recurringMonths);
    } else {
        return Number(installmentMonths);
    }
}

function getLayerItemSelectorStep(paymentMethod, paymentGateway) {
    if (
        [PAYMENT_METHODS.CARD, PAYMENT_METHODS.IDEAL].includes(paymentMethod) &&
        paymentGateway !== PAYMENT_GATEWAYS.YAADPAY
    ) {
        return 4;
    }
    return 3;
}

function DonationForm(props) {
    const preservedValues = useRef();
    const { onDonateSuccess, onDonateFailed } = props;
    const [banquestInstance, setBanquestInstance] = useState(null);
    const [paymeInstance, setPaymeInstance] = useState(null);
    const successSubmitted = useRef(false);
    const paymeTokenGenerated = useRef(false);
    const isAbandonedDonationLogged = useRef(false);
    const [cardknoxCardToken, setCardknoxCardToken] = useState(null);
    const [cardknoxCvvToken, setCardknoxCvvToken] = useState(null);
    const [cardknoxExp, setCardknoxExp] = useState(null);
    const [cardknoxCardState, setCardknoxCardState] = useState(null);
    const [cardknoxCvvState, setCardknoxCvvState] = useState(null);
    const [cardknoxExpState, setCardknoxExpState] = useState(null);
    const nmiTokenData = useRef({ status: 'blank', token: null });
    const gatewayErrorCount = useRef(1);
    const classes = useStyles();
    const dispatch = useDispatch();
    const stripeContext = useStripeContext();
    const { formatMessage, locale } = useIntl();
    const browserSupport = useSelector(selectBrowserSupport);
    const { name: campaignName } = useSelector(selectPageSpecificCampaignData);
    const {
        text,
        heading,
        layerSelectorMainText,
        layerSelectorSubText,
        deferMultiLayerChoice,
        commentLabel,
    } = useSelector(selectCheckoutData);
    const defaultValues = useSelector(selectDonationForm);
    const multiplier = useSelector(selectMatchRatio);
    const amount = useSelector(selectDonationAmount);
    const givingId = useSelector(selectGivingId);
    const givingCount = useSelector(selectGivingCount);
    const givingAmount = useSelector(selectGivingAmount);
    const givingOptions = useSelector(selectGivingPaymentOptions);
    const matchedDonation = useSelector(selectMatchedDonor);
    const donorPflSourceId = useSelector(selectDonorPflSourceId);
    const recurringMonths = useSelector(selectRecurringMonths);
    const installmentMonths = useSelector(selectInstallmentMonths);
    const currencies = useSelector(selectCurrencies);
    const defaultCurrency = useSelector(selectDonationCurrency);
    const defaultPaymentMethod = useSelector(selectDonationPaymentMethod);
    const paymentGateway = useSelector(selectPaymentGateway);
    const fiscalSponsorConfig = useSelector(selectFiscalSponsorConfig);
    const campaign = useSelector(selectSelectedCampaign);
    const pageName = useSelector(selectPageName);
    const payments = useSelector(selectPayments); //all currencies/gateways
    const payment = useSelector(selectPayment); //selected currency
    const fee = useSelector(selectFee);
    const enableTippingForGateway = useSelector(selectEnableTipping);
    const defaultTipPercent = useSelector(selectDefaultTipPercent);
    const tippingCheckoutText = payment.tippingCheckoutText;
    const tippingThankYouText = payment.tippingThankYouText;
    const tippingPlatformText = payment.tippingPlatformText;
    const tipPercents = useSelector(selectTipPercents);
    const testMode = useSelector(selectIsTestMode);
    const paymentError = useSelector(selectError);
    const paymentAlert = useSelector(selectAlert);
    const { status } = useSelector(selectPaymentProcess);
    const isGatewayRedirect = useSelector(selectIsGatewayRedirect);
    const customFields = useSelector(selectCustomFields);
    const defaultFields = useSelector(selectDefaultFields);
    const defaultPaymentMode = useSelector(selectDefaultPaymentMode);
    const LayersIsFetched = useSelector(selectLayersIsFetched);
    const tabs = useSelector(selectTabs);
    const isCustomFieldsNotEmpty = Object.keys(customFields).length > 0;
    const minDonation = useSelector(selectMinDonationAmount);
    const donationLayers = useSelector(selectDonationLayers);
    const autoFilledValues = useSelector(selectAutoFilledValues);
    const donationTier = useSelector(selectDonationTier);
    const autoScrollSettings = useSelector(selectAutoScrollSettings);
    const donationLayersNames = getLayersNames(
        formatMessage,
        donationLayers,
        locale,
    );
    const { getMessageResponse } = usePostMessage(getNedarimConstants());
    const formData = useForm({
        resolver: yupResolver(
            validationSchema(
                formatMessage,
                paymentGateway,
                fiscalSponsorConfig,
                minDonation,
                customFields,
                defaultFields,
                donationLayersNames,
            ),
        ),
        mode: 'onTouched',
        defaultValues: {
            ...defaultValues,
            ...autoFilledValues,
            months: calculateDefaultMonthsValue(
                payment,
                autoFilledValues.paymentMode || defaultValues.paymentMode,
                autoFilledValues.months || payment.recurringDefaultMonths || 12,
                autoFilledValues.months || payment.installmentDefaultMonths,
            ),
            paymentMode: defaultPaymentMode,
            amount,
            currency: defaultCurrency,
            paymentMethod: defaultPaymentMethod,
            tipPercent: defaultTipPercent,
            donorsFundDonor: '',
            donorsFundDonorAuth: '',
            ojcFundCard: '',
            ojcFundExpiry: '',
        },
    });
    const {
        errors,
        handleSubmit,
        register,
        watch,
        getValues,
        setValue,
        trigger,
        formState,
        control,
    } = formData;
    const [getFormClass, getFormIcon] = useFormStyling(
        formState.dirtyFields,
        errors,
        getValues,
    );
    const pageLayerItem = useSelector(selectSelectedLayerItem);

    const onDonationButtonReady = () => {
        if (autoFilledValues.autoFilled) {
            const validateFields = Object.keys(
                omit(autoFilledValues, [
                    'paymentMode',
                    'paymentMethod',
                    'amount',
                ]),
            );
            trigger(validateFields);
        }
    };

    const { paymentMethod, paymentMode, currency } = watch();
    const handlePaymentModeChange = value => () => {
        const isMonthsDirty = 'months' in formState.dirtyFields;

        setValue('paymentMode', value, { shouldValidate: true });

        switch (value) {
            case 'installments':
            case 'recurring':
            case 'unlimitedRecurring':
                if (!isMonthsDirty) {
                    setDefaultMonthsValue(getValues('amount'), value);
                } else {
                    setMonthsValue(getValues('amount'), value);
                }
                break;
            default:
                setValue('months', 0, { shouldDirty: false });
                break;
        }
    };
    const handlePaymentMethodChange = paymentMethod => () => {
        if (paymentMode !== 'regular') {
            handlePaymentModeChange('regular')();
        }
        dispatch(updateDonationPaymentMethod(paymentMethod));
        setValue('paymentMethod', paymentMethod, { shouldValidate: true });
        setValue('giftAid', false);
        setValue('taxGift', false);

        const tipPercent = enableTippingForGateway
            ? Number(defaultTipPercent) || 0
            : 0;
        setValue(
            'tipPercent',
            enableTippingForPaymentMethod(paymentMethod) ? tipPercent : 0,
        );
    };
    const handleCurrencyChange = useCallback(
        currency => {
            dispatch(updateDonationCurrency(currency));
            setValue('currency', currency, { shouldDirty: true });

            const excludedPaymentMethods = [];
            if (!browserSupport.googlePay) {
                excludedPaymentMethods.push(PAYMENT_METHODS.GOOGLE_PAY);
            }
            if (!browserSupport.applePay) {
                excludedPaymentMethods.push(PAYMENT_METHODS.APPLE_PAY);
            }
            const currencyPaymentMethod = getDefaultPaymentMethod(
                payments,
                currency,
                { excludedPaymentMethods },
            );

            const paymentConfig = getPaymentConfig(
                payments[currency],
                currencyPaymentMethod,
            );
            const tipPercent = Number(defaultTipPercent) || 0;
            setValue('tipPercent', tipPercent || 0);
            setValue('giftAid', false);
            setValue('taxGift', false);

            const options = paymentConfig;
            if (options) {
                const allowedPaymentModes = [
                    options.allowSinglePayment ? 'regular' : false,
                    options.allowInstallments ? 'installments' : false,
                    options.allowRecurring ? 'recurring' : false,
                    options.allowUnlimitedrecurring
                        ? 'unlimitedRecurring'
                        : false,
                ].filter(Boolean);

                if (!allowedPaymentModes.includes(paymentMode)) {
                    requestAnimationFrame(() => {
                        handlePaymentModeChange('regular')();
                    });
                }

                const isRecurring =
                    getValues('paymentMode') === PAYMENT_MODES.RECURRING;
                if (isRecurring && options.lockRecurringMonths) {
                    const months = Number(options.recurringDefaultMonths || 12);
                    setValue('months', months);
                    dispatch(updateRecurringMonths(months));
                }
            }

            if (paymentMethod !== currencyPaymentMethod) {
                handlePaymentMethodChange(currencyPaymentMethod)();
            }
        },
        [currency, paymentMethod, paymentMode],
    );
    const handleMonthsChange = eventValue => {
        const paymentMode = getValues('paymentMode');
        const value = Number(eventValue);
        setValue('months', value, {
            shouldValidate: true,
            shouldDirty: true,
        });
        paymentMode === 'recurring' || paymentMode === 'unlimitedRecurring'
            ? dispatch(updateRecurringMonths(value))
            : dispatch(updateInstallmentMonths(value));
    };
    const setDefaultMonthsValue = (
        amount,
        paymentMode = getValues('paymentMode'),
    ) => {
        const isMonthsDirty = 'months' in formState.dirtyFields;
        let monthsValue;

        // ensure that currency, payment method, and payment config are correct
        const currency = getValues('currency');
        const paymentMethod = getValues('paymentMethod');
        const payment = getPaymentConfig(payments[currency], paymentMethod);

        if (paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING) {
            monthsValue = UNLIMITED_RECURRING_EFFECTIVE_MONTHS;
        } else if (paymentMode === PAYMENT_MODES.RECURRING) {
            monthsValue = payment.recurringDefaultMonths || 12;
        } else {
            monthsValue = payment.installmentDefaultMonths;
        }

        if (!isMonthsDirty) {
            setValue('months', monthsValue, { shouldDirty: false });
        }
    };

    const setMonthsValue = (amount, paymentMode = getValues('paymentMode')) => {
        // ensure that currency, payment method, and payment config are correct
        const currency = getValues('currency');
        const paymentMethod = getValues('paymentMethod');
        const payment = getPaymentConfig(payments[currency], paymentMethod);

        const monthsValue = calculateDefaultMonthsValue(
            payment,
            paymentMode,
            recurringMonths,
            installmentMonths,
        );
        setValue('months', monthsValue, { shouldDirty: false });
    };

    const handleAmountChange = amount => {
        dispatch(updateDonationAmount(amount));
        setValue('amount', amount, { shouldValidate: true, shouldDirty: true });
    };
    const handleAmountBlur = () => {};
    const handleLayerItemsChange = layerItems => {
        setValue('layerItems', layerItems);
        trigger('amount');
    };
    const handleTipPercentChange = tipPercent =>
        setValue('tipPercent', tipPercent);

    const renderPaymentMethodContent = useCallback(
        paymentMethod => {
            switch (paymentMethod) {
                case PAYMENT_METHODS.PAYPAL:
                case PAYMENT_METHODS.APPLE_PAY:
                case PAYMENT_METHODS.GOOGLE_PAY:
                case PAYMENT_METHODS.DONORSFUND:
                case PAYMENT_METHODS.OJCFUND:
                case PAYMENT_METHODS.PLEDGER:
                case PAYMENT_METHODS.MATBIA:
                case PAYMENT_METHODS.CARD:
                case PAYMENT_METHODS.CASHAPP:
                case PAYMENT_METHODS.ASSERBISHVIL:
                case PAYMENT_METHODS.BROOM:
                case PAYMENT_METHODS.BELZ:
                case PAYMENT_METHODS.CMZ:
                case PAYMENT_METHODS.KOLYOM:
                case PAYMENT_METHODS.MHTRUST:
                case PAYMENT_METHODS.YADSHLOMO:
                case PAYMENT_METHODS.ZOREYATZEDOKOS:
                    return (
                        <PaymentModeSelector
                            onChange={handlePaymentModeChange}
                            value={getValues('paymentMode')}
                        />
                    );
            }
        },
        [paymentMode],
    );

    const renderVoucherFields = useCallback(
        paymentMethod => {
            switch (paymentMethod) {
                case PAYMENT_METHODS.AAC:
                case PAYMENT_METHODS.ASSERBISHVIL:
                case PAYMENT_METHODS.BROOM:
                case PAYMENT_METHODS.BELZ:
                case PAYMENT_METHODS.CMZ:
                case PAYMENT_METHODS.KOLYOM:
                case PAYMENT_METHODS.MHTRUST:
                case PAYMENT_METHODS.YADSHLOMO:
                case PAYMENT_METHODS.ZOREYATZEDOKOS:
                    return (
                        <FormGroup>
                            <ControlledFormInput
                                type="text"
                                name="accountNumber"
                                required
                                placeholder={formatMessage({
                                    id: 'Checkout.accountNumber',
                                })}
                                ref={register}
                                error={errors.accountNumber}
                                className={getFormClass('accountNumber')}
                                endIcon={getFormIcon('accountNumber')}
                            />
                            <FormInputHelperText error={errors.accountNumber} />
                        </FormGroup>
                    );
                case PAYMENT_METHODS.DONORSFUND:
                    return (
                        <FormGroup>
                            <ControlledFormInput
                                type="text"
                                name="donorsFundDonor"
                                required
                                placeholder={formatMessage({
                                    id: 'Checkout.givingCardNumber',
                                })}
                                ref={register}
                                error={errors.donorsFundDonor}
                                className={getFormClass('donorsFundDonor')}
                                endIcon={getFormIcon('donorsFundDonor')}
                            />
                            <FormInputHelperText
                                error={errors.donorsFundDonor}
                            />
                            <ControlledFormInput
                                type="text"
                                name="donorsFundDonorAuth"
                                required
                                placeholder={formatMessage({
                                    id: 'Checkout.givingCardCvv',
                                })}
                                ref={register}
                                error={errors.donorsFundDonorAuth}
                                className={getFormClass('donorsFundDonorAuth')}
                                endIcon={getFormIcon('donorsFundDonorAuth')}
                            />
                            <FormInputHelperText
                                error={errors.donorsFundDonorAuth}
                            />
                        </FormGroup>
                    );
                case PAYMENT_METHODS.OJCFUND:
                    return (
                        <FormGroup>
                            <ControlledFormInput
                                type="text"
                                name="ojcFundCard"
                                required
                                placeholder={formatMessage({
                                    id: 'Checkout.ojcCardNumber',
                                })}
                                ref={register}
                                error={errors.ojcFundCard}
                                className={getFormClass('ojcFundCard')}
                                endIcon={getFormIcon('ojcFundCard')}
                            />
                            <FormInputHelperText error={errors.ojcFundCard} />
                            <ControlledFormInput
                                type="text"
                                name="ojcFundExpiry"
                                required
                                placeholder={formatMessage({
                                    id: 'Checkout.ojcCardExpiry',
                                })}
                                ref={register}
                                error={errors.ojcFundExpiry}
                                className={getFormClass('ojcFundExpiry')}
                                endIcon={getFormIcon('ojcFundExpiry')}
                            />
                            <FormInputHelperText error={errors.ojcFundExpiry} />
                        </FormGroup>
                    );
            }
        },
        [errors],
    );

    const renderPaymentFields = useCallback(
        (paymentGateway, paymentMethod) => {
            switch (paymentMethod) {
                case 'card':
                case 'pledger':
                case 'matbia':
                    return renderCardFields(paymentGateway);
                case 'ideal':
                    return renderIdealBankSelector();
                default:
                    return null;
            }
        },
        [paymentGateway, paymentMethod, errors, control],
    );

    const renderIdealBankSelector = () => {
        return (
            <>
                <StepHeading index={3}>
                    <FormattedMessage id="Checkout.stripe.idealBankSelector" />
                </StepHeading>
                <IdealBankSelector
                    control={control}
                    dirtyFields={formState.dirtyFields}
                    errors={errors}
                />
            </>
        );
    };

    const renderCardFields = paymentGateway => {
        switch (paymentGateway) {
            case 'stripe':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <StripeCardInfo
                            control={control}
                            dirtyFields={formState.dirtyFields}
                            errors={errors}
                            locale={locale}
                            rules={{
                                cc: {
                                    required: true,
                                },
                                ccv: {
                                    required: true,
                                },
                                expire: {
                                    required: true,
                                },
                            }}
                        />
                    </>
                );
            case 'payme':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <PaymeCardInfo
                            sellerId={payment.sellerId}
                            setPaymeInstance={setPaymeInstance}
                            paymeTokenGenerated={paymeTokenGenerated.current}
                            testMode={testMode}
                            control={control}
                            errors={errors}
                            locale={['he', 'yi'].includes(locale) ? 'he' : 'en'}
                            rules={{
                                cc: {
                                    required: true,
                                },
                                ccv: {
                                    required: true,
                                },
                                expire: {
                                    required: true,
                                },
                            }}
                        />
                    </>
                );
            case 'cardknox':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <CardknoxCardInfo
                            iFieldskey={payment.cardknoxIfieldsKey}
                            setCardknoxCardToken={setCardknoxCardToken}
                            setCardknoxCvvToken={setCardknoxCvvToken}
                            setCardknoxExp={setCardknoxExp}
                            setCardknoxCardState={setCardknoxCardState}
                            setCardknoxCvvState={setCardknoxCvvState}
                            setCardknoxExpState={setCardknoxExpState}
                            testMode={testMode}
                            control={control}
                            errors={errors}
                            locale={locale}
                            rules={{
                                cc: {
                                    required: true,
                                },
                                ccv: {
                                    required: true,
                                },
                                expire: {
                                    required: true,
                                },
                            }}
                            validityLabel={formatMessage({
                                id: 'DonationForm.enterCardValidity',
                            })}
                            key={gatewayErrorCount.current}
                            paymentMethod={getValues('paymentMethod')}
                        ></CardknoxCardInfo>
                    </>
                );

            case 'nedarim':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <NedarimCardInfo
                            locale={['he', 'yi'].includes(locale) ? 'he' : 'en'}
                        ></NedarimCardInfo>
                    </>
                );

            case 'nmi':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <NmiCardInfo
                            publicApiKey={payment.nmiPublicApiKey}
                            control={control}
                            errors={errors}
                            rules={{
                                cc: {
                                    required: true,
                                },
                                ccv: {
                                    required: true,
                                },
                                expire: {
                                    required: true,
                                },
                            }}
                            locale={locale}
                            nmiDataRef={nmiTokenData}
                        />
                    </>
                );

            case 'banquest':
                return (
                    <>
                        <StepHeading index={3}>
                            <FormattedMessage id="Checkout.stripe.paymentDetails" />
                        </StepHeading>
                        <BanquestCardInfo
                            tokenizationPublicKey={
                                payment.banquestTokenizationPublicKey
                            }
                            testMode={testMode}
                            locale={locale}
                            errors={errors}
                            onInstance={setBanquestInstance}
                        />
                    </>
                );
        }
    };

    const renderPaymentModeContent = useCallback(
        paymentMode => {
            if (givingId && givingOptions.type === 'recurring') {
                paymentMode = givingOptions.type;
            }
            const disableAll = givingId && givingAmount > 0;
            switch (paymentMode) {
                case 'regular':
                    return (
                        <PaymentMethodCurrencyWidget
                            currencies={currencies}
                            currency={currency}
                            defaultAmount={amount}
                            multiplier={multiplier}
                            donationTier={donationTier}
                            onCurrencyChange={handleCurrencyChange}
                            onAmountChange={handleAmountChange}
                            onAmountBlur={handleAmountBlur}
                            error={errors.amount}
                            disableAll={disableAll}
                            isAutoScrollEnabled={
                                autoScrollSettings?.isAutoScrollEnabled ?? true
                            }
                        />
                    );
                case 'installments':
                    return (
                        <InstallmentsWidget
                            onChange={handleAmountChange}
                            onBlur={handleAmountBlur}
                            onCurrencyChange={handleCurrencyChange}
                            amount={amount}
                            multiplier={multiplier}
                            donationTier={donationTier}
                            months={
                                getValues('months') ||
                                Number(payment.installmentDefaultMonths)
                            }
                            minMonths={Number(
                                payment.installmentMinMonths || 2,
                            )}
                            maxMonths={Number(payment.installmentMaxMonths)}
                            currencySign={currencySign(currency)}
                            selectProps={{
                                onChange: handleMonthsChange,
                            }}
                            error={errors.amount}
                            currencies={currencies}
                            currency={currency}
                            isAutoScrollEnabled={
                                autoScrollSettings?.isAutoScrollEnabled ?? true
                            }
                        />
                    );
                case 'recurring':
                case 'unlimitedRecurring':
                    return (
                        <RecurringWidget
                            onChange={handleAmountChange}
                            onBlur={handleAmountBlur}
                            onCurrencyChange={handleCurrencyChange}
                            amount={amount}
                            duration={
                                calculateMonths() ||
                                Number(
                                    givingId
                                        ? givingOptions.duration
                                        : payment.recurringDefaultMonths || 12,
                                )
                            }
                            paymentMode={paymentMode}
                            multiplier={multiplier}
                            donationTier={donationTier}
                            maxMonths={Number(
                                givingId &&
                                    givingOptions.type ===
                                        PAYMENT_MODES.RECURRING
                                    ? givingOptions.duration
                                    : payment.recurringMaxMonths || 12,
                            )}
                            lockMonths={Boolean(
                                !givingId && payment.lockRecurringMonths,
                            )}
                            currencySign={currencySign(currency)}
                            selectProps={{
                                onChange: handleMonthsChange,
                            }}
                            error={errors.amount}
                            currencies={currencies}
                            currency={currency}
                            disableAll={givingId && givingAmount > 0}
                            onPaymentModeChange={handlePaymentModeChange}
                            isAutoScrollEnabled={
                                autoScrollSettings?.isAutoScrollEnabled ?? true
                            }
                        />
                    );
            }
        },
        [
            currency,
            handleCurrencyChange,
            multiplier,
            amount,
            currencies,
            errors,
            payment,
            donationTier?.entityId,
        ],
    );

    const renderDisplayNameFields = () =>
        (!defaultFields['displayName'] ||
            defaultFields['displayName']?.isEnabled) && (
            <>
                <FormGroup>
                    <Toggle name="enableDisplayName" inputRef={register}>
                        <FormattedMessage id="Checkout.placeholder.enableDisplayName" />
                    </Toggle>
                </FormGroup>
                {Boolean(getValues('enableDisplayName')) && (
                    <FormGroup>
                        <FormInput
                            type="text"
                            name="displayName"
                            placeholder={formatMessage({
                                id: 'Checkout.placeholder.displayName',
                            })}
                            ref={register}
                            error={errors.displayName}
                            className={getFormClass('displayName')}
                            endIcon={getFormIcon('displayName')}
                        />
                        <FormInputHelperText error={errors.displayName} />
                    </FormGroup>
                )}
            </>
        );

    const renderCustomFields = () => {
        if (isCustomFieldsNotEmpty) {
            return Object.entries(customFields).map(([, config]) => {
                return (
                    <FormGroup key={config?.name} className={classes.fields}>
                        <CustomFormInput
                            {...config}
                            control={control}
                            inputRef={register}
                            formState={formState}
                            errors={errors}
                            getValues={getValues}
                        />
                        <FormInputHelperText
                            error={
                                errors.customFields &&
                                errors.customFields[config?.name]
                            }
                        />
                    </FormGroup>
                );
            });
        }

        return null;
    };

    const renderDefaultFields = () => (
        <DonationFormDefaultFields
            getFormClass={getFormClass}
            getFormIcon={getFormIcon}
            control={control}
            paymentGateway={paymentGateway}
            defaultPhonePrefix={autoFilledValues.phonePrefix}
        />
    );

    const createPayMeData = useCallback(
        async data => {
            const instance = paymeInstance;
            if (window.PayMe && instance) {
                paymeTokenGenerated.current = false;
                const saleData = {
                    payerFirstName: data.firstName,
                    payerLastName: data.lastName,
                    payerEmail: data.email,
                    payerPhone: data.phone,
                    total: {
                        label: campaignName,
                        amount: {
                            currency: data.currency,
                            value: data.amount.toFixed(2),
                        },
                    },
                };
                const validatorResponse = paymeValidator(
                    saleData,
                    formatMessage,
                );
                if (validatorResponse) {
                    throw new Error(validatorResponse);
                }

                const returnData = await instance
                    .tokenize(saleData)
                    .then(tokenizationResult => {
                        paymeTokenGenerated.current = true;
                        return tokenizationResult;
                    })
                    .catch(error => {
                        if (error.type && error.type === 'tokenize-error') {
                            if (error.validationError) {
                                const [firstErrorMessage] = Object.values(
                                    error.errors,
                                );
                                throw new Error(firstErrorMessage);
                            } else if (error.payload) {
                                const errorMessage = get(
                                    error.payload,
                                    'status_error_details',
                                );
                                throw new Error(errorMessage);
                            } else {
                                throw new Error('Token Generation failed');
                            }
                        } else {
                            throw new Error(error.message);
                        }
                    });
                return returnData;
            } else {
                throw new Error(
                    'Certain payment related scripts have not been loaded yet! Please contact support@causematch.com for further assistance.',
                );
            }
        },
        [paymeInstance],
    );

    const createNmiToken = useCallback(() => {
        if (window.CollectJS) {
            nmiTokenData.current.status = 'pending';
            window.CollectJS.startPaymentRequest();
            return new Promise(resolve => {
                const poll = () => {
                    if (nmiTokenData.current.status === 'idle') {
                        return resolve();
                    }
                    setTimeout(poll, 100);
                };
                poll();
            });
        } else {
            throw new Error(
                'Certain payment related scripts have not been loaded yet! Please contact support@causematch.com for further assistance.',
            );
        }
    }, []);

    const createBanquestToken = useCallback(
        data => {
            if (banquestInstance) {
                if (banquestInstance.isError) {
                    throw new Error('Please fill card details correctly!');
                }

                return banquestInstance.getToken(data);
            } else {
                throw new Error(
                    'Certain payment related scripts have not been loaded yet! Please contact support@causematch.com for further assistance.',
                );
            }
        },
        [banquestInstance],
    );

    const isTokenPending = useCallback(
        (paymentGateway, paymentMethod) => {
            if (paymentGateway === 'cardknox') {
                if (['card', 'pledger'].includes(paymentMethod)) {
                    return (
                        (cardknoxCardToken === null &&
                            cardknoxCardState === 'valid') ||
                        (cardknoxCvvToken === null &&
                            cardknoxCvvState === 'valid')
                    );
                }
                if (paymentMethod === 'matbia') {
                    return (
                        cardknoxCardToken === null &&
                        cardknoxCardState === 'valid'
                    );
                }
            }
            return false;
        },
        [
            cardknoxCardToken,
            cardknoxCvvToken,
            cardknoxCardState,
            cardknoxCvvState,
        ],
    );

    const validateCardknox = () => {
        //errors in order
        const cardFieldsErrorMessage =
            'Cardknox field error! Please contact support@causematch.com for further assistance';
        if (!cardknoxCardState) {
            throw new Error(cardFieldsErrorMessage);
        }
        if (paymentMethod !== 'matbia' && !cardknoxCvvState) {
            throw new Error(cardFieldsErrorMessage);
        }
        if (cardknoxCardState == 'error' || cardknoxCvvState == 'error') {
            throw new Error(
                'Cardknox token generation failed! Please contact support@causematch.com for further assistance',
            );
        }
        let emptyElements = [];
        let invalidElements = [];
        let errorString = '';
        if (cardknoxCardState == 'empty') {
            emptyElements.push('Number');
        } else if (cardknoxCardState == 'invalid') {
            invalidElements.push('Number');
        }
        if (paymentMethod !== 'matbia' && cardknoxCvvState == 'empty') {
            emptyElements.push('CVV');
        } else if (cardknoxCvvState == 'invalid') {
            invalidElements.push('CVV');
        }
        if (!cardknoxExpState || cardknoxExpState == 'empty') {
            emptyElements.push('Expiry date');
        } else if (cardknoxExpState == 'invalid') {
            invalidElements.push('Expiry date');
        }
        if (emptyElements.length != 0) {
            errorString = `Credit Card ${emptyElements.join(', ')} required!`;
        }
        if (invalidElements.length != 0) {
            errorString += `\nCredit Card ${invalidElements.join(
                ', ',
            )} invalid!`;
        }
        if (errorString != '') {
            throw new Error(errorString);
        }
    };

    const handlePreChargeFlow = async data => {
        switch (paymentGateway) {
            case 'payme':
                return await createPayMeData(data);
            case 'cardknox':
                validateCardknox();
                return {
                    card: cardknoxCardToken,
                    cvv: cardknoxCvvToken,
                    exp: cardknoxExp,
                };
            case 'nedarim': {
                const result = await getMessageResponse({
                    Name: 'ValidateFields',
                });
                if (result.Value !== 'OK') {
                    throw new Error(result.Field + ' ' + result.ErrorType);
                }
                break;
            }
            case 'nmi':
                await createNmiToken();
                return nmiTokenData.current.token;
            case 'banquest':
                return await createBanquestToken(data);
            default:
                return null;
        }
    };

    const createRecaptchaToken = async () => {
        let tokenResult = null;
        if (window.grecaptcha) {
            tokenResult = await window.grecaptcha.enterprise
                .execute(config.googleRecaptchaKey, {
                    action: 'submit',
                })
                .then(token => token);

            return tokenResult;
        } else {
            throw new Error('Recaptcha loading failed. Please try again later');
        }
    };

    const makeDonation = async (
        data,
        layerItems,
        customToken = null,
        { removeGiving = false } = {},
    ) => {
        if (!campaign) {
            throw new Error('Donation campaign not found!');
        }
        let tokenResult = null,
            recaptchaToken = null;

        if (!removeGiving && givingId && givingOptions.type === 'recurring') {
            data.paymentMode = givingOptions.type;
            data.months = givingOptions.duration;
        }

        const donate = async (layerItem = null) => {
            const result = await api.donation.createDonation({
                campaignId: campaign.id,
                donations: createDonations(pageName, {
                    ...data,
                    layerItem,
                    givingId: removeGiving ? null : givingId,
                    givingCount: removeGiving ? null : givingCount,
                    givingOptions: removeGiving ? null : givingOptions,
                }),
                paymentGateway,
                paymentMethod,
                props: {
                    stripe: stripeContext?.stripe ?? null,
                    elements: stripeContext?.elements ?? null,
                    getMessageResponse,
                    formatMessage,
                },
                gatewayToken: tokenResult,
                matchedDonationId: matchedDonation?.id,
                donorPflSourceId,
                recaptchaToken,
            });
            dispatch(paymentIterationComplete());

            return result;
        };

        try {
            let donations = [];
            dispatch(startPaymentProcess(layerItems.length || 1));

            recaptchaToken = await createRecaptchaToken();
            if (!recaptchaToken) {
                throw new Error('Recaptcha token empty');
            }

            if (['card', 'pledger', 'matbia'].includes(paymentMethod)) {
                if (paymentGateway === PAYMENT_GATEWAYS.AUTHORIZE) {
                    tokenResult = customToken;
                } else {
                    tokenResult = await handlePreChargeFlow(data);
                }
            } else if (
                ['applepay', 'googlepay', 'chariot'].includes(paymentMethod)
            ) {
                tokenResult = customToken;
            }
            let paymentFlow = donationFlow(paymentGateway, paymentMethod);
            if (paymentFlow === 'awayPre') {
                setValue('awayProcessing', true);
            }

            if (layerItems.length) {
                donations = await donate(layerItems);
            } else {
                donations = await donate();
            }

            successSubmitted.current = true;

            return donations;
        } catch (error) {
            // @todo: implement "global" error handler with UI output message
            if (typeof onDonateFailed === 'function') {
                onDonateFailed(error);
            }
            dispatch(
                paymentFailed(
                    formatMessage({
                        id: error.message,
                        defaultMessage: error.message,
                    }),
                ),
            );
            if (error.name == 'GatewayError') {
                gatewayErrorCount.current += 1;
            }
        }
    };

    const onFormValidationError = errors => {
        if (errors?.amount) {
            setTimeout(() => focusElement('[name="amount"]'), 25);
        }
    };

    const onSubmit = async ({ layerItems, ...data }) => {
        sendAnalyticsEvent({
            event: 'campaign:donation_submit',
            org_account_id: campaign.accountId,
            campaign_id: campaign.id,
            campaign_sub_page: pageName,
            page_language:
                pageLayerItem?.language || document.documentElement.lang,
            is_layer_item_page: !!pageLayerItem,
        });

        const donations = await makeDonation(data, layerItems);

        if (donations) {
            setPaymeInstance(null);

            if (donations.redirect) {
                dispatch(setGatewayRedirect());
                setTimeout(() => {
                    window.location = donations.redirectUrl;
                }, 1);
            }

            if (typeof onDonateSuccess === 'function') {
                onDonateSuccess(donations);
            }
        }
    };

    const handleDonationAbandonment = () => {
        dispatch(setPaymentAlert(null));
        dispatch(resetGiving());

        if (isGatewayRedirect || isAbandonedDonationLogged.current) {
            return;
        }

        const abandonedData = {
            campaignId: campaign.id,
            email: preservedValues.current.email,
            phone: preservedValues.current.phone,
            phonePrefix: preservedValues.current.phonePrefix,
            currency: preservedValues.current.currency,
            comment: preservedValues.current.comment,
            isAnonymous: preservedValues.current.isAnonymous,
            name: [
                preservedValues.current.firstName,
                preservedValues.current.lastName,
            ]
                .filter(Boolean)
                .join(' '),
            amount: preservedValues.current.amount,
            layerItems: preservedValues.current.layerItems.map(item => ({
                id: parseInt(item.value, 10),
                name: item.name,
            })),
            additionalData: {
                address1: preservedValues.current.address1,
                address2: preservedValues.current.address2,
            },
            pageName,
        };
        if (abandonedData.email || abandonedData.phone) {
            axios.post('/donation/cancel', abandonedData, {
                baseURL: config.publicHost,
            });
        }
    };

    useTimeOnPage(() => {
        if (!successSubmitted.current) {
            handleDonationAbandonment();
            isAbandonedDonationLogged.current = true;
        }
    }, !isAbandonedDonationLogged.current);

    useCloseTab(() => {
        handleDonationAbandonment();
    }, [isGatewayRedirect, isAbandonedDonationLogged.current]);

    useEffect(() => {
        register('paymentMode');
        register('paymentMethod');
        register('amount');
        register('currency');
        register('months');
        register('accountNumber');
        register('layerItems');
        register('tipPercent');
        register('awayProcessing');
    }, [register]);

    useEffect(() => {
        return () => {
            if (
                !successSubmitted.current &&
                !isGatewayRedirect &&
                !isAbandonedDonationLogged.current
            ) {
                handleDonationAbandonment();
            }
        };
    }, [
        successSubmitted.current,
        isGatewayRedirect,
        isAbandonedDonationLogged.current,
    ]);

    useEffect(() => {
        if (!LayersIsFetched) {
            dispatch(fetchLayers(campaign.id));
        }
    }, []);

    useEffect(() => {
        preservedValues.current = getValues();
    }, [getValues()]);

    const isTippingEnabled =
        enableTippingForGateway && enableTippingForPaymentMethod(paymentMethod);
    const tipPercent = Number(getValues('tipPercent')) || 0;

    useEffect(() => {
        let tipPercent = defaultTipPercent;
        if (!isTippingEnabled) {
            tipPercent = 0;
        }
        setValue('tipPercent', tipPercent);
    }, [isTippingEnabled, defaultTipPercent, setValue]);

    const selectedLayerItems = getValues('layerItems') || [];

    const updatedPaymentMode = () => {
        if (givingId && givingOptions.type === 'recurring') {
            return 'recurring';
        }
        return paymentMode;
    };

    const canAddLayerItems = useMemo(() => {
        const nextCount = selectedLayerItems.length + 1;
        const nextAmountPerItem = getSingleDonationAmount(amount, nextCount);
        if (amount !== 0 && nextAmountPerItem < minDonation) {
            return false;
        }
        return true;
    }, [amount, selectedLayerItems, currency]);

    const calculateMonths = () => {
        let months = 1;
        if (givingId) {
            if (givingOptions.type === 'recurring') {
                months = givingOptions.duration;
            } else {
                months = 1;
            }
        } else {
            if (paymentMode === PAYMENT_MODES.REGULAR) {
                months = 0;
            } else {
                months = getValues('months');
            }
        }
        return months;
    };

    const getLayerItemSelectorDisabledMessage = () => {
        if (!givingId && !canAddLayerItems) {
            return formatMessage(
                {
                    id: 'Checkout.layerItemsSelector.minAmountNotice',
                    defaultMessage: `Thank you for your generosity! Please note that a minimum donation of {minAmount} is required per each of the {layers} you select. If you'd like to add more {layers}, your total will be evenly divided among them. We appreciate your support!`,
                },
                {
                    minAmount: `${currencySign(currency)}${minDonation}`,
                    layers: donationLayersNames,
                },
            );
        }
        return '';
    };

    return (
        <FormProvider {...formData}>
            <form
                onSubmit={handleSubmit(onSubmit, onFormValidationError)}
                className={classes.donationForm}
                autoComplete="on"
            >
                <DoubleLineHeading className={classes.doubleHeading}>
                    {heading ? (
                        heading
                    ) : (
                        <FormattedMessage
                            id="Checkout.heading"
                            values={{ campaignName }}
                        />
                    )}
                </DoubleLineHeading>
                {text && (
                    <Text html className={classes.topP}>
                        {text}
                    </Text>
                )}
                <StepHeading index={1}>
                    <FormattedMessage id="Checkout.paymentMethods" />
                </StepHeading>
                <PaymentMethodSelector
                    onChange={handlePaymentMethodChange}
                    value={getValues('paymentMethod')}
                />
                {renderPaymentMethodContent(paymentMethod)}
                {renderVoucherFields(paymentMethod)}
                <CustomLogicWidget>
                    {!givingId && (
                        <SuggestedAmounts
                            currency={currency}
                            onAmountChange={handleAmountChange}
                        />
                    )}
                    {renderPaymentModeContent(paymentMode)}
                </CustomLogicWidget>
                {isTippingEnabled ? (
                    <TipWidget
                        fee={fee}
                        amount={amount}
                        currencySign={currencySign(currency)}
                        tippingCheckoutText={tippingCheckoutText}
                        tippingThankYouText={tippingThankYouText}
                        defaultTipPercent={Number(defaultTipPercent) || 0}
                        tipPercent={tipPercent}
                        percents={tipPercents}
                        onTipPercentChange={handleTipPercentChange}
                        layersCount={selectedLayerItems?.length || 1}
                    />
                ) : null}
                <StepHeading index={2}>
                    <FormattedMessage
                        id="Checkout.personalInfo"
                        values={{
                            small: smallFormatter,
                        }}
                    />
                </StepHeading>
                <FormGroup
                    inline
                    flexStart
                    gap={15}
                    className={classes.userInfo}
                >
                    <FormGroup width="100%">
                        <FormInput
                            type="text"
                            name="firstName"
                            autoComplete="given-name"
                            placeholder={formatMessage({
                                id: 'Checkout.placeholder.firstName',
                            })}
                            className={getFormClass('firstName')}
                            startIcon={<UserIcon />}
                            endIcon={getFormIcon('firstName')}
                            ref={register}
                            error={errors.firstName}
                        />
                        <FormInputHelperText error={errors.firstName} />
                    </FormGroup>
                    <FormGroup width="100%">
                        <FormInput
                            type="text"
                            name="lastName"
                            autoComplete="family-name"
                            placeholder={formatMessage({
                                id: 'Checkout.placeholder.lastName',
                            })}
                            startIcon={<UserIcon />}
                            ref={register}
                            className={getFormClass('lastName')}
                            endIcon={getFormIcon('lastName')}
                            error={errors.lastName}
                        />
                        <FormInputHelperText error={errors.lastName} />
                    </FormGroup>
                </FormGroup>
                {renderDisplayNameFields()}
                <GiftFields currency={currency} paymentMethod={paymentMethod} />
                <FormGroup className={classes.anonymous}>
                    <Checkbox
                        name="isAnonymous"
                        inputRef={register}
                        defaultChecked={autoFilledValues.isAnonymous || false}
                    >
                        <FormattedMessage
                            id="Checkout.placeholder.isAnonymous"
                            values={{
                                small: smallFormatter,
                            }}
                        />
                    </Checkbox>
                </FormGroup>
                {!isEmpty(defaultFields) ? (
                    renderDefaultFields()
                ) : (
                    <>
                        <FormGroup className={classes.fields}>
                            <FormInput
                                type="email"
                                name="email"
                                autoComplete="email"
                                placeholder={formatMessage({
                                    id: 'Checkout.placeholder.email',
                                })}
                                required
                                startIcon={<EmailIcon />}
                                ref={register}
                                error={errors.email}
                            />
                            <FormInputHelperText error={errors.email} />
                        </FormGroup>
                        <FormGroup className={classes.fields}>
                            <FormInput
                                type="tel"
                                name="phone"
                                autoComplete="tel"
                                placeholder={formatMessage({
                                    id: 'Checkout.placeholder.phone',
                                })}
                                required
                                startIcon={<PhoneIcon />}
                                ref={register}
                                error={errors.phone}
                            />
                            <FormInputHelperText error={errors.phone} />
                        </FormGroup>
                        <FormGroup>
                            <FormInput
                                type="text"
                                name="address1"
                                autoComplete="address-line1"
                                placeholder={formatMessage({
                                    id: 'Checkout.placeholder.address',
                                })}
                                startIcon={<HomeIcon />}
                                ref={register}
                                error={errors.address1}
                            />
                            <FormInputHelperText error={errors.address1} />
                        </FormGroup>
                    </>
                )}

                {renderCustomFields()}
                {renderPaymentFields(paymentGateway, paymentMethod)}
                {tabs.filter(tab => !tab.layer || tab.counter).length > 2 ? (
                    <>
                        <StepHeading
                            index={getLayerItemSelectorStep(
                                paymentMethod,
                                paymentGateway,
                            )}
                        >
                            {layerSelectorMainText ? (
                                layerSelectorMainText
                            ) : (
                                <FormattedMessage
                                    id="Checkout.placeholder.layerItemSelectorMainText"
                                    values={{
                                        small: smallFormatter,
                                        layerNames: donationLayersNames,
                                    }}
                                />
                            )}
                            {layerSelectorSubText ? (
                                <small>{layerSelectorSubText}</small>
                            ) : (
                                <FormattedMessage
                                    id="Checkout.placeholder.layerItemSelectorSubText"
                                    values={{ small: smallFormatter }}
                                />
                            )}
                        </StepHeading>

                        <LayerItemsSelector
                            disabled={
                                (givingId && selectedLayerItems.length >= 1) ||
                                !canAddLayerItems
                            }
                            additionQuery={{
                                campaignId: campaign.id,
                                allowDonations: true,
                                paginate: 0,
                            }}
                            items={selectedLayerItems}
                            onChange={handleLayerItemsChange}
                            deferMultiLayerChoice={deferMultiLayerChoice}
                            disabledMessage={getLayerItemSelectorDisabledMessage()}
                            deferDisabled={!givingId && !canAddLayerItems}
                        />
                    </>
                ) : null}
                <FormGroup>
                    <FormTextarea
                        rows={5}
                        resize={true}
                        icon={<EditIcon />}
                        placeholder={
                            commentLabel ||
                            formatMessage({
                                id: 'Checkout.placeholder.comment',
                            })
                        }
                        name="comment"
                        inputRef={register}
                        error={errors.comment}
                    />
                    <FormInputHelperText error={errors.comment} />
                </FormGroup>
                <PEFTerms />
                <TotalAmount
                    tippingPlatformText={tippingPlatformText}
                    currencySign={currencySign(currency)}
                    fee={fee}
                    amount={amount}
                    months={calculateMonths(
                        paymentMode,
                        givingId,
                        givingOptions,
                    )}
                    recurring={Boolean(
                        paymentMode === 'recurring' ||
                            (givingId && givingOptions.type === 'recurring'),
                    )}
                    installments={paymentMode === 'installments'}
                    unlimitedRecurring={
                        paymentMode === PAYMENT_MODES.UNLIMITED_RECURRING
                    }
                    layerItems={selectedLayerItems}
                    tipping={isTippingEnabled}
                    tipPercent={tipPercent}
                />

                <PaymentError error={paymentError} />
                <PaymentAlert alert={paymentAlert} />
                <FormGroup>
                    <DonateButton
                        paymentGateway={paymentGateway}
                        paymentMethod={paymentMethod}
                        paymentMode={updatedPaymentMode()}
                        currency={currency}
                        isTokenPending={isTokenPending(
                            paymentGateway,
                            paymentMethod,
                        )}
                        status={status}
                        awayProcessing={getValues('awayProcessing')}
                        paypalClientId={payment.paypalClientId}
                        chariotCid={payment.chariotCid}
                        payment={payment}
                        onDonateFailed={onDonateFailed}
                        onDonateSuccess={onDonateSuccess}
                        makeDonation={makeDonation}
                        onReady={onDonationButtonReady}
                        isTestMode={testMode}
                    />
                </FormGroup>

                <LinkedText>
                    <small>
                        <FormattedMessage
                            id="Checkout.termsStatic"
                            values={{
                                terms: linkFormatter(
                                    `https://www.causematch.com/${
                                        locale === 'he' ? 'he/' : ''
                                    }terms-of-service/?ref=cm2`,
                                ),
                                privacy: linkFormatter(
                                    `https://www.causematch.com/${
                                        locale === 'he' ? 'he/' : ''
                                    }privacy-policy/?ref=cm2`,
                                ),
                            }}
                        />
                    </small>
                </LinkedText>

                <LinkedText>
                    <small className={classes.recaptchaText}>
                        This site is protected by reCAPTCHA and the Google{' '}
                        <a href="https://policies.google.com/privacy">
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href="https://policies.google.com/terms">
                            Terms of Service
                        </a>{' '}
                        apply.
                    </small>
                </LinkedText>
            </form>
        </FormProvider>
    );
}

DonationForm.propTypes = {
    onDonateSuccess: PropTypes.func,
    onDonateFailed: PropTypes.func,
};

const useStyles = makeStyles(({ palette, spacing }) => ({
    donationForm: {
        '& input': {
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },

            '&[type=number]': {
                '-moz-appearance': 'textfield',
            },
        },
    },
    doubleHeading: {
        color: palette.primary.main,
        borderColor: 'currentColor',
        margin: 0,
    },
    topP: {
        margin: '15px 0 90px',
        fontFamily: 'Heebo, sans-serif',
        color: palette.grey[500],

        '& *': {
            lineHeight: '1.35',
        },
        '& p': {
            paddingBottom: spacing(1),
        },

        '@media (max-width: 600px)': {
            marginBottom: 30,
            fontSize: '0.813rem',

            '& *': {
                lineHeight: '1.25',
            },
            '& p': {
                paddingBottom: 4,
            },
        },
    },
    anonymous: {},
    socialsWidget: {
        marginTop: spacing(10),
    },
    userInfo: {
        '@media (max-width: 600px)': {
            '& > div': {
                display: 'block',
            },
            '& > div > div': {
                marginBottom: '10px !important',
            },
        },
    },
    fields: {
        '@media (max-width: 600px)': {
            marginBottom: '10px !important',
        },
    },
    noFields: {
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    recaptchaText: {
        direction: 'ltr',
    },
}));

export default DonationForm;
