import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormLabel } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { currencySign } from 'common/helpers';
import { selectPurimDonationTier } from 'common/helpers/donationTier';
import {
    selectDonationTiersSettings,
    selectPageDesign,
} from '../../campaign/campaignSlice';
import {
    selectCustomLogicState,
    selectDonationTier,
    updateCustomLogicState,
    updateDonationTier,
} from '../checkoutSlice';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants/campaign';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        textAlign: 'left',
        color: palette.accent.main,
        fontFamily: typography.fontFamily,

        '[dir=rtl] &': {
            textAlign: 'right',
        },
    },
    column: {
        minWidth: 0,
    },
    label: {
        paddingInlineStart: spacing(1),
        paddingBottom: spacing(1),
        color: palette.accent.main,
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(19),
        fontWeight: 600,
        display: 'block',

        '&.Mui-focused': {
            color: palette.accent.main,
        },

        '@media (max-width: 600px)': {
            fontSize: typography.pxToRem(16),
        },
    },
    numDonorsInputContainer: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: 14,
        paddingBottom: 14,
        border: `2px solid ${palette.primary.main}`,
        borderRadius: 5,
        background: palette.background.default,
        boxShadow: '0px 3px 6px #00000029',
        boxSizing: 'border-box',

        '@media (max-width: 600px)': {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    focused: {
        outline: `2px solid ${palette.primary.main}`,
    },
    modernFocused: {
        outline: `0.5px solid ${palette.primary.main}`,
    },
    modernDonorInputContainer: {
        borderRadius: spacing(2),
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        boxShadow: 'none',
    },
    numDonorsInput: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingInlineStart: spacing(2),
        paddingInlineEnd: spacing(2),
        boxSizing: 'border-box',
        border: 'none',
        color: palette.primary.main,
        fontFamily: typography.fontFamily,
        fontWeight: 500,
        fontSize: 22,
        textAlign: 'right',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: spacing(2),
        width: 0,

        '&:focus': {
            border: 'none',
            outline: 'none',
        },
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '@media (max-width: 600px)': {
            fontSize: 17,
            fontWeight: 400,
        },
    },
    modernDonorInput: {
        color: palette.text.primary,
        borderRadius: spacing(2),
    },
    message: {
        marginTop: spacing(2),
        color: palette.accent.main,

        '@media (max-width: 600px)': {
            marginTop: spacing(1.5),
            fontSize: typography.pxToRem(14),
        },
    },
}));

function PurimDonationInput({ currency, amount, children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customLogicState = useSelector(selectCustomLogicState);
    const donationTiersSettings = useSelector(selectDonationTiersSettings);
    const donationTier = useSelector(selectDonationTier);
    const pageDesign = useSelector(selectPageDesign);
    const isModernDesign = pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN;
    const [isNumDonorsFocused, setIsNumDonorsFocused] = useState(false);

    const handleChange = event => {
        const value = event.target.value
            .replace(/^0*/, '')
            .replace(/[^0-9]/g, '');

        const numDonors = value ? Number(value) : '';

        dispatch(updateCustomLogicState({ numDonors }));
    };

    useEffect(() => {
        if (!customLogicState?.timestamp) {
            dispatch(updateCustomLogicState({ numDonors: 1 }));
        }
    }, [customLogicState?.timestamp]);

    useEffect(() => {
        const donationTier = selectPurimDonationTier(
            donationTiersSettings,
            currency,
            amount,
            customLogicState,
        );
        dispatch(updateDonationTier(donationTier));
    }, [currency, amount, customLogicState?.timestamp, donationTiersSettings]);

    const {
        isMessageVisible,
        evyonimCount,
        totalAmount,
        amountPerEvyon,
        tierDescription,
    } = useMemo(() => {
        const params = {
            isMessageVisible: false,
            evyonimCount: '',
            totalAmount: '',
            amountPerEvyon: '',
            tierDescription: '',
        };
        if (
            !customLogicState?.numDonors ||
            !currency ||
            !amount ||
            !donationTier
        ) {
            return params;
        }

        params.evyonimCount = Number(customLogicState.numDonors) * 2;

        params.isMessageVisible = true;

        params.totalAmount = `${currencySign(currency)}${amount?.toLocaleString(
            'en-EN',
        )}`;

        params.amountPerEvyon = `${currencySign(currency)}${(
            amount / params.evyonimCount
        )?.toLocaleString('en-EN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`;

        params.tierDescription = donationTier.description?.toLowerCase();

        return params;
    }, [currency, amount, customLogicState?.timestamp, donationTier?.entityId]);

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.column}>
                    <FormLabel className={classes.label}>
                        <FormattedMessage
                            id="Checkout.purim.numDonorsLabel"
                            defaultMessage="How many people are performing the Mitzvah with this donation?"
                        />
                    </FormLabel>
                    <div
                        className={classNames(classes.numDonorsInputContainer, {
                            [classes.focused]: isNumDonorsFocused,
                            [classes.modernDonorInputContainer]: isModernDesign,
                            [classes.modernFocused]:
                                isNumDonorsFocused && isModernDesign,
                        })}
                    >
                        <input
                            type="text"
                            inputMode="numeric"
                            className={classNames(classes.numDonorsInput, {
                                [classes.modernDonorInput]: isModernDesign,
                            })}
                            onChange={handleChange}
                            value={customLogicState?.numDonors}
                            onFocus={() => setIsNumDonorsFocused(true)}
                            onBlur={() => setIsNumDonorsFocused(false)}
                        />
                    </div>
                </div>
                <Box mt={2} />
                <div className={classes.column}>{children}</div>
            </div>
            {isMessageVisible && (
                <div className={classes.message}>
                    <FormattedMessage
                        id="Checkout.purim.donationMessage"
                        defaultMessage="Your donation of <highlight>{totalAmount}</highlight> for Matanot La’Evyonim will support <highlight>{evyonimCount} Evyonim with {tierDescription}</highlight>. Each Evyon will receive <highlight>{amountPerEvyon}</highlight> on the day of Purim."
                        values={{
                            totalAmount,
                            evyonimCount,
                            tierDescription,
                            amountPerEvyon,
                            highlight(...chunks) {
                                return <strong>{chunks}</strong>;
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
}

PurimDonationInput.propTypes = {
    currency: PropTypes.string,
    amount: PropTypes.number,
    children: PropTypes.node,
};

export default PurimDonationInput;
