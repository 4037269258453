import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        margin: 0,
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
    },
}));

function FormLabel({ children, className }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}>{children}</div>
    );
}

FormLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default FormLabel;
