import * as React from 'react';

function SearchIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.653"
            height="13.651"
            viewBox="0 0 14.653 13.651"
            fill="transparent"
            {...props}
        >
            <g transform="translate(-1130.142 -1414.3)">
                <path
                    d="M1139.984,1675.709a4.617,4.617,0,1,1-4.432-4.794,4.617,4.617,0,0,1,4.432,4.794"
                    transform="translate(0 -256)"
                />
                <path
                    strokeLinecap="round"
                    strokeWidth="1.222px"
                    strokeLinejoin="round"
                    d="M1139.984,1675.709a4.617,4.617,0,1,1-4.432-4.794A4.617,4.617,0,0,1,1139.984,1675.709Z"
                    transform="translate(0 -256)"
                />
                <line
                    strokeLinecap="round"
                    strokeWidth="1.222px"
                    strokeLinejoin="round"
                    x2="4.76"
                    y2="4.161"
                    transform="translate(1139.173 1422.928)"
                />
            </g>
        </svg>
    );
}

export default SearchIcon;
