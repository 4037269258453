import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'client/componentLibrary/Button';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';
import Avatar from '../Avatar/Avatar';
import Progress from 'client/componentLibrary/Progress';
import Text from '../Text/Text';
import { currencySign } from 'common/helpers';
import Card from 'client/componentLibrary/Card';
import { getProgressGradation } from 'common/helpers/layerItemProgressGradation';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        position: 'relative',

        '&::after': {
            content: '""',
            borderRadius: spacing(2),
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: '-1px 2px 8px rgb(0, 0, 0, 0.1)',
            opacity: 0,
            transitionProperty: 'opacity',
            transitionTimingFunction: 'ease-out',
            transitionDuration: '1.1s',
        },
        '&:hover::after': {
            transitionDuration: ' 0.4s',
            opacity: 1,
        },
    },
    bonusRound: {
        border: `1.5px solid ${palette.primary.main} !important`,
        '&::after': {
            boxShadow: `-1px 2px 8px ${changeColorAlpha(
                palette.primary.main,
                0.2,
            )}`,
        },
    },
    banner: {
        position: 'relative',
        width: '100%',

        '& img': {
            width: '100%',
            maxHeight: 144,
            objectFit: 'cover',
            borderRadius: spacing(2),
            '@media (max-width: 1023px)': {
                maxHeight: 110,
            },
            '@media (max-width: 820px)': {
                maxHeight: 92,
            },
        },
    },
    content: {
        padding: `${spacing(2)}px ${spacing(3)}px`,

        '@media (max-width: 600px)': {
            padding: `${spacing(2)}px ${spacing(2)}px ${spacing(
                1,
            )}px !important`,
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(4),
        overflow: 'hidden',
    },
    avatar: {
        marginInlineEnd: spacing(1.5),
        flexShrink: 0,
        boxShadow: '0px 0px 15px -10px #000',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    name: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '1.25rem',
        lineHeight: 1.1,
        fontWeight: 600,

        '@media (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    description: {
        color: palette.text.primary,
        fontFamily: typography.fontFamily,
        fontSize: '0.875rem',
        lineHeight: 1,
        paddingTop: spacing(0.5),
        marginBottom: spacing(0.5),
        wordBreak: 'break-word',

        '@media (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    info: {
        margin: `${spacing(2)}px 0`,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    counts: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '0.125em',
        marginInlineStart: 'auto',
    },
    count: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontFamily: typography.fontFamily,
        fontSize: '0.938rem',
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    amounts: {
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        fontFamily: typography.fontFamily,
        fontSize: '0.75rem',
        color: palette.text.primary,
        paddingTop: spacing(0.25),
        '& strong': {
            marginBottom: spacing(-0.25),
            marginInlineEnd: spacing(1),
            fontSize: '1rem',
            fontWeight: 'bold',
        },

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',

            '& strong': {
                fontSize: '0.938rem',
            },
        },
    },
    amountCustom: {
        color: palette.text.primary,
        marginBottom: 0,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        fontFamily: typography.fontFamily,
    },
    goalAmount: {
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        marginInlineStart: '3px',
        '@media (max-width: 600px)': {
            fontSize: 'inherit',
            marginTop: spacing(-0.25),
        },
    },
    itemProgress: {
        display: 'flex',
        alignItems: 'baseline',
        fontSize: '0.938rem',
        margin: `0 ${spacing(0.5)}px`,
        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
            marginBottom: spacing(-0.5),
        },
    },
    progressLabel: {
        fontSize: typography.pxToRem(14),
        bottom: spacing(2),
        '&::after': {
            borderWidth: '4px 3px 0px 3px',
        },
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: spacing(1.5),
    },
    actionBtn: {
        fontSize: '1.2rem',
        '@media (max-width: 600px)': {
            fontSize: '0.938rem',
        },
    },
    bonusGoalBanner: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: '16%',
        alignItems: 'center',
        padding: `0 ${spacing(1)}px`,
        backgroundColor: palette.primary.main,
        borderRadius: `0 0 ${spacing(1)}px ${spacing(1)}px`,
        color: palette.primary.contrastText,
        fontSize: '1rem',
        fontWeight: 500,
        border: isLightColor(palette.primary.main, 0.7)
            ? `1px solid ${palette.primary.contrastText}`
            : 'none',
        '[dir=rtl] &': {
            right: 'auto',
            left: '16%',
        },
        '@media (max-width: 600px)': {
            padding: `0 ${spacing(0.5)}px`,
            fontSize: '0.813rem',
        },
    },
}));

function ModernTeamCard({
    allowDonations,
    amount,
    avatarSrc,
    baseGoalPercent,
    bonusGoalAmount = 0,
    campaignSlug,
    className,
    currency = 'USD',
    description,
    donorsCount,
    goalAmount = 0,
    hasEnteredBonusGoal = false,
    isCustomTileEnabled = false,
    name,
    onDonate,
    onView,
    queryString,
    teamsCount,
    tile = null,
}) {
    const classes = useStyles();

    const { goalPercent, bonusGoalPercent } = getProgressGradation(
        amount,
        goalAmount,
        bonusGoalAmount,
        hasEnteredBonusGoal,
    );
    const goals = [
        {
            percent: goalPercent,
            color: 'secondary',
        },
        {
            percent: bonusGoalPercent,
            color: 'primary',
        },
    ];

    return (
        <Card
            className={classNames(
                classes.root,
                {
                    [classes.bonusRound]: hasEnteredBonusGoal,
                },
                className,
            )}
            onClick={onView}
        >
            {isCustomTileEnabled && Boolean(tile) && (
                <div className={classNames(classes.banner)}>
                    <img src={tile} alt={name} />
                </div>
            )}
            <div className={classes.content}>
                <div className={classes.header}>
                    {!!avatarSrc && (
                        <Avatar size={84} className={classes.avatar}>
                            <img src={avatarSrc} alt={name} />
                        </Avatar>
                    )}
                    <div>
                        <div className={classes.name}>{name}</div>
                        {!!description && (
                            <div className={classes.description}>
                                {description}
                            </div>
                        )}
                    </div>
                </div>
                {goalAmount > 0 && !isNaN(baseGoalPercent) && (
                    <Progress
                        goals={goals}
                        size={6}
                        showLabel
                        label={`${baseGoalPercent}%`}
                        labelClassName={classes.progressLabel}
                    />
                )}
                <div className={classes.info}>
                    <div className={classes.amounts}>
                        {!isNaN(amount) ? (
                            <>
                                <Text className={classes.amountCustom}>
                                    {currencySign(currency)}
                                    {amount.toLocaleString('en-EN')}
                                </Text>
                                <div className={classes.itemProgress}>
                                    {goalAmount > 0 ? (
                                        <>
                                            <FormattedMessage id="Amount.outOf" />{' '}
                                            <span
                                                className={classes.goalAmount}
                                            >
                                                {currencySign(currency)}
                                                {hasEnteredBonusGoal ? (
                                                    <>
                                                        {(
                                                            goalAmount +
                                                            bonusGoalAmount
                                                        ).toLocaleString(
                                                            'en-EN',
                                                        )}{' '}
                                                        <FormattedMessage
                                                            id="DonationWidget.bonusTitle"
                                                            defaultMessage="Bonus Goal"
                                                        />
                                                    </>
                                                ) : (
                                                    goalAmount.toLocaleString(
                                                        'en-EN',
                                                    )
                                                )}
                                            </span>
                                        </>
                                    ) : (
                                        <span>
                                            <FormattedMessage
                                                id="Amount.raised"
                                                defaultMessage="Raised"
                                            />
                                        </span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <span>Analytics in progress...</span>
                        )}
                    </div>
                    <div className={classes.counts}>
                        {!isNaN(donorsCount) && amount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Donor"
                                    values={{ counter: donorsCount }}
                                />
                            </div>
                        )}
                        {!isCustomTileEnabled && teamsCount > 0 && (
                            <div className={classes.count}>
                                <FormattedMessage
                                    id="Team"
                                    values={{ counter: teamsCount }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isCustomTileEnabled && (
                    <>
                        <div className={classes.actions}>
                            <Button
                                variant="outlined"
                                color="accent"
                                className={classes.actionBtn}
                                onClick={onView}
                            >
                                <FormattedMessage id="Button.view" />
                            </Button>
                            {allowDonations && (
                                <Link
                                    to={`/${campaignSlug}/donate/${queryString}`}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={onDonate}
                                        className={classes.actionBtn}
                                    >
                                        <FormattedMessage id="Button.donateNow" />
                                    </Button>
                                </Link>
                            )}
                        </div>
                        {hasEnteredBonusGoal && (
                            <div className={classes.bonusGoalBanner}>
                                <FormattedMessage
                                    id="common.bonusGoal"
                                    defaultMessage="Bonus Goal!"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </Card>
    );
}

ModernTeamCard.propTypes = {
    allowDonations: PropTypes.bool,
    amount: PropTypes.number,
    avatarSrc: PropTypes.string,
    baseGoalPercent: PropTypes.number,
    bonusGoalAmount: PropTypes.number,
    campaignSlug: PropTypes.string,
    className: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    donorsCount: PropTypes.number,
    goalAmount: PropTypes.number,
    hasEnteredBonusGoal: PropTypes.bool,
    isCustomTileEnabled: PropTypes.bool,
    name: PropTypes.string,
    onDonate: PropTypes.func,
    onView: PropTypes.func,
    queryString: PropTypes.string,
    teamsCount: PropTypes.number,
    tile: PropTypes.string,
};

export default ModernTeamCard;
