import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import { CURRENCY_SIGNS } from '../../../../../common/constants';
import TruncatedText from 'client/components/II/TruncatedText';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { changeColorAlpha, isLightColor } from '../../../../helpers/colorUtils';
import Card from 'client/componentLibrary/Card';
import Button from 'client/componentLibrary/Button';
import GiftRoundedIcon from 'client/components/Icons/GiftRounded';
import { isEmpty } from 'lodash';

const commonCountValue = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    fontSize: '0.8rem',
    fontWeight: 'bolder',
    height: '1.2rem',
    top: '-0.3rem',
    lineHeight: '1px',
};

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {
        textAlign: 'center',
        color: palette.text.primary,
        position: 'relative',
        boxShadow: `0 ${spacing(0.25)}px ${spacing(2)}px ${changeColorAlpha(
            palette.grey[300],
            0.3,
        )}`,
        transition: 'box-shadow .2s',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        overflow: 'hidden',

        '&:hover': {
            boxShadow: `0 ${spacing(0.25)}px ${spacing(2)}px ${changeColorAlpha(
                palette.grey[300],
                0.7,
            )}`,
        },
    },
    donorInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: `${spacing(2)}px ${spacing(2)}px ${spacing(1)}px ${spacing(
            2,
        )}px`,
        position: 'relative',
        minWidth: 0,
    },
    logo: {
        position: 'absolute',
        left: 10,
        top: 10,
        width: 40,
        height: 40,
        '& img': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            objectFit: 'cover',
            border: `3px solid var(--perk-color, ${palette.primary.main})`,
        },
        '@media (max-width: 600px)': {
            left: spacing(0.5),
            top: spacing(0.5),
        },
    },
    logoWithDonationTier: {
        top: spacing(1),
        '@media (max-width: 600px)': {
            top: 0,
        },
    },
    content: {
        padding: `${spacing(1)}px ${spacing(2)}px ${spacing(2)}px ${spacing(
            2,
        )}px`,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    additionals: {
        marginTop: 'auto',
        padding: `${spacing(1)}px ${spacing(2)}px`,
        backgroundColor: palette.background.paperBorder,
        minWidth: 0,
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: 1.2,
        fontFamily: typography.fontFamily,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        '@media (max-width: 600px)': {
            marginTop: spacing(1),
        },
    },
    amount: {
        fontSize: '2.438rem',
        lineHeight: '47px',
        fontFamily: typography.fontFamily,
        fontWeight: '600',

        '@media (max-width: 600px)': {
            fontSize: '2rem',
            lineHeight: 1,
        },
    },
    greeting: {
        fontSize: '0.875rem',
    },
    through: {
        marginTop: spacing(1),
        fontSize: '1rem',
        fontWeight: 300,
        fontFamily: typography.fontFamily,
    },
    throughUrl: {
        fontWeight: 500,
        color: palette.text.primary,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    matchMyGiftButton: {
        borderRadius: spacing(1),
        textTransform: 'none',
        display: 'flex',

        '& svg': {
            stroke: palette.secondary.contrastText,
            fill: palette.secondary.main,
        },
    },
    matchingCountItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '1px',
        background: palette.background.paperBorder,
        top: spacing(2),
        right: spacing(2),
        position: 'absolute',
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '50%',
        transition: 'right ease 200ms, top ease 200ms',
        border: 'none',
        cursor: 'pointer',
        '@media (max-width: 600px)': {
            top: spacing(1),
            right: spacing(1.5),
        },
    },
    matchingCountItemLong: {
        '@media (max-width: 600px)': {
            right: spacing(2.5),
        },
    },
    countValue: {
        ...commonCountValue,
        outline: `1px solid ${palette.background.paper}`,
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        width: '1.2rem',
        right: '-0.5rem',
        borderRadius: '50%',
    },
    countValueLong: {
        ...commonCountValue,
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        width: '2rem',
        right: '-1rem',
        borderRadius: '1rem',
    },
    matchingCountIcon: {
        transform: 'scale(1.3)',
        stroke: palette.text.primary,
        fill: palette.background.paperBorder,
    },
    matchingCountItemsWrapper: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        width: '100px',
        height: '64px',
    },
    matchingCountToolTipContent: {
        color: palette.text.primary,
        fontSize: '0.875rem',
        textAlign: 'center',
    },
    matchingCountToolTipContentItemNumber: {
        border: `1px solid ${palette.text.primary}`,
        borderRadius: '3px',
        padding: '2px 3px',
        display: 'inline-block',
        margin: '0 0 0 5px',
        '[dir=rtl] & ': {
            margin: '0 5px 0 0',
        },
    },
    matchingCountToolTipContentItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    marginTop: {
        marginTop: '2px',
    },
    smallTopPadding: {
        paddingTop: spacing(1),
    },
    donationTier: {
        marginBottom: spacing(0.5),
        padding: `${spacing(0.5)}px ${spacing(2)}px`,
    },
    donationTierWithMmg: {
        padding: `${spacing(0.5)}px ${spacing(7)}px`,
    },
}));

function ModernDonateCard(props) {
    const {
        className,
        bgColor,
        logoUrl,
        name,
        amount = 0,
        hideAmount = false,
        greetingText,
        throughText,
        throughLayerItemName,
        throughItemUrl,
        currency = '$',
        throughLayerItem,
        isMatchMyGiftEnabled,
        onMatchMyGiftClicked,
        matchMyGiftCount = 0,
        donorPflCount = 0,
        handleViewThroughItem,
        showDonorPflCount,
        showMatchMyGiftCount,
        totalMatchingCount,
        donationTier,
        perkOutlineColor,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();

    const showDonationTier = !isEmpty(donationTier) && !hideAmount;

    const MmgButton = () =>
        isMatchMyGiftEnabled ? (
            <Button
                color="secondary"
                name="matchMyGift"
                variant="contained"
                className={classes.matchMyGiftButton}
                onClick={onMatchMyGiftClicked}
            >
                <GiftRoundedIcon />
                <Box mx={0.5} />
                <FormattedMessage
                    id="DonateCard.matchMyGiftButton"
                    defaultMessage="Match This Gift"
                />
            </Button>
        ) : null;

    const HtmlTooltip = withStyles(({ palette, spacing }) => ({
        tooltip: {
            backgroundColor: palette.background.paperBorder,
            padding: spacing(1),
            margin: `${spacing(0.75)}px 0`,
        },
        arrow: {
            '&::before': {
                border: `1px solid ${palette.background.paperBorder}`,
                backgroundColor: palette.background.paperBorder,
                boxSizing: 'border-box',
            },
        },
    }))(Tooltip);

    const rootBorderColor = showDonationTier
        ? donationTier?.color
        : perkOutlineColor;

    return (
        <Card
            className={classNames(classes.root, className)}
            backgroundColor={bgColor}
            borderColor={rootBorderColor}
            style={{ '--perk-color': perkOutlineColor }}
        >
            {showDonationTier && (
                <div
                    className={classNames(classes.donationTier, {
                        [classes.donationTierWithMmg]:
                            showMatchMyGiftCount || showDonorPflCount,
                    })}
                    style={{
                        backgroundColor: donationTier?.color,
                        color: isLightColor(donationTier?.color || '#FFF', 0.65)
                            ? palette.text.primary
                            : palette.common.white,
                    }}
                >
                    <TruncatedText
                        text={donationTier?.label}
                        justify="center"
                        width="100%"
                        minHeight={22}
                        lines={1}
                        cursor="default"
                    />
                </div>
            )}
            <div
                className={classNames(classes.donorInfo, {
                    [classes.smallTopPadding]: showDonationTier,
                })}
            >
                {logoUrl && (
                    <div
                        className={classNames(classes.logo, {
                            [classes.logoWithDonationTier]: showDonationTier,
                        })}
                    >
                        <img src={logoUrl} alt="Logo icon" />
                    </div>
                )}
                <div className={classes.amount}>
                    {hideAmount ? (
                        <FormattedMessage
                            id="DonateCard.hiddenAmount"
                            defaultMessage="Donated"
                        />
                    ) : (
                        <>
                            <span style={{ marginRight: 6 }}>
                                {CURRENCY_SIGNS[currency] || currency}
                            </span>
                            {Math.round(amount).toLocaleString('en-EN')}
                        </>
                    )}
                </div>
                <Tooltip title={name || ''}>
                    <div className={classes.name}>{name}</div>
                </Tooltip>
            </div>
            <div className={classes.content}>
                <MmgButton />

                {throughText && (
                    <div className={classes.through}>
                        {throughText}{' '}
                        {throughLayerItem?.isPublished ? (
                            <a
                                href={throughItemUrl}
                                className={classes.throughUrl}
                                onClick={handleViewThroughItem}
                            >
                                {throughLayerItemName}
                            </a>
                        ) : (
                            <b>{throughLayerItemName}</b>
                        )}
                    </div>
                )}
            </div>
            {greetingText && (
                <div className={classes.additionals}>
                    <div className={classes.greeting}>
                        <TruncatedText
                            text={greetingText}
                            justify="center"
                            width="100%"
                            minHeight={22}
                            lines={throughText ? 1 : 2}
                            cursor="default"
                        />
                    </div>
                </div>
            )}
            {(showMatchMyGiftCount || showDonorPflCount) && (
                <div className={classes.matchingCountItemsWrapper}>
                    <HtmlTooltip
                        arrow
                        title={
                            <div
                                className={classes.matchingCountToolTipContent}
                            >
                                {showMatchMyGiftCount && (
                                    <div
                                        className={
                                            classes.matchingCountToolTipContentItem
                                        }
                                    >
                                        <span>
                                            <FormattedMessage
                                                id="DonateCard.matchMyGiftButton"
                                                defaultMessage="Match This Gift"
                                            />
                                        </span>
                                        <span
                                            className={
                                                classes.matchingCountToolTipContentItemNumber
                                            }
                                        >
                                            {matchMyGiftCount}
                                        </span>
                                    </div>
                                )}
                                {showDonorPflCount && (
                                    <div
                                        className={classNames(
                                            classes.matchingCountToolTipContentItem,
                                            {
                                                [classes.marginTop]:
                                                    showDonorPflCount &&
                                                    showMatchMyGiftCount,
                                            },
                                        )}
                                    >
                                        <span>
                                            <FormattedMessage
                                                id="DonateCard.personalFundraisingLink"
                                                defaultMessage="Personal Fundraising Link"
                                            />
                                        </span>
                                        <span
                                            className={
                                                classes.matchingCountToolTipContentItemNumber
                                            }
                                        >
                                            {donorPflCount}
                                        </span>
                                    </div>
                                )}
                            </div>
                        }
                        enterTouchDelay={0}
                    >
                        <div
                            className={classNames(classes.matchingCountItem, {
                                [classes.matchingCountItemLong]:
                                    totalMatchingCount >= 100,
                            })}
                        >
                            <GiftRoundedIcon
                                className={classes.matchingCountIcon}
                            />

                            <div
                                className={
                                    totalMatchingCount >= 100
                                        ? classes.countValueLong
                                        : classes.countValue
                                }
                            >
                                <div>
                                    {totalMatchingCount >= 100
                                        ? '99+'
                                        : totalMatchingCount}
                                </div>
                            </div>
                        </div>
                    </HtmlTooltip>
                </div>
            )}
        </Card>
    );
}

ModernDonateCard.propTypes = {
    amount: PropTypes.number,
    bgColor: PropTypes.string,
    className: PropTypes.string,
    currency: PropTypes.string,
    donationTier: PropTypes.string,
    donorPflCount: PropTypes.number,
    greetingText: PropTypes.string,
    handleViewThroughItem: PropTypes.func,
    hideAmount: PropTypes.bool,
    isMatchMyGiftEnabled: PropTypes.bool,
    logoUrl: PropTypes.string,
    matchMyGiftCount: PropTypes.number,
    name: PropTypes.string,
    onMatchMyGiftClicked: PropTypes.func,
    showDonorPflCount: PropTypes.bool,
    showMatchMyGiftCount: PropTypes.bool,
    throughItemUrl: PropTypes.string,
    throughLayerItem: PropTypes.object,
    throughLayerItemName: PropTypes.string,
    throughText: PropTypes.string,
    totalMatchingCount: PropTypes.number,
    perkOutlineColor: PropTypes.string,
};

export default ModernDonateCard;
