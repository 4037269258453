import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';

/**
 * as loader has circle shape, and box of loader is square, we can't use
 * size as min height, that bring scroll bar blinking some time, we need
 * add to min height part of square's diagonal.
 */
function getMinHeight(size) {
    return Math.sqrt(size * size * 2);
}

const useStyles = makeStyles(() => ({
    loaderContainer: {
        visibility: 'hidden',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',

        '&>span': {
            display: 'block',
        },
    },
    loaderContainerVisible: {
        visibility: 'visible',
    },
    contentContainer: {
        opacity: 1,
    },
    contentContainerLoading: {
        opacity: 0.6,
    },
}));

const Loader = React.forwardRef(
    (
        {
            isLoading = true,
            isLoaded = !isLoading,
            children = null,
            size = 35,
            loaderColor,
            loaderClassName = null,
            contentClassName = null,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles({ isLoading });
        const { palette } = useTheme();

        return (
            <Box
                ref={ref}
                position="relative"
                height={children ? 'auto' : size}
                minHeight={getMinHeight(size)}
            >
                {isLoaded && (
                    <Box
                        className={classNames(
                            classes.contentContainer,
                            contentClassName,
                            isLoading ? classes.contentContainerLoading : null,
                        )}
                    >
                        {children}
                    </Box>
                )}
                <Box
                    className={classNames(
                        classes.loaderContainer,
                        isLoading ? classes.loaderContainerVisible : null,
                        loaderClassName,
                    )}
                >
                    <ClipLoader
                        color={loaderColor || palette.primary.main}
                        loading={isLoading}
                        size={size}
                        {...props}
                    />
                </Box>
            </Box>
        );
    },
);

Loader.propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    children: PropTypes.node,
    size: PropTypes.number,
    loaderColor: PropTypes.string,
    loaderClassName: PropTypes.string,
    contentClassName: PropTypes.string,
};

export default Loader;
