import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SelectSearch from 'react-select-search';
import { changeColorAlpha } from 'client/helpers/colorUtils';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        position: 'relative',
        width: props => props.width,
        display: 'block',
        boxSizing: 'border-box',

        '&::after': {
            content: '""',
            display: 'block',
            border: `solid ${palette.text.primary}`,
            borderWidth: '1px 1px 0 0',
            position: 'absolute',
            transform: 'translateY(calc(-50% - 1px)) rotate(135deg)',
            transformOrigin: 'center center',
            pointerEvents: 'none',
            width: 7,
            height: 7,
            right: 13,
            top: 27,

            'html[dir=rtl] &': {
                right: 'auto',
                left: 13,
            },
            '@media (max-width: 600px)': {
                top: 19,
            },
        },
        '& input': {
            overflow: 'hidden',
            background: palette.background.default,
            appearance: 'none',
            width: '100%',
            boxSizing: 'border-box',
            outline: 'none',
            height: 54,
            paddingInlineEnd: '35px',
            paddingInlineStart: '20px',
            border: `1px solid ${palette.text.primary}`,
            borderRadius: 5,
            fontFamily: typography.fontFamily,
            fontSize: 16,
            color: palette.text.primary,
            '&::placeholder': {
                color: changeColorAlpha(palette.text.primary, 0.85),
            },
            '&::-moz-placeholder': {
                color: 'black',
            },

            '@media (max-width: 600px)': {
                paddingTop: 5,
                paddingBottom: 5,
                height: 44,
            },
        },
        '& ul': {
            padding: '5px 0',
            margin: 0,
            listStyle: 'none',
            background: palette.background.default,
            border: `1px solid ${palette.text.primary}`,
            borderRadius: 5,
            maxHeight: 200,
            overflowY: 'scroll',
            '&:empty': {
                display: 'none',
            },
        },
        '& button': {
            border: 0,
            background: 'none',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            color: palette.text.primary,
            cursor: 'pointer',
            fontFamily: typography.fontFamily,
            fontSize: 16,
            padding: '4px 20px',
            '&:hover, &:focus': {
                background: changeColorAlpha(palette.text.primary, 0.1),
            },
        },
    },
}));

function FormSelectSearch(props) {
    const { className, width = 'auto', value, options = [], ...rest } = props;
    const classes = useStyles({ width });

    const preventableKeyPress = useCallback(
        onKeyPress => e => {
            onKeyPress(e);
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        },
        [],
    );

    const renderValue = useCallback(
        ({ onKeyPress, ...valueProps }, snapshot, className) => (
            <input
                {...valueProps}
                onKeyPress={preventableKeyPress(onKeyPress)}
                className={className}
            />
        ),
        [],
    );

    return (
        <SelectSearch
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
            options={options}
            value={value}
            search
            renderValue={renderValue}
            {...rest}
        />
    );
}

FormSelectSearch.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    className: PropTypes.string,
    value: PropTypes.any,
};

export default FormSelectSearch;
