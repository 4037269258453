import * as React from 'react';
import PropTypes from 'prop-types';

function CommentIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.463"
            height="10.464"
            viewBox="0 0 10.463 10.464"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            <g
                transform="translate(0.000000,10.000000) scale(0.008,-0.008)"
                fill={props.color}
                stroke={props.color}
                strokeWidth={10}
            >
                <path
                    d="M480 1174 c-105 -22 -203 -77 -285 -158 -313 -312 -191 -830 230
-971 73 -25 90 -27 200 -23 106 4 130 9 203 37 l84 33 120 -42 c99 -35 125
-40 144 -31 32 14 31 38 -11 187 l-35 125 26 72 c34 96 43 234 20 331 -51 217
-234 395 -451 441 -86 18 -160 18 -245 -1z m225 -69 c183 -39 326 -165 386
-342 34 -100 33 -231 -4 -334 -15 -41 -27 -86 -27 -100 0 -13 13 -69 29 -124
16 -55 27 -101 26 -103 -1 -1 -41 11 -88 28 -107 38 -134 38 -227 -1 -88 -37
-209 -49 -303 -30 -227 48 -407 268 -407 500 0 211 135 409 327 481 105 39
187 46 288 25z"
                />
            </g>
        </svg>
    );
}

CommentIcon.propTypes = {
    color: PropTypes.string,
};

export default CommentIcon;
