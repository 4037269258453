import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
    return {
        root: {
            borderRadius: 5,
            fontWeight: 500,
            fontFamily: typography.fontFamily,
            padding: spacing(0.5),
            fontSize: '1rem',
            '@media (max-width: 600px)': {
                fontSize: typography.pxToRem(14),
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                left: `50%`,
                top: '100%',
                borderStyle: 'solid',
                borderWidth: '9px 3px 0px 3px',
                borderColor: 'transparent',
            },
        },
        primary: {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            '&::after': {
                borderTopColor: `${palette.primary.main} !important`,
            },
        },
        secondary: {
            backgroundColor: palette.secondary.main,
            color: palette.secondary.contrastText,
            '&::after': {
                borderTopColor: `${palette.secondary.main} !important`,
            },
        },
        accent: {
            backgroundColor: palette.accent.main,
            color: palette.accent.contrastText,
            '&::after': {
                borderTopColor: `${palette.accent.main} !important`,
            },
        },
    };
});

function ArrowLabel({
    className,
    color = 'primary',
    elevation = 0,
    label = '',
    style,
}) {
    const classes = useStyles();
    const { shadows } = useTheme();

    return (
        <div
            className={classNames(
                classes.root,
                classes[color] ? classes[color] : null,
                className,
            )}
            style={{
                boxShadow: shadows[elevation] || 'none',
                ...style,
            }}
        >
            {label}
        </div>
    );
}

ArrowLabel.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    color: PropTypes.oneOf(['primary', 'secondary', 'accent']),
    elevation: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    size: PropTypes.number,
    style: PropTypes.object,
};

export default ArrowLabel;
