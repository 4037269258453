import React from 'react';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectPageDesign } from '../../features/campaign/campaignSlice';
import ClassicDonateCard from './ClassicDonateCard';
import ModernDonateCard from './ModernDonateCard';

function DonateCard(props) {
    const pageDesign = useSelector(selectPageDesign);

    switch (pageDesign) {
        case CAMPAIGN_PAGE_DESIGNS.MODERN:
            return <ModernDonateCard {...props} />;

        case CAMPAIGN_PAGE_DESIGNS.CLASSIC:
        default:
            return <ClassicDonateCard {...props} />;
    }
}

export default DonateCard;
