import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AmountInput from 'client/components/CampaignPage/components/AmountInput/AmountInput';
import useIsMobile from 'client/hooks/isMobile';
import { roundAmount } from 'common/helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import ArrowCircleIcon from '../ArrowCircleIcon/ArrowCircleIcon';
import FormGroup from '../FormGroup/FormGroup';
import FormSelect from '../FormSelect/FormSelect';
import MultiplierIcon from '../MultiplierIcon/MultiplierIcon';
import DonationTier from 'client/components/DonationTier/DonationTier';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
    root: {},
    donation: {
        marginBottom: 0,
        flexShrink: 0,
        width: 240,
        '& > div': {
            color: palette.primary.main,
        },

        '@media (max-width: 600px)': {
            width: '100%',
        },
    },
    period: {
        width: '100%',
        marginBottom: 0,
        '& > div': {
            color: palette.primary.main,
        },
    },
    arrow: {
        marginTop: spacing(4),
        flexShrink: 0,

        '@media (max-width: 600px)': {
            marginTop: spacing(3),
            width: 21,
            height: 21,
            transform: 'rotate(90deg)',
        },

        'html[dir=rtl] &': {
            transform: 'rotate(180deg)',
            transformOrigin: 'center center',
        },
    },
    input: {
        borderColor: palette.primary.main,
        '& input': {
            color: palette.primary.main,
            textAlign: 'center',

            '@media (max-width: 600px)': {
                padding: `${spacing(0.5)} ${spacing(2)}px`,
                fontSize: typography.pxToRem(13),
                height: 38,
            },
        },
    },
    select: {
        width: '100%',
        borderColor: palette.primary.main,
        '&::after': {
            borderColor: palette.primary.main,
        },
        '& select': {
            color: palette.primary.main,

            '@media (max-width: 600px)': {
                fontSize: typography.pxToRem(13),
                height: 38,
                paddingInlineStart: spacing(2),
            },
        },
    },
    helperContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
    },
    donationTier: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginInlineEnd: spacing(-1.5),
        paddingTop: spacing(0.75),
    },
    tooltip: {
        color: palette.primary.main,
        fontWeight: 900,
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        marginTop: spacing(2),
        justifyContent: 'center',
    },
    icon: {
        '@media (max-width: 600px)': {
            height: 39,
        },
    },
    matching: {
        marginInlineStart: spacing(2),
        fontFamily: typography.fontFamily,
        fontSize: typography.pxToRem(16),
        color: palette.primary.main,
        fontWeight: 600,

        '@media (max-width: 600px)': {
            marginInlineStart: spacing(1),
            fontSize: typography.pxToRem(14),
        },
    },
    amountContainer: {
        '& > div': {
            '@media (max-width: 600px)': {
                flexFlow: 'column nowrap',
                alignItems: 'center',
            },
        },
    },
}));

function InstallmentsWidget(props) {
    const {
        className,
        amount: defaultAmount = 0,
        multiplier = 1,
        donationTier = null,
        onChange,
        onBlur,
        selectProps,
        months: defaultMonths = 12,
        minMonths = 2,
        maxMonths = 12,
        currencySign = '$',
        error,
        currencies,
        currency = 'USD',
        onCurrencyChange,
        isAutoScrollEnabled = true,
    } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    const { formatMessage } = useIntl();
    const [amount, setAmount] = useState(
        defaultAmount === 0 ? '' : String(defaultAmount),
    );
    const [decimalTooltip, setDecimalTooltip] = useState(false);
    const { isMobile } = useIsMobile();
    const options = useMemo(() => {
        if (!selectProps.options) {
            const options = [];

            for (let months = minMonths; months <= maxMonths; months++) {
                let amountPerMonth = roundAmount(
                    amount / months,
                ).toLocaleString('en-EN', { maximumFractionDigits: 2 });

                options.push({
                    value: months,
                    name: formatMessage(
                        { id: 'Installment.option' },
                        { months, amountPerMonth, currencySign },
                    ),
                    selected: months === defaultMonths,
                });
            }

            return options;
        }
        return selectProps.options;
    }, [defaultMonths, maxMonths, amount, currency]);

    useEffect(() => {
        setAmount(defaultAmount === 0 ? '' : String(defaultAmount));
    }, [defaultAmount]);

    const handleDonationChange = event => {
        const amount = event.target?.value || event;

        setAmount(amount);
        onChange(amount === '' ? 0 : amount);
    };

    const handleDonationBlur = event => {
        if (typeof onBlur === 'function') {
            onBlur(event);
        }
    };

    const handleMonthsChange = event => {
        if (typeof selectProps.onChange === 'function') {
            selectProps.onChange(event.target.value);
        }
    };

    return (
        <div
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
        >
            <FormGroup
                inline={!isMobile}
                gap={20}
                className={classes.amountContainer}
            >
                <AmountInput
                    className={classes.donation}
                    currencies={currencies}
                    currency={currency}
                    error={error}
                    label={formatMessage({ id: 'Checkout.yourDonation' })}
                    onAmountChange={handleDonationChange}
                    onAmountBlur={handleDonationBlur}
                    onAmountDecimal={setDecimalTooltip}
                    onCurrencyChange={onCurrencyChange}
                    defaultAmount={amount?.toLocaleString('en-EN')}
                    autoFocus={isAutoScrollEnabled}
                />
                <ArrowCircleIcon className={classes.arrow} />
                <FormGroup
                    className={classes.period}
                    label={formatMessage({ id: 'Installment.period' })}
                >
                    <FormSelect
                        className={classes.select}
                        {...selectProps}
                        onChange={handleMonthsChange}
                        options={options}
                    />
                </FormGroup>
            </FormGroup>
            <div className={classes.helperContent}>
                <div className={classes.messages}>
                    {decimalTooltip && (
                        <FormInputHelperText className={classes.tooltip}>
                            <FormattedMessage
                                id="EnterAmountInput.decimalTooltip"
                                defaultMessage="Decimals are not allowed in donation amount"
                            />
                        </FormInputHelperText>
                    )}
                    {error && <FormInputHelperText error={error} />}
                </div>
                {donationTier && (
                    <div className={classes.donationTier}>
                        <DonationTier
                            size="small"
                            donationTier={donationTier}
                        />
                    </div>
                )}
            </div>
            {multiplier > 1 && (
                <div className={classes.bottom}>
                    <MultiplierIcon
                        multiplier={multiplier}
                        height={51}
                        fill={palette.primary.main}
                        className={classes.icon}
                    />
                    <div className={classes.matching}>
                        <FormattedMessage
                            id="Matching.amount"
                            values={{
                                matchAmount: (
                                    amount * multiplier
                                )?.toLocaleString('en-EN'),
                                currencySign,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

InstallmentsWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    amount: PropTypes.number,
    multiplier: PropTypes.number,
    donationTier: PropTypes.object,
    selectProps: PropTypes.object,
    className: PropTypes.string,
    months: PropTypes.number,
    minMonths: PropTypes.number,
    maxMonths: PropTypes.number,
    currencySign: PropTypes.string,
    error: PropTypes.object,
    currencies: PropTypes.array,
    currency: PropTypes.string,
    onCurrencyChange: PropTypes.func.isRequired,
    isAutoScrollEnabled: PropTypes.bool,
};

export default InstallmentsWidget;
