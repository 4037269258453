import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getPaletteColor } from 'common/helpers/palette';

const useStyles = makeStyles(
    ({ palette, spacing }) => ({
        root: {
            border: `0.5px solid`,
            color: palette.text.primary,
        },

        rounded: {
            borderRadius: spacing(2),
        },
    }),
    { name: 'card' },
);

const Card = (
    {
        backgroundColor,
        borderColor,
        children,
        className,
        elevation = 0,
        square,
        style,
        ...rest
    },
    innerRef,
) => {
    const { palette } = useTheme();
    const classes = useStyles();

    const borderColorCode = getPaletteColor(
        palette,
        borderColor,
        palette.background?.paperBorder,
    );
    const backgroundColorCode = getPaletteColor(
        palette,
        backgroundColor,
        palette.background?.paper,
    );

    return (
        <Paper
            className={classNames(
                classes.root,
                { [classes.rounded]: !square },
                className,
            )}
            elevation={elevation}
            square={square}
            style={{
                ...style,
                borderColor: borderColorCode,
                backgroundColor: backgroundColorCode,
            }}
            ref={innerRef}
            {...rest}
        >
            {children}
        </Paper>
    );
};

Card.propTypes = {
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    elevation: PropTypes.number,
    square: PropTypes.bool,
    style: PropTypes.object,
};

export default React.forwardRef(Card);
