import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        borderRadius: '50%',
        border: '0.5px solid #999',
    },
});

function Avatar(props) {
    const { children, size = 'auto', className } = props;
    const classes = useStyles();

    return (
        <div
            className={classNames(classes.root, className)}
            style={{ width: size, height: size }}
        >
            {children}
        </div>
    );
}

Avatar.propTypes = {
    children: PropTypes.node,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default Avatar;
