import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        position: 'relative',
        fontFamily: typography.fontFamily,
        paddingLeft: 30,
    },
    input: {
        position: 'absolute',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        opacity: 1,
    },
    label: {
        display: 'inline-block',
        fontFamily: typography.fontFamily,
        fontSize: '1rem',
        color: palette.text.primary,
        '& small': {
            fontSize: typography.pxToRem(13),
            fontWeight: 300,
        },
        '& a': {
            fontWeight: 500,
            color: palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    icon: {
        position: 'absolute',
        left: 5,
        top: 7,
        width: 7,
        height: 8.75,
        zIndex: 2,
    },
}));

function Radio(props) {
    const {
        className,
        children,
        onChange,
        defaultChecked = false,
        inputRef,
        ...rest
    } = props;
    const classes = useStyles();

    const handleChange = event => {
        if (typeof onChange === 'function') {
            onChange(event);
        }
    };

    return (
        <label className={classNames(classes.root, className)}>
            <input
                className={classes.input}
                type="radio"
                defaultChecked={defaultChecked}
                onChange={handleChange}
                ref={inputRef}
                {...rest}
            />
            <span className={classes.label}>{children}</span>
        </label>
    );
}

Radio.propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.node,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    inputRef: PropTypes.func,
};

export default Radio;
