import React, { useCallback, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import { parseCampaignPagePath } from 'client/helpers/parseCampaignPagePath';
import useIsMobile from 'client/hooks/isMobile';
import { sendAnalyticsEvent } from 'client/services/analytics';
import { CAMPAIGN_PAGE_DESIGNS } from 'common/constants/campaign';
import isDateStringPast from 'common/helpers/isDatePast';
import * as u2a from 'common/helpers/u2a';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import ClassicCampaignPage from './CampaignDesigns/Classic';
import ModernCampaignPage from './CampaignDesigns/Modern';
import {
    addDonationFlashMessage,
    startFlashMessagesQueue,
    stopFlashMessagesQueue,
} from './components/DonationFlasher/donationFlasherSlice';
import PageLayout from './components/PageLayout/PageLayout';
import {
    fetchBySlug,
    selectCampaignPageData,
    selectCampaignSlug,
    selectEnabledLayerDonation,
    selectLandingData,
    selectPageDesign,
    selectPageName,
    selectRdpHiddenTabs,
    selectSelectedCampaign,
    selectSelectedLayerItem,
    updateCampaign,
    updateDonationsSum,
    updateDonorPflSourceId,
    updateGiving,
    updateIsCampaignExpired,
    updateSelectedLayerItemStatistics as updatePageLayerItemStatistics,
} from './features/campaign/campaignSlice';
import { open } from './features/checkout/checkoutPopupSlice';
import { slice as donorSlice } from './features/donorsList/donorsListSlice';
import { slice as teamDonorsListSlice } from './features/donorsList/teamDonorsListSlice';
import { slice as teamSlice } from './features/layersList/teamsListSlice';
import {
    selectIsDefaultTabSelected,
    selectLayersIsFetched,
    selectTabs,
    setIsDefaultTabSelected,
    setSelectedTab,
} from './features/recentDonationPanel/recentDonationPanelSlice';
import useWebsocket from './hooks/ws';
import PreviewBanner from './PreviewBanner';
import { defaultCampaignTheme } from 'client/theme';
import { updateDonationPaymentMethod } from './features/checkout/checkoutSlice';
import filterQueryParams from 'client/helpers/filterQueryParams';

yup.addMethod(yup.mixed, 'requiredCardElement', function (message) {
    return this.test('testRequiredCardElement', message, function (value) {
        return value?.empty === false;
    });
});

yup.addMethod(yup.mixed, 'cardElementError', function () {
    return this.test({
        name: 'testCardElementError',
        test(value) {
            if (value?.error) {
                return this.createError({
                    message: value.error?.message,
                });
            }

            return true;
        },
    });
});

function CampaignPage() {
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const campaign = useSelector(selectSelectedCampaign);
    const {
        defaultRDPTab = -1,
        enableFb,
        enableGa,
        enableGAd,
        enableGAdConversionTag,
        fbTrackingId,
        gaTrackingId,
        gAdTrackingId,
        gAdLabel,
        gAdConversionTagId,
        gAdConversionTagLabel,
    } = useSelector(selectLandingData);
    const campaignSlug = useSelector(selectCampaignSlug);
    const {
        language,
        isLayerItem,
        hideHeader = false,
        hideFooter = false,
    } = useSelector(selectCampaignPageData);
    const pageDesign = useSelector(selectPageDesign);
    const isModernDesign = pageDesign === CAMPAIGN_PAGE_DESIGNS.MODERN;
    const isLayerDonationEnabled = useSelector(selectEnabledLayerDonation);

    const rdpHiddenTabs = useSelector(selectRdpHiddenTabs);
    const rdpTabs =
        useSelector(selectTabs).filter(
            tab =>
                (!tab.layer || tab.counter) &&
                !tab.layer?.isHiddenFromRdp &&
                !rdpHiddenTabs?.includes(tab.tabContentId),
        ) || [];
    const viewedLayerItem = useSelector(teamSlice.selectors.selectSelectedItem); // currently selected TeamCardPopup layer item
    const pageLayerItem = useSelector(selectSelectedLayerItem); // ItemDetailsBar layer item
    const pageName = useSelector(selectPageName);

    const layerItemRaisedAmount = Number(
        pageLayerItem?.statistics
            ? pageLayerItem.statistics.donationsAmount
            : pageLayerItem?.donationsAmount,
    );

    const pageLanguage = language || document.documentElement.lang;

    const handleTimerStop = () => dispatch(updateIsCampaignExpired(true));

    useEffect(() => {
        if (campaign?.id) {
            sendAnalyticsEvent({
                event: 'campaign:page_load',
                org_account_id: campaign.accountId,
                campaign_id: campaign.id,
                campaign_sub_page: pageName,
                page_language: pageLanguage,
                is_layer_item_page: !!pageLayerItem,
            });
        }
    }, [campaign?.id, pageName, pageLayerItem]);

    const handleDonationApproved = useCallback(
        donor => {
            if (donor.campaign.id === campaign.id) {
                dispatch(
                    donorSlice.actions.updateTotal({
                        donationsCount: donor.campaign.donationsCount,
                    }),
                );
                dispatch(
                    teamSlice.actions.updateSelectedLayerItemStatistics(
                        donor.layerItems,
                    ),
                );
                dispatch(updatePageLayerItemStatistics(donor.layerItems));
                dispatch(donorSlice.actions.addDonor({ donor }));
                dispatch(addDonationFlashMessage(donor));
                if (
                    donor.layerItems?.find(
                        layerItem => layerItem.id === viewedLayerItem?.id,
                    )
                ) {
                    dispatch(teamDonorsListSlice.actions.addDonor({ donor }));
                }
                dispatch(updateDonationsSum(donor));

                if (donor.giving) {
                    dispatch(updateGiving({ donor }));
                }
            }
        },
        [viewedLayerItem, campaign && !!campaign.id],
    );
    const handleDonationDeleted = useCallback(
        donor => {
            if (donor.campaign.id === campaign.id) {
                dispatch(donorSlice.actions.decreaseTotal());
                dispatch(
                    teamSlice.actions.updateSelectedLayerItemStatistics(
                        donor.layerItems,
                    ),
                );
                dispatch(updatePageLayerItemStatistics(donor.layerItems));
                dispatch(donorSlice.actions.removeDonor({ donor }));
                dispatch(teamDonorsListSlice.actions.removeDonor({ donor }));
                dispatch(updateDonationsSum(donor));
            }
        },
        [campaign && !!campaign.id],
    );
    const handleDonationUpdate = useCallback(donor => {
        if (donor.campaign.id === campaign.id) {
            const allLayerItems = [
                ...(donor.layerItems || []),
                ...(donor.prevLayerItems || []),
            ];
            dispatch(
                teamSlice.actions.updateSelectedLayerItemStatistics(
                    allLayerItems,
                ),
            );
            dispatch(updatePageLayerItemStatistics(allLayerItems));

            dispatch(donorSlice.actions.updateDonor(donor));

            // try to update donation in RDP if it exists there
            // will be ignored if donation doesn't exist in RDP
            dispatch(teamDonorsListSlice.actions.updateDonor(donor));

            // if donation was moved to the current layer item page,
            // add it to the RDP
            if (
                donor.layerItems?.find(
                    layerItem => layerItem.id === viewedLayerItem?.id,
                )
            ) {
                dispatch(teamDonorsListSlice.actions.addDonor({ donor }));
            }

            // if donation was moved from the current layer item page,
            // remove it from the RDP
            if (
                donor.prevLayerItems?.find(
                    layerItem => layerItem.id === viewedLayerItem?.id,
                )
            ) {
                dispatch(teamDonorsListSlice.actions.removeDonor({ donor }));
            }

            dispatch(updateDonationsSum(donor));
            if (donor.giving) {
                dispatch(updateGiving({ donor }));
            }
        }
    });
    const handleCampaignUpdate = useCallback(updatedCampaign => {
        if (updatedCampaign.id === campaign.id) {
            dispatch(updateCampaign(updatedCampaign));
            dispatch(
                updateIsCampaignExpired(
                    isDateStringPast(updatedCampaign.endAt),
                ),
            );
        }
    });

    const handleDonationMatched = useCallback(
        ({ id, matchedDonationsCount }) => {
            dispatch(
                donorSlice.actions.updateMatchedDonationsCount({
                    id,
                    count: matchedDonationsCount,
                }),
            );
        },
    );

    const handleDonorPflCountUpdated = useCallback(updatedDonations => {
        for (let donation of updatedDonations) {
            dispatch(
                donorSlice.actions.updateDonationPflCountAndSlug({
                    id: donation.id,
                    donorPflCount: donation.donorPflCount,
                    donorPflSlug: donation.donorPflSlug,
                }),
            );
        }
    });

    const handleDonateNowClick = useCallback((paymentMethod, history) => {
        if (paymentMethod) {
            dispatch(updateDonationPaymentMethod(paymentMethod));
        }
        dispatch(
            open({
                layerItem: isLayerDonationEnabled ? pageLayerItem : null,
                paymentMethod,
            }),
        );
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
        );
        const virtualLocation = `${history.location.pathname.replace(
            /\/+$/,
            '',
        )}/donate/${queryString}`;
        triggerGtmEvent(GTM_EVENTS.CLICK_DONATE, {
            eventCategory: 'main-donate-button',
        });
        triggerGtmEvent(GTM_EVENTS.OPEN_CHECKOUT, {
            eventCategory: 'virtual-pageview',
            virtualLocation: virtualLocation,
        });
        history.push(virtualLocation);
    }, []);

    useWebsocket({ 'donation:approved': handleDonationApproved });
    useWebsocket({ 'donation:delete': handleDonationDeleted });
    useWebsocket({ 'donation:update': handleDonationUpdate });
    useWebsocket({ 'donation:matched': handleDonationMatched });
    useWebsocket({ 'donorPfl:countUpdated': handleDonorPflCountUpdated });
    useWebsocket({ 'campaign:update': handleCampaignUpdate });
    useEffect(() => {
        const { slug, donorPflSlug, isDonate } = parseCampaignPagePath();
        dispatch(fetchBySlug(slug));
        isDonate && dispatch(open({ layerItem: viewedLayerItem }));
        if (donorPflSlug) {
            dispatch(updateDonorPflSourceId(u2a.b32decodeUInt(donorPflSlug)));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(startFlashMessagesQueue());
        return () => {
            dispatch(stopFlashMessagesQueue());
        };
    }, [dispatch]);

    const layersIsFetched = useSelector(selectLayersIsFetched);
    const isDefaultTabSelected = useSelector(selectIsDefaultTabSelected);

    //Set default tab after campaign is loaded
    useEffect(() => {
        if (
            campaign &&
            layersIsFetched &&
            !isDefaultTabSelected &&
            !isLayerItem
        ) {
            let newSelectedTab = rdpTabs.find(tab => tab.id === defaultRDPTab);
            const fallbackTab =
                rdpTabs.find(tab => tab.tabContentId === 'donors') ||
                rdpTabs[0];

            if (
                !newSelectedTab ||
                newSelectedTab?.layer?.layerItemsCount === 0
            ) {
                newSelectedTab = fallbackTab;
            } else {
                dispatch(setIsDefaultTabSelected(true));
            }

            dispatch(setSelectedTab(newSelectedTab));
        }
    }, [campaign, layersIsFetched]);

    useEffect(() => {
        if (campaignSlug) {
            triggerGtmEvent(GTM_EVENTS.TRACK_INIT, {
                fbIdArray: enableFb ? fbTrackingId : 'false',
                gaIdArray: enableGa ? gaTrackingId : 'false',
                gAdId: enableGAd ? gAdTrackingId : 'false',
                gAdLabel: enableGAd ? gAdLabel : '',
                gAdConversionTagId: enableGAdConversionTag
                    ? gAdConversionTagId
                    : 'false',
                gAdConversionTagLabel: enableGAdConversionTag
                    ? gAdConversionTagLabel
                    : 'false',
                campaignId: campaign.id,
            });
        }
    }, [campaignSlug]);

    if (!campaign) {
        return null;
    }

    function renderCampaignDesign() {
        const props = {
            campaign,
            handleDonateNowClick,
            handleTimerStop,
            isMobile,
            layerItemRaisedAmount,
            rdpTabs,
        };
        switch (pageDesign) {
            case CAMPAIGN_PAGE_DESIGNS.MODERN:
                return <ModernCampaignPage {...props} />;
            case CAMPAIGN_PAGE_DESIGNS.CLASSIC:
            default:
                return <ClassicCampaignPage {...props} />;
        }
    }

    return (
        <ThemeProvider theme={defaultCampaignTheme}>
            <PageLayout
                slugs={campaign.slugs}
                locales={campaign.locales}
                pageLanguage={pageLanguage}
                hideHeader={hideHeader}
                hideFooter={hideFooter}
                addSidePadding={isModernDesign}
                hideLanguageIcon={isModernDesign}
                pageDesign={pageDesign}
            >
                {!campaign.published && (
                    <PreviewBanner campaignId={campaign.id} />
                )}
                {renderCampaignDesign()}
            </PageLayout>
        </ThemeProvider>
    );
}

export default CampaignPage;
