import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSingleDonationAmount, roundAmount } from 'common/helpers';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateDonationTotal } from '../../features/checkout/checkoutSlice';
import Divider from '../Divider/Divider';
import SelectedDonations from '../SelectedDonations/SelectedDonations';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        margin: '30px 0 60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media (max-width: 600px)': {
            margin: '20px 0 40px',
        },
    },
    label: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '1.313rem',
        fontWeight: 'bold',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            fontSize: '1.063rem',
        },
    },
    main: {
        textAlign: 'right',
    },
    total: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '2.5rem',
        lineHeight: '48px',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            fontSize: '2.125rem',
        },
    },
    payment: {
        fontFamily: 'Heebo, sans-serif',
        fontSize: '0.938rem',
        color: palette.primary.main,

        '@media (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
}));

function TotalAmount(props) {
    const {
        className,
        amount = 0,
        fee = 0,
        months = 1,
        currencySign = '$',
        recurring = false,
        installments = false,
        layerItems = [],
        unlimitedRecurring = false,
        tipping = true,
        tipPercent = 0,
        tippingPlatformText,
    } = props;
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const classes = useStyles();

    const amountWithFee = useCallback(
        amount => amount + roundAmount(amount * ((fee || 0) / 100)),
        [fee],
    );

    const tipAmount = useMemo(() => {
        return tipping ? roundAmount(amount * ((tipPercent || 0) / 100)) : 0;
    }, [amount, tipping, tipPercent]);

    const getLayerItemDonations = items => {
        const monthFactor = recurring || unlimitedRecurring ? months : 1;
        const singleLayerItemAmount = getSingleDonationAmount(
            amount * monthFactor,
            items.length,
        );

        return items.map(item => ({
            name: item.name,
            amount: Number(singleLayerItemAmount)?.toLocaleString('en-EN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        }));
    };

    const amountPerMonth = useMemo(() => {
        if (installments) return (amount / months).toFixed(2);
        return amount;
    }, [amount, installments, months]);

    const totalAmount = useMemo(() => {
        const amountWithTip = amount + tipAmount;
        const recurringAmount =
            recurring || unlimitedRecurring
                ? amountWithTip * months
                : amountWithTip;
        return recurringAmount.toLocaleString('en-EN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }, [
        amount,
        recurring,
        installments,
        months,
        layerItems,
        tipAmount,
        amountWithFee,
    ]);

    const monthlyAmount = useMemo(() => {
        return (installments ? amountPerMonth : amount).toLocaleString('en-EN');
    }, [installments, amountPerMonth, amount]);

    useEffect(() => {
        dispatch(
            updateDonationTotal({ tipAmount, totalAmount, monthlyAmount }),
        );
    }, [tipAmount, totalAmount, monthlyAmount]);

    return (
        <>
            {layerItems.length ? (
                <>
                    <Divider dotted={true} />
                    <SelectedDonations
                        currencySign={currencySign}
                        items={getLayerItemDonations(layerItems)}
                        isUnlimitedRecurringDonation={unlimitedRecurring}
                    />
                    <Divider dotted={true} />
                </>
            ) : null}
            <div className={classNames(classes.root, className)}>
                <div className={classes.label}>
                    {formatMessage({
                        id: 'DonationForm.totalAmount',
                    })}
                </div>
                <div className={classes.main}>
                    <div className={classes.total}>
                        {currencySign}
                        {totalAmount}
                    </div>
                    {amount > 0 && (
                        <div className={classes.payment}>
                            {unlimitedRecurring &&
                                formatMessage({
                                    id: 'DonationForm.calculatedOver12months',
                                })}
                            <br></br>
                            {tipping && tippingPlatformText && (
                                <>
                                    {tippingPlatformText} {currencySign}
                                    {tipAmount.toLocaleString('en-EN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    <br></br>
                                </>
                            )}
                            {months > 1 && unlimitedRecurring
                                ? formatMessage(
                                      {
                                          id: 'DonationForm.unlimitedRecurringTotalBreakdown',
                                      },
                                      { currencySign, monthlyAmount },
                                  )
                                : months > 1 &&
                                  formatMessage(
                                      {
                                          id: 'DonationForm.totalBreakdown',
                                      },
                                      { currencySign, monthlyAmount, months },
                                  )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

TotalAmount.propTypes = {
    amount: PropTypes.number,
    fee: PropTypes.number,
    months: PropTypes.number,
    className: PropTypes.string,
    currencySign: PropTypes.string,
    recurring: PropTypes.bool,
    unlimitedRecurring: PropTypes.bool,
    installments: PropTypes.bool,
    layerItems: PropTypes.array,
    percents: PropTypes.array,
    tipping: PropTypes.bool,
    tipPercent: PropTypes.number,
    tippingPlatformText: PropTypes.string,
};

export default TotalAmount;
